<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script setup>
const currentVer = 1
const ver = localStorage.getItem('ver')
if (!ver && ver < currentVer) {
  localStorage.clear()
  localStorage.setItem('ver', currentVer)
}
</script>

<style></style>
