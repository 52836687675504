<template>
  <div v-if="!isLoading">
    <template v-if="transactions.length > 0">
      <v-card
        v-for="transaction in transactions"
        color="surface"
        :variant="!tonal ? 'flat' : 'tonal'"
        :key="transaction._id"
        :class="{ 'mb-3': true, new: transaction.isNew }"
      >
        <v-card-text>
          <div class="d-flex flex-row justify-wrap-around">
            <div class="avatars mr-3 d-flex flex-column">
              <div class="d-flex flex-row">
                <v-avatar
                  v-if="transaction?.subjectUserId?.profileImageUrl"
                  size="32"
                  variant="elevated"
                  :image="transaction.subjectUserId.profileImageUrl"
                ></v-avatar>
                <v-avatar size="32" v-else color="surface-variant"></v-avatar>
              </div>
            </div>
            <div class="desc text-body-2 mr-3">
              <p class="text-left text-body-2">
                You claimed ticket fees for
                <router-link
                  v-if="transaction?.subjectUserId?.username"
                  class="text-primary link"
                  :to="`/profile/${transaction.subjectUserId.username}`"
                  >{{ getSubjectName(transaction) }}</router-link
                >
                <span v-else>{{ getSubjectName(transaction) }}</span>
              </p>
              <p class="text-caption text-disabled">
                {{ displayTime(transaction.updatedAt) }}
              </p>
            </div>
            <v-spacer></v-spacer>
            <div
              :class="[
                'price font-weight-bold justify-end d-flex flex-row align-start text-right',
              ]"
            >
              <span class="text-green">
                {{ transaction.feesDistributed }}
              </span>
              <i :class="['avax-icon', 'small fade ml-1 ']"></i>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <p
      v-else
      class="text-body-1 mt-3 text-center font-weight-bold text-disabled"
    >
      No Claims Yet
    </p>
  </div>
  <div v-else>
    <v-card v-for="n in 10" :key="n">
      <v-card-text>
        <div class="d-flex flex-row justify-wrap-around">
          <div class="avatars mr-3 d-flex flex-row">
            <v-skeleton-loader size="32" type="avatar"></v-skeleton-loader>
          </div>
          <v-skeleton-loader
            class="mx-auto border"
            width="100%"
            type="list-item-two-line"
          ></v-skeleton-loader>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { onMounted, onUnmounted, ref } from 'vue'
import { customPost } from '../../utils/apiUtils'
dayjs.extend(relativeTime)

defineProps({
  tonal: { type: Boolean, default: false },
})
const isLoading = ref(true)
const transactions = ref([])
const updateInterval = ref(null)

const displayTime = (createdAt) => {
  return dayjs(createdAt).fromNow()
}

const getSubjectName = (transaction) => {
  if (transaction.subjectUserId) {
    return transaction.subjectUserId.displayName
  } else {
    return 'Unknown'
  }
}

const getData = async () => {
  isLoading.value = true
  try {
    const response = await customPost(`/api/transactions/userClaims`, {})
    if (response && response.success) {
      transactions.value = response.transactions
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  } finally {
    isLoading.value = false
  }
}

const fetchNewTransactions = async () => {
  try {
    const response = await customPost(`/api/transactions/userClaims`, {})

    if (response && response.success) {
      const newTransactions = response.transactions
        .filter(
          (newTrans) =>
            !transactions.value.find(
              (existingTrans) => existingTrans._id === newTrans._id
            )
        )
        .map((trans) => ({ ...trans, isNew: true })) // Mark each new transaction as new

      if (newTransactions.length > 0) {
        // Prepend new transactions to the start of the array
        transactions.value.unshift(...newTransactions)
      }
    }
  } catch (error) {
    console.error(error)
  }
}

onMounted(async () => {
  await getData()

  updateInterval.value = setInterval(async () => {
    await fetchNewTransactions()
  }, 30000)
})

onUnmounted(() => {
  if (updateInterval.value) {
    clearInterval(updateInterval.value)
  }
})
</script>
<style>
.no-overlay {
  .v-btn__overlay,
  .v-card__overlay {
    background: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
.new {
  animation: newTransaction 1s ease-in-out;
}
@keyframes newTransaction {
  0%,
  100% {
    background-color: transparent; /* Use 'transparent' instead of 'none' for background-color */
  }
  50% {
    background-color: rgba(255, 200, 0, 0.354);
  }
}
</style>
