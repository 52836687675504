<template>
  <v-list class="comment-list" dense>
    <!-- This is a loop for comments, replace with your actual data source -->

    <v-list-item v-for="i in quantity" :key="i" class="comment-item mb-2">
      <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
    </v-list-item>
  </v-list>
</template>

<script>
import { ref } from 'vue'
export default {
  props: ['quantity'],
  components: {},
  setup() {
    const count = ref(['1', '2', '3', '4', '5'])

    return {
      count,
    }
  },
}
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}
.v-icon.liked {
  color: red;
}
.drop {
  border-radius: 0;
}
</style>
