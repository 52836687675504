<template>
  <v-row class="leaderboard-skeleton" v-for="index in quantity" :key="index">
    <v-col cols="2" class="d-flex align-center">
      <v-skeleton-loader
        class="no-bg-skeleton no-mb-skeleton"
        type="avatar"
      ></v-skeleton-loader>
    </v-col>
    <v-col cols="7" class="d-flex align-center">
      <v-skeleton-loader
        class="no-bg-skeleton"
        type="text"
        width="70%"
      ></v-skeleton-loader>
    </v-col>
    <v-col cols="3" class="d-flex align-center justify-end">
      <v-skeleton-loader
        class="no-bg-skeleton"
        type="text"
        width="70"
        color="none"
      ></v-skeleton-loader>
    </v-col>
    <v-divider></v-divider>
  </v-row>
</template>

<script>
import { ref } from 'vue'
export default {
  props: ['quantity'],
  components: {},
  setup() {
    const count = ref(['1', '2', '3', '4', '5'])

    return {
      count,
    }
  },
}
</script>
<style>
.leaderboard-skeleton {
  .no-bg-skeleton {
    background: none;
  }
  .v-skeleton-loader__avatar {
    margin-bottom: 0;
  }
}
</style>
<style lang="scss" scoped>
.full-width {
  width: 100%;
}
.drop {
  border-radius: 0;
}
</style>
