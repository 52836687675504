<template>
  <v-dialog
    v-model="isActive"
    @update:modelValue="handleDialogClose"
    transition="dialog-top-transition"
    width="w-100 mr-0 ml-0"
    color="background"
    max-width="600"
  >
    <v-card color="background">
      <v-card-title>
        <div class="d-flex align-center justify-space-between">
          <v-list-item
            class="ma-0 pa-0"
            :prepend-avatar="user.profileImageUrl"
            :title="`${user.displayName}`"
            @click="navigateToProfile(user.username)"
          >
          </v-list-item>
          <v-btn
            size="small"
            color="surface-variant"
            variant="text"
            class="align-center justify-space-between"
            @click="closeDialog"
            icon="mdi-close"
          >
          </v-btn>
        </div>
      </v-card-title>
      <v-container v-if="user && user.volume > 0" fluid>
        <Chart :user="user" />
      </v-container>
      <v-card-text class="pa-0">
        <TradeMeta class="my-n2 mx-2" :user="user" />
        <div class="ma-2">
          <MultipleTradeWidget
            :userdata="user"
            @refetch="refetchUser"
            @updatePrice="updatePrice"
          />
        </div>
        <div v-if="pinEnabled" class="d-flex flex-column pr-6 pl-6 pt-4 pb-4">
          <v-text-field
            v-model="pin"
            label="Enter your PIN"
            type="password"
            dense
          ></v-text-field>
        </div>
      </v-card-text>
      <!-- <v-card-actions> </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getBuyPrice, getSellPrice } from '../../utils/apiUtils'
import Chart from '../Chart.vue'
import MultipleTradeWidget from './MultipleTradeWidget.vue'
import TradeMeta from './TradeMeta.vue'

const props = defineProps({
  show: { type: Boolean },
  user: { type: Object },
  friendlyId: { type: String },
  showMeta: {
    type: Boolean,
    default: true,
  },
})
const emit = defineEmits(['close', 'refetchUsers'])
const router = useRouter()
const store = useStore()
const isActive = ref(true)
const buyPrice = ref(0)
const sellPrice = ref(0)
const isUpdatingBuyPrice = ref(false)
const isUpdatingSellPrice = ref(false)
const profileUser = ref(null)
const pin = ref('')

onMounted(() => {
  fetchAndUpdateBuyPrice()
  fetchAndUpdateSellPrice()
  isActive.value = true
})

const updatePrice = async (newPrice) => {
  if (profileUser.value) {
    profileUser.value.sharePrice = newPrice
  }
}

const refetchUser = () => {
  emit('refetchUsers')
}

const fetchAndUpdateBuyPrice = async () => {
  isUpdatingBuyPrice.value = true
  const response = await getBuyPrice(props.user.address, 1)
  const price = response.price
  if (price != null) {
    buyPrice.value = price
  }
  isUpdatingBuyPrice.value = false
}

const handleDialogClose = (newValue) => {
  if (!newValue) {
    // Dialog is being closed
    closeDialog()
  }
}

const fetchAndUpdateSellPrice = async () => {
  isUpdatingSellPrice.value = true
  const response = await getSellPrice(props.user.address, 1)
  const price = response.price
  if (price != null) {
    sellPrice.value = price
  }
  isUpdatingSellPrice.value = false
}

let intervalId

const navigateToProfile = (user) => {
  router.push({ name: 'Profile', params: { username: user } })
}

const pinEnabled = computed(() => {
  return store.state.user.pinRequired
})

onMounted(() => {
  intervalId = setInterval(() => {
    fetchAndUpdateBuyPrice()
    fetchAndUpdateSellPrice()
  }, 10000)
})

onBeforeUnmount(() => {
  clearInterval(intervalId)
})

const closeDialog = () => {
  isActive.value = false
  emit('close') // Emit an event to close the dialog
}
</script>
<style scoped lang="scss">
.sell-btn {
  margin-bottom: 0 !important;
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}
.button-container {
  width: 100%;
  justify-content: space-between; /* Adds space between the buttons */
}
</style>
