<template>
  <template v-if="size === 'default'">
    <i :class="['avax-icon mr-1', 'small']"></i
    >{{ formatNumberTwoDecimals(balance) }}
  </template>
  <span class="text-high-emphasis d-flex flex-row align-center" v-else>
    <i :class="['avax-icon mr-1 fade', 'small']"></i
    >{{ formatNumberTwoDecimals(balance) }}
    <v-icon
      icon="mdi-wallet-outline"
      size="default"
      class="ml-1 mt-0 text-disabled"
    ></v-icon>
  </span>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { getBalance } from '../utils/apiUtils'
import { formatNumberTwoDecimals } from '../utils/helpers'

defineProps({
  size: {
    type: String,
    default: 'default',
  },
})
const store = useStore()
const balance = ref(0)

const useWeb3Wallet = computed(() => store.state.user?.useWeb3Wallet)

const address = computed(() => {
  return useWeb3Wallet.value
    ? store.state.user.web3address
    : store.state.user.address
})

watch(useWeb3Wallet, (newVal) => {
  if (newVal) {
    fetchBalance()
  }
})

onMounted(async () => {
  await fetchBalance()
})

onUnmounted(() => {
  clearInterval(intervalId)
})

const fetchBalance = async () => {
  const currentAddress = address.value
  if (!currentAddress) {
    return
  }
  const response = await getBalance(currentAddress)
  if (response.success) {
    balance.value = response.balance
  } else {
    return 0
  }
}

const intervalId = setInterval(async () => {
  await fetchBalance()
}, 10000)
</script>
