<template>
  <template v-if="noTransactions"> No chart to display </template>
  <template v-else>
    <div class="w-100" v-if="!isLoading">
      <canvas class="w-100" ref="chartRef"></canvas>
    </div>
    <!-- <v-skeleton-loader v-else :elevation="2" type="ossein"></v-skeleton-loader> -->
    <v-skeleton-loader
      v-else
      :loading="isLoading"
      height="180"
      type="ossein"
      class="mb-4"
    />
    <!-- <v-skeleton-loader
    v-else
    class="no-overlay"
    type="button"
    height="180"
  ></v-skeleton-loader> -->
  </template>
</template>

<script setup>
import axios from 'axios'
import Chart from 'chart.js/auto'
import { onMounted, ref } from 'vue'

const props = defineProps({
  user: Object,
})

const chartRef = ref(null)
const isLoading = ref(true)
const noTransactions = ref(false)

const getData = async () => {
  isLoading.value = true
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/api/transactions/subject/${props.user.address}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    if (response) {
      return response.data.transactions
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  } finally {
    isLoading.value = false
  }
}

onMounted(async () => {
  // Fetch the transaction data from the server
  let transactions = await getData()

  if (transactions.success === false) {
    noTransactions.value = true
    return
  }

  if (!transactions) {
    noTransactions.value = true
    return
  }

  if (transactions.length === 0) {
    noTransactions.value = true
    return
  }

  if (!props.user) {
    noTransactions.value = true
    return
  }

  transactions.reverse()

  if (chartRef.value) {
    const ctx = chartRef.value.getContext('2d')

    // Extract block numbers and prices from the transactions
    const dates = transactions.map((tx) =>
      new Date(tx.updatedAt).toLocaleDateString()
    )
    const prices = transactions.map((tx) => {
      if (tx.shareAmount > 1) {
        return tx.price / tx.shareAmount
      } else {
        return tx.price
      }
    })
    const buyOrSell = transactions.map((tx) => (tx.isBuy ? 'Buy' : 'Sell'))

    // Create a gradient fill
    let gradient = ctx.createLinearGradient(0, 0, 0, 200)
    gradient.addColorStop(0, 'rgba(255, 181, 5, 1)') // Color at the top
    gradient.addColorStop(0.5, 'rgba(255, 181, 5, 0.5)') // Midway color
    gradient.addColorStop(1, 'rgba(255, 181, 5, 0)') // Transparent at the bottom

    const data = {
      labels: dates,
      datasets: [
        {
          label: 'Buy/Sell',
          borderColor: '#ffb505',
          backgroundColor: gradient,
          data: prices,
          pointRadius: 0,
          fill: true,
        },
      ],
    }

    const config = {
      type: 'line',
      data,
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
          x: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            mode: 'index',
            intersect: false,
            callbacks: {
              title: function (tooltipItem) {
                // Return the date for the title
                return dates[tooltipItem[0].index]
              },
              label: function (tooltipItem) {
                // Display Price and Buy or Sell
                const value = tooltipItem.parsed.y // Price value
                const action = buyOrSell[tooltipItem.dataIndex] // Buy or Sell
                return `${action}: ${value.toFixed(2)} AVAX`
              },
            },
          },
        },
      },
    }
    new Chart(ctx, config)
  }
})
</script>
<style>
.no-overlay {
  .v-btn__overlay,
  .v-card__overlay {
    background: transparent !important;
  }
}
</style>
