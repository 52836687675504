<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500">
    <v-card>
      <v-card-title>
        <v-icon icon="mdi-alert" size="x-small" color="red" />
        External Link Warning</v-card-title
      >
      <v-card-text>
        <p class="text-body-2 mb-3">
          Caution: You're about to exit Legendary and visit an external site.
          We're not responsible for its content or security. Always protect your
          personal info and be wary of suspicious links. Continue at your own
          risk.
        </p>
        <p>
          <a
            :href="hrefLink"
            target="_blank"
            rel="noopener"
            class="text-primary"
            @click="handleLinkClick"
            >{{ hrefLink }}</a
          >
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleLinkClick">Continue</v-btn>
        <v-btn text @click="closeDialog" variant="tonal">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue'

// Props from parent component
const props = defineProps({
  hrefLink: String,
})

// Use 'dialog' ref to control the dialog visibility
const dialog = ref(true)

// Emits to communicate with parent
const emit = defineEmits(['close'])

// Function to handle link click
const handleLinkClick = () => {
  window.open(props.hrefLink, '_blank')
  closeDialog()
}

// Function to close the dialog and unmount the component
const closeDialog = () => {
  dialog.value = false
  // Emit to parent to set hrefLink to null, unmounting this component
  emit('close')
}
</script>
