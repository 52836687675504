<template>
  <v-dialog persistent v-model="showTradeConfirmationDialog">
    <v-card>
      <v-progress-linear
        v-if="isTrading"
        indeterminate
        :color="actionType === 'BUY' ? 'green' : 'red'"
      ></v-progress-linear>
      <v-card-title class="d-flex flex-row justify-space-between align-center">
        <v-icon icon="mdi-arrow-top-right" size="small"></v-icon>
        <span
          :class="[actionType === 'BUY' ? 'text-green' : 'text-red', 'mr-1']"
        >
          {{ actionType }}
        </span>
        {{ amount }} {{ amount > 1 ? 'SHARES' : 'SHARE' }}

        <v-spacer></v-spacer>
        <v-btn icon variant="plain" dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-table class="tableconfirmation bg-background text-body-2">
        <tbody>
          <tr>
            <th>Subject</th>
            <td>{{ username }}</td>
          </tr>
          <tr>
            <th>Number of tickets</th>
            <td>{{ amount }}</td>
          </tr>
          <tr>
            <th>Price</th>
            <td class="d-flex flex-row align-center">
              <i :class="['avax-icon', 'small mr-1']"></i> {{ price }}
            </td>
          </tr>
        </tbody>
      </v-table>

      <div v-if="pinEnabled" class="mx-4 my-2">
        <v-text-field
          v-model="pin"
          label="Enter your PIN"
          type="password"
          dense
          :disabled="isTrading"
        ></v-text-field>
      </div>

      <v-card-actions class="justify-end">
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          :color="actionType === 'BUY' ? 'green' : 'red'"
          :loading="isTrading"
          :disabled="isTrading"
          @click="submitTrade"
          variant="elevated"
          >Confirm {{ actionType }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { buyTicket, sellTicket } from '../../utils/apiUtils'

const emit = defineEmits(['close', 'reload', 'firstTrade'])
const props = defineProps({
  show: Boolean,
  isBuy: Boolean,
  amount: Number,
  price: Number,
  subject: String,
  username: String,
})

const store = useStore()
const isTrading = ref(false)
const showTradeConfirmationDialog = ref(props.show)
const pin = ref('')

watch(
  () => props.show,
  (newVal) => {
    showTradeConfirmationDialog.value = newVal
  }
)

const actionType = computed(() => {
  return props.isBuy ? 'BUY' : 'SELL'
})

const pinEnabled = computed(() => {
  return store.state.user.pinRequired
})

const closeDialog = () => {
  showTradeConfirmationDialog.value = false
  emit('close') // Emit an event to close the dialog
}

const submitTrade = async () => {
  if (pinEnabled.value && pin.value === '') {
    store.dispatch('showSnackbar', {
      show: true,
      message: 'Please enter your PIN.',
      color: 'warning',
      timeout: 2000,
    })
    return
  }
  try {
    isTrading.value = true

    const amount = props.amount
    const address = props.subject
    const value = props.price

    let trade

    if (props.isBuy) {
      trade = await buyTicket(
        address,
        amount,
        value,
        pin.value,
        store.state.user?.useWeb3Wallet,
        store.state.user?.referrer,
        store.state.user?.web3address ? store.state.user.web3address : null
      )
    } else {
      trade = await sellTicket(
        address,
        amount,
        pin.value,
        store.state.user?.useWeb3Wallet,
        store.state.user?.web3address ? store.state.user.web3address : null
      )
    }

    if (trade.success) {
      store.commit('setPendingTransaction', trade.txHash)
      // Show success Snackbar
      store.dispatch('showSnackbar', {
        show: true,
        message: `${
          props.isBuy ? 'Buy' : 'Sell'
        } transaction is pending. <a href="${process.env.VUE_APP_EXPLORER_TX}${
          trade.txHash
        }" target="_blank" style="color:#fff">View Status</a>!`,
        color: 'info',
        timeout: 3000,
      })

      pin.value = ''

      if (props.isBuy && value == 0) {
        emit('firstTrade')
      }

      emit('reload')
      // Close the dialog
      emit('close')
    } else {
      console.log('Trade failed:', trade.message)
      pin.value = ''

      // Show failure Snackbar
      store.dispatch('showSnackbar', {
        show: true,
        message: `Trade failed: ${trade.message}`,
        color: 'error',
        timeout: 2000,
      })
    }
  } catch (error) {
    pin.value = ''
    console.log('An error occurred while tipping:', error)

    // Show error Snackbar
    store.dispatch('showSnackbar', {
      show: true,
      message: `An error occurred: ${error.message}`,
      color: 'error',
      timeout: 2000,
    })
  }
  isTrading.value = false
}
</script>

<style>
.v-dialog {
  max-width: 500px;
}

.tableconfirmation {
  margin: 10px 10px;
  /* background-color: #1e2234; */
  border-radius: 5px;
}
</style>
