<template>
  <PostTextForm />
</template>

<script>
import PostTextForm from '../components/PostText/PostTextForm.vue'

export default {
  components: {
    PostTextForm,
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/global.scss';
.post-tabs .v-tabs-slider {
  top: 0 !important;
  bottom: auto !important;
}
.video-preview {
  height: 100%;
  width: 100%;
  margin: 0 0 1rem 0;
  video {
    width: 65%;
    object-fit: cover;
    max-width: 500px;
  }
}
.video-upload-container {
  flex-direction: column;
  align-items: center;
  display: grid;
  height: 90%;
}
.file-input-box {
  padding: 4rem 2rem;
  border-style: dashed;
  width: 80%;
  margin: 0 auto 1rem;
  border-width: 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
}
.input-box {
  width: 90%;
  border-radius: 4px;
  border: 1px #000000a6 solid;
  font-size: 1.1rem;
  padding: 1rem;
}
</style>
