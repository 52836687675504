<template>
  <div v-if="showSpinner || showArrow" class="d-flex justify-center">
    <v-icon v-show="showArrow" class="pull-down-arrow"
      >mdi-arrow-down-thin</v-icon
    >
    <v-progress-circular
      v-show="showSpinner"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
  <v-tabs
    v-model="tab"
    align-tabs="center"
    bg-color="background"
    grow
    color="primary"
    v-if="!props.filterType"
  >
    <v-tab value="discover">Discover</v-tab>
    <v-tab value="for you">For You</v-tab>
  </v-tabs>
  <v-divider v-if="!props.filterType"></v-divider>
  <v-container class="pl-0 pr-0 pt-0 pb-0" v-if="!busy">
    <v-btn
      size="small"
      v-if="newPosts > 0"
      @click="refreshFeed"
      class="new-posts-button"
      color="secondary"
      prepend-icon="mdi-arrow-up"
    >
      {{ newPosts }} new post{{ newPosts > 1 ? 's' : '' }}
    </v-btn>
    <template v-if="posts.length > 0">
      <PostItem
        v-for="post in posts"
        :key="post.postId"
        @navigateToPost="navigateToSinglePost"
        @refresh="refreshFeed"
        :post="post"
        @showQuickReply="showQuickReply"
        @openLink="(link) => (hrefLink = link)"
      />
    </template>

    <h6 v-else class="text-center mt-6 text-h6">No Posts</h6>
  </v-container>
  <PostSkeleton :quantity="5" v-else />
  <div
    v-if="!busy && posts.length > 0"
    v-intersect.quiet="{
      handler: loadInfiniteScroll,
      options: infiniteOptions,
    }"
    class="intersection-element d-flex align-center justify-center pt-5 pb-5"
  >
    <v-progress-circular color="primary" indeterminate></v-progress-circular>
  </div>
  <v-btn
    icon="mdi-plus"
    color="primary"
    @click="navigateTo('Post Text')"
    class="post-new d-md-none"
  >
  </v-btn>
  <!-- Centralized Tip Dialog -->

  <Tipping
    :show="showTipDialog"
    :subject="subject"
    :postId="postId"
    @close="showTipDialog = false"
    @tip="handleTipAmount"
  />
  <QuickReplyPopup
    v-if="quickReplyPost"
    :showDialog="toggleQuickReply"
    :post="quickReplyPost"
    @closeDialog="closeQuickReply"
    @replyPosted="handleReplyPosted"
  />
  <OpenLinkDialog
    v-if="hrefLink"
    :hrefLink="hrefLink"
    @close="hrefLink = null"
  />
</template>

<script setup>
import PostSkeleton from '@/components/Loaders/PostSkeleton.vue'
import OpenLinkDialog from '@/components/OpenLinkDialog.vue'
import PostItem from '@/components/PostItem.vue'
import QuickReplyPopup from '@/components/PostText/QuickReplyPopup.vue'
import Tipping from '@/components/UserActions/Tipping.vue'
import store from '@/store'
import {
  inject,
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { customGet, customPost } from '../utils/apiUtils'

const router = useRouter()
const route = useRoute()
const emitter = inject('emitter')
const posts = ref(store.state.feedCache || [])
const busy = ref(store.state.feedCache ? false : true) // if cache doesn't exist, do initial loading skeleton
const isLoadingInfinite = ref(false)
const isDiscoveryMode = ref(true)
const tab = ref('one')

const props = defineProps({
  filterType: {
    type: String,
    default: null,
  },
  profileUserId: {
    type: String,
    default: null,
  },
  pageOrigin: {
    type: String,
    default: null,
  },
})

const lastSeenTimestamp = ref(store.state.lastSeenTimestampCache || null)
const firstSeenTimestamp = ref(store.state.firstSeenTimestampCache || null)

const showTipDialog = ref(false)
const subject = ref(null)
const postId = ref(null)
const currentPost = ref(null)

const newPosts = ref(0)
let intervalId = null

const toggleQuickReply = ref(false)
const quickReplyPost = ref(null)
const hrefLink = ref(null)

// Pull down to refresh reactive variables
const showArrow = ref(false)
const showSpinner = ref(false)
const startWatching = ref(false)
const tabUnwatch = ref(null)

emitter.on('refresh', () => {
  refreshFeed()
})

emitter.on('filterId', (data) => {
  const { removedId, originalId } = data
  let newPosts = posts.value.filter((post) => post.friendlyId !== removedId)
  newPosts = newPosts.map((p) => {
    if (p.postId === originalId) {
      let updatedPost = { ...p }
      updatedPost.hasReposted = false // Set hasReposted to false
      updatedPost.repostCount = Math.max(p.repostCount - 1, 0) // Decrement repostCount safely
      return updatedPost
    }
    return p
  })

  posts.value = newPosts // Set the new posts
  store.commit('setFeedCache', newPosts)
})

// const handleTouchEnd = () => {
//   // Calculate the total movement
//   const totalMovement = endY.value - startY.value
//   // Check if the pull down action has crossed the threshold and the page is at the top
//   if (startY.value !== 0 && totalMovement > threshold && window.scrollY === 0) {
//     console.log('pull ended')
//     refreshFeed() // Refresh method is called here
//   } else {
//     // Reset the UI elements
//     showArrow.value = false
//     showSpinner.value = false
//   }

//   // Reset startY and endY for the next touch action
//   startY.value = 0
//   endY.value = 0
// }

/**
 * Intersection Observer api settings
 * Root margin - 800px margin all around to trigger the method.
 * threshold - atleast 20% of the item intersection should trigger the method
 */
const infiniteOptions = {
  rootMargin: '1200px',
  threshold: 0.2,
}

const handleTipAmount = (tipAmount) => {
  if (currentPost.value) {
    currentPost.value.tipDollars += tipAmount
  }
}

const startNewPostsInterval = () => {
  if (!props.filterType) {
    intervalId = setInterval(() => {
      getNewPostLength()
    }, 10000)
  }
}

const getNewPostLength = async () => {
  try {
    const url =
      `/api/posts/newpostslength?filterOut=video&lastSeen=${firstSeenTimestamp.value}` +
      (isDiscoveryMode.value ? '&discover=true' : '')

    const response = await customGet(url)

    if (response.success) {
      if (response.posts > 0) {
        newPosts.value = response.posts
      }
    }
  } catch (error) {
    console.log('Error getting new posts:', error)
  }
}

const api = async () => {
  try {
    let url
    if (props.filterType === 'personalPosts') {
      let addendum = '?filterType=' + props.filterType
      if (props.profileUserId != null) {
        addendum += '&profileUserId=' + props.profileUserId
      }
      url = `/api/posts/curated-feed` + addendum
    } else if (
      props.filterType === 'userbookmark' ||
      props.filterType === 'userheart'
    ) {
      let addendum = '?filterType=' + props.filterType
      addendum += '&type=' + props.filterType
      addendum += '&pageOrigin=' + props.pageOrigin
      url = `/api/posts/curated-feed` + addendum
    } else {
      url =
        `/api/posts/feed?filterOut=video` +
        (isDiscoveryMode.value ? '&discover=true' : '')
    }

    if (lastSeenTimestamp.value) {
      url += `&lastSeen=${lastSeenTimestamp.value}`
    }

    const response = await customGet(url)

    if (response.success) {
      if (
        firstSeenTimestamp.value === null ||
        response.firstSeen > firstSeenTimestamp.value
      ) {
        firstSeenTimestamp.value = response.firstSeen
      }
      lastSeenTimestamp.value = response.lastSeen
      return response.posts
    } else if (response.failure) {
      //TODO Handle what happens when we run out of posts, display a message or something
    }
  } catch (error) {
    console.log('Error uploading video:', error)
  }
}

/**
 * Updating post in feed with latest data from reply component.
 * This is to prevent extra api call to udpate reply count data
 * @param {*} param0
 */
const handleReplyPosted = ({ post: postItem, newCommentCount }) => {
  const post = posts.value.find((p) => p.postId === postItem.postId)
  if (post) {
    post.commentCount = newCommentCount
  }
}
const showQuickReply = (postItem) => {
  quickReplyPost.value = postItem
  toggleQuickReply.value = true
}

const closeQuickReply = () => {
  toggleQuickReply.value = false
  quickReplyPost.value = null
}

const navigateToSinglePost = (postItem, event = null) => {
  //We want to handle middle mouse events
  if (event && event.button === 1) {
    event.preventDefault()
    const routeData = router.resolve({
      name: 'SinglePost',
      params: {
        id: postItem.postId,
      },
    })
    window.open(routeData.href, '_blank')
    return
  }
  /**
   * Specifical condition for replacing user and post id
   * for reposted posts with the original user and original post id
   *
   * Actually, even better. This can be moved to onBeforeMount. TODO: Refactor lateronBeforeMount
   */
  if (postItem.isRepost) {
    postItem.postId = postItem.originalPostId
    postItem.user = postItem.originalPoster
  }
  store.commit('setPostCache', postItem)
  /**
   * Only setting feed cache when going from feed to single post for now
   * otherwise we can set the cache in onUnmounted
   */
  if (props.filterType === null) {
    // Only set post cache feed for default feed page
    store.commit('setFeedCache', posts.value)
    store.commit('setLastSeenTimestampCache', lastSeenTimestamp.value)
    store.commit('setFirstSeenTimestampCache', firstSeenTimestamp.value)
  }
  router.push({
    name: 'SinglePost',
    params: {
      id: postItem.postId,
    },
  })
}

const loadInfiniteScroll = async (entries, observer, isIntersecting) => {
  if (isIntersecting) {
    if (isLoadingInfinite.value) return
    isLoadingInfinite.value = true
    const newPosts = await api()
    if (newPosts) {
      posts.value.push(...newPosts)
    }
    await delay(500) // Adding a pause of 500 seconds to allow mounting of new posts
    isLoadingInfinite.value = false
  }
}
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const refreshFeed = async () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  posts.value = []
  lastSeenTimestamp.value = null
  firstSeenTimestamp.value = null
  newPosts.value = 0
  showSpinner.value = false
  await loadMore()
}
const loadMore = async () => {
  if (busy.value && posts.value.length > 0) return
  busy.value = true
  const newPosts = await api()
  if (newPosts) {
    // posts.value = [...posts.value, ...newPosts] // Instead of destructuring same array and creating a new array
    posts.value.push(...newPosts) // Destructuring new posts and pushing it at the bottom of existing array
  }
  busy.value = false
}
const navigateTo = (routeName) => {
  router.push({ name: routeName })
}

const checkReferrer = async () => {
  const user = store.state.user

  console.log(user)
  console.log(localStorage.getItem('referralToken'))

  const url = `/api/auth/setReferrer`

  const referralToken = localStorage.getItem('referralToken')

  if (!referralToken) return

  const payload = {
    address: user.address,
    referralToken: referralToken,
  }

  console.log('payload', payload)

  try {
    const response = await customPost(url, payload)
    console.log(response.data)
    localStorage.removeItem('referralToken')
  } catch (error) {
    console.log(error)
  }
}

/**
 * In Keep Alive tag, the onActivated is called during mounting or when revisiting the view.
 */
onActivated(async () => {
  if (props.filterType === 'userbookmark' || props.filterType === 'userheart') {
    //We want to clear the cache on page load if we are in the Saved section
    await store.dispatch('clearFeedCache')
  }
  if (props.filterType === 'personalPosts') {
    //In this case we are calling from profile, so we want posts to be null again
    posts.value = []
  }
  // only fetch posts in mounting if no posts or if in non feed page
  if (posts.value.length === 0) {
    await loadMore() // Load initial posts
    startNewPostsInterval() // Starting timer for checking new post as lastSeenTimestamp is done.
  }

  // start watching the tabs changing
  startWatching.value = true

  // watch the tabs changing to toggle discovery mode on and off
  tabUnwatch.value = watch(tab, (newVal) => {
    if (!startWatching.value) {
      return
    }

    if (newVal === 'discover') {
      isDiscoveryMode.value = true
    } else {
      isDiscoveryMode.value = false
    }
    //reset the last/first seen timestamps to reset content
    lastSeenTimestamp.value = null
    firstSeenTimestamp.value = null
    refreshFeed()
  })

  if (route.query.mode === 'for-you') {
    tab.value = 'for you'
  }
})

/**
 * Adding onMounted for non feed pages as they don't use keep alive. Hence on ACtivated won't be called
 */
onMounted(async () => {
  if (props.filterType === 'userbookmark' || props.filterType === 'userheart') {
    //We want to clear the cache on page load if we are in the Saved section
    await store.dispatch('clearFeedCache')
  }
  if (props.filterType === 'personalPosts') {
    //In this case we are calling from profile, so we want posts to be null again
    posts.value = []
  }
  checkReferrer()
  // only fetch posts in mounting if no posts or if in non feed page
  if (posts.value.length === 0) {
    await loadMore() // Load initial posts
    startNewPostsInterval() // Starting timer for checking new post as lastSeenTimestamp is done.
  }

  // start watching the tabs changing
  startWatching.value = true

  // watch the tabs changing to toggle discovery mode on and off
  tabUnwatch.value = watch(tab, (newVal) => {
    if (!startWatching.value) {
      return
    }

    if (newVal === 'discover') {
      isDiscoveryMode.value = true
    } else {
      isDiscoveryMode.value = false
    }
    //reset the last/first seen timestamps to reset content
    lastSeenTimestamp.value = null
    firstSeenTimestamp.value = null
    refreshFeed()
  })

  if (route.query.mode === 'for-you') {
    tab.value = 'for you'
  }
})

/**
 * Clear feed cache when going away from feed to any other page
 * other than the single post page
 */
onBeforeRouteLeave((to, from) => {
  // Clear the cache only if navigating away from feed/saved but not to SinglePost
  if (
    (from.name === 'Feed' || from.name === 'Saved') &&
    to.name !== 'SinglePost'
  ) {
    // store.dispatch('clearFeedCache')
    store.commit('setFeedScrollPosition', {
      attributeName: 'Feed',
      position: window.scrollY,
    })
  }
})

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId)
  }

  //stop watching the tabs changing
  startWatching.value = false
  if (tabUnwatch.value) {
    tabUnwatch.value()
  }
})

onDeactivated(() => {
  // called when removed from the DOM into the cache
  // and also when unmounted
  startWatching.value = false
  if (tabUnwatch.value) {
    tabUnwatch.value()
  }
})
</script>
<style>
/* body,
html {
  overscroll-behavior-y: contain;
} */
</style>
<style scoped>
.pull-down-arrow,
.v-progress-circular {
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
}

.pull-down-arrow.show,
.v-progress-circular.show {
  transform: translateY(0);
  opacity: 1;
}

.pull-down-arrow.hide,
.v-progress-circular.hide {
  transform: translateY(-20px); /* Adjust as needed */
  opacity: 0;
}

.end-card {
  background-color: red;
  height: 200px;
}
.post-new {
  position: fixed;
  bottom: 6rem;
  right: 2rem;
  @media (min-width: 768px) {
    bottom: 6rem;
    right: 2rem;
  }
}

.new-posts-button {
  position: fixed;
  top: 80px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
}
.swiper {
  height: 100%;
}
/* Your CSS goes here */
</style>
