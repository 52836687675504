import { ethers } from 'ethers'
import abi from '../abi/contract.json'

export const isWeb3Enabled = () => {
  return typeof window.ethereum !== 'undefined'
}

export const signMessage = async (nonce) => {
  try {
    if (!window.ethereum) {
      return { success: false, error: 'Metamask not found' }
    }

    if (window.ethereum) {
      const provider = new ethers.BrowserProvider(window.ethereum)
      const signer = await provider.getSigner()
      const message =
        'Welcome to Legendary. Become a Legend. Verify your address to start interacting with your Web3 wallet. Code - ' +
        nonce
      const signature = await signer.signMessage(message)
      console.log('Signature: ', signature)
      return { signature }
    }
  } catch (error) {
    return { success: false, error: error.message }
  }
}

export const signMessageLogin = async (nonce) => {
  try {
    if (!window.ethereum) {
      return { success: false, error: 'Metamask not found' }
    }

    if (window.ethereum) {
      const provider = new ethers.BrowserProvider(window.ethereum)
      const signer = await provider.getSigner()
      const message =
        'Welcome to Legendary. Login with your MetaMask wallet now. Code - ' +
        nonce
      const signature = await signer.signMessage(message)
      console.log('Signature: ', signature)
      return { signature }
    }
  } catch (error) {
    return { success: false, error: error.message }
  }
}

export const signMessageRecoverPin = async (nonce) => {
  try {
    if (!window.ethereum) {
      return { success: false, error: 'Metamask not found' }
    }

    if (window.ethereum) {
      const provider = new ethers.BrowserProvider(window.ethereum)
      const signer = await provider.getSigner()
      const message =
        'Recover your PIN by signing this message. Code - ' + nonce
      const signature = await signer.signMessage(message)
      console.log('Signature: ', signature)
      return { signature }
    }
  } catch (error) {
    return { success: false, error: error.message }
  }
}

export const connectWallet = async () => {
  try {
    if (!window.ethereum) {
      return { success: false, error: 'Metamask not found' }
    }

    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })

      await checkCurrentChainId()
      return accounts[0]
    }
  } catch (error) {
    return { success: false, error: error.message }
  }
}

export const getAccounts = async () => {
  try {
    if (!window.ethereum) {
      return { success: false, error: 'Metamask not found' }
    }

    if (window.ethereum) {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' })
      if (accounts.length > 0) {
        return { success: true, account: accounts[0] }
      } else {
        return { success: false, error: 'No accounts found' }
      }
    }
  } catch (error) {
    return { success: false, error: error.message }
  }
}

export const switchToAvalanche = async () => {
  const AVALANCHE_MAINNET_PARAMS = getParams()
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: AVALANCHE_MAINNET_PARAMS.chainId }], // Avalanche Mainnet
    })
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [AVALANCHE_MAINNET_PARAMS],
        })
      } catch (addError) {
        // Handle adding Avalanche chain error
        throw new Error(`Error adding Avalanche network: ${addError.message}`)
      }
    } else {
      // Handle other errors
      throw new Error(
        `Error switching to Avalanche network: ${switchError.message}`
      )
    }
  }
}

export const buyShares = async (
  shareSubject,
  amount,
  isReferral,
  referer,
  avaxAmount,
  web3address
) => {
  try {
    if (!referer) {
      referer = '0x0000000000000000000000000000000000000000'
    }
    let contract = await getContract(web3address)
    if (!contract.success) {
      return { success: false, message: contract.error }
    }
    contract = contract.contract
    const tx = await contract.buyShares(
      shareSubject,
      amount,
      isReferral,
      referer,
      {
        value: ethers.parseEther(avaxAmount.toString()),
      }
    )
    const txHash = await tx.hash
    if (txHash) {
      return { success: true, txHash }
    } else {
      return { success: false, message: 'Transaction failed' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

export const sellShares = async (shareSubject, amount, web3address) => {
  try {
    let contract = await getContract(web3address)
    if (!contract.success) {
      return { success: false, message: contract.error }
    }
    contract = contract.contract
    const tx = await contract.sellShares(shareSubject, amount)
    const txHash = await tx.hash
    if (txHash) {
      return { success: true, txHash }
    } else {
      return { success: false, message: 'Transaction failed' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

export const tipping = async (shareSubject, amount, post, web3address) => {
  try {
    let contract = await getContract(web3address)
    if (!contract.success) {
      return { success: false, message: contract.error }
    }
    contract = contract.contract
    amount = ethers.parseEther(amount.toString())
    const tx = await contract.tipUser(shareSubject, amount, post, {
      value: amount,
    })
    const txHash = await tx.hash
    if (txHash) {
      return { success: true, txHash }
    } else {
      return { success: false, message: 'Transaction failed' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

export const transferWeb3 = async (address, tokenId, amount, web3address) => {
  try {
    let contract = await getContract(web3address)
    if (!contract.success) {
      return { success: false, message: contract.error }
    }
    contract = contract.contract
    const tx = await contract.safeTransferFrom(
      web3address,
      address,
      tokenId,
      amount,
      '0x'
    )
    const txHash = await tx.hash
    if (txHash) {
      return { success: true, txHash }
    } else {
      return { success: false, message: 'Transaction failed' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

export const claimRewards = async (shareSubject) => {
  try {
    const contract = await getContract()
    const tx = await contract.distributeShareHolderFees(shareSubject)
    const txHash = await tx.hash
    if (txHash) {
      return { success: true, txHash }
    } else {
      return { success: false, message: 'Transaction failed' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

async function getContract(web3address) {
  if (!window.ethereum) {
    return { success: false, error: 'Metamask not found' }
  }
  await checkCurrentChainId()
  const accounts = await getAccounts()
  if (!accounts.success) {
    await connectWallet()
  }
  if (accounts.account.toLowerCase() != web3address.toLowerCase()) {
    return { success: false, error: 'Wrong wallet connected' }
  }
  const provider = new ethers.BrowserProvider(window.ethereum)
  const signer = await provider.getSigner()
  const contract = new ethers.Contract(
    process.env.VUE_APP_CONTRACT_ADDRESS,
    abi,
    signer
  )
  return { success: true, contract }
}

const checkCurrentChainId = async () => {
  try {
    const chainId = await window.ethereum.request({
      method: 'eth_chainId',
    })
    if (process.env.VUE_APP_NETWORK == 'fuji') {
      if (chainId != '0xa869') {
        await switchToAvalanche()
      }
    } else {
      if (chainId != '0xa86a') {
        await switchToAvalanche()
      }
    }
  } catch (err) {
    console.log(err)
  }
}

const getParams = () => {
  const currentNetwork = process.env.VUE_APP_NETWORK
  console.log('Current env network: ', currentNetwork)

  if (currentNetwork == 'fuji') {
    return {
      chainId: '0xa869',
      chainName: 'Avalanche Fuji Testnet',
      nativeCurrency: {
        name: 'Avalanche',
        symbol: 'AVAX',
        decimals: 18,
      },
      rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
      blockExplorerUrls: ['https://cchain.explorer.avax-test.network'],
    }
  } else {
    return {
      chainId: '0xa86a',
      chainName: 'Avalanche Mainnet',
      nativeCurrency: {
        name: 'Avalanche',
        symbol: 'AVAX',
        decimals: 18,
      },
      rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
      blockExplorerUrls: ['https://cchain.explorer.avax.network'],
    }
  }
}
