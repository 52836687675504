<template>
  <div :key="paramUser">
    <template v-if="!isLoading">
      <div v-if="userToShow">
        <!-- Profile Image -->
        <div class="top-card d-flex align-center justify-center w-100">
          <div
            :class="[
              'bg-image',
              userToShow.profileBannerUrl ? 'has-banner' : 'no-banner',
            ]"
            :style="`background-image: url(${userToShow.profileBannerUrl})`"
          ></div>
        </div>
        <div class="text-center mt-n16">
          <v-avatar
            size="100"
            :image="largerImageUrl(userToShow.profileImageUrl)"
            class="avatar"
            color="surface"
          ></v-avatar>
          <h3>{{ userToShow.displayName }}</h3>
          <span class="text-subtitle-2 text-disabled"
            >@{{ userToShow.username }}</span
          >
        </div>

        <div class="profile-info w-100 mb-5 pr-3 pl-3">
          <div
            class="d-flex flex-row justify-space-between align-center justify-center mb-2"
          >
            <div
              class="d-flex flex-start action-buttons w-100 align-center justify-left pr-2"
            >
              <FollowButton
                class="mr-3 post-user-btns"
                variant="elevated"
                size="default"
                color="primary"
                :user="userToShow"
                v-if="!userToShow.isMe"
              />
              <v-btn
                class="mr-3 trade-btn post-user-btns"
                density="compact"
                size="default"
                variant="outlined"
                :icon="true"
                @click.stop="openTippingPopup(userToShow)"
              >
                <v-icon size="x-small">mdi-currency-usd</v-icon>
              </v-btn>
              <v-btn
                class="trade-btn post-user-btns"
                density="compact"
                size="default"
                variant="outlined"
                :icon="true"
                v-if="userToShow.isMe || userToShow.isHolder"
                :disabled="!holderRoom"
                @click.stop="navigateToHolderRoom"
              >
                <v-icon size="x-small">mdi-message-outline</v-icon>
              </v-btn>
            </div>
            <div class="share-price justify-right d-flex flex-column">
              <div
                class="text-subtitle-1 w-100 d-flex flex-row align-center font-weight-bold justify-right flex-row-reverse"
              >
                {{ userToShow.sharePrice
                }}<i :class="['avax-icon mr-1', 'small']"></i>
              </div>
              <div class="text-caption w-100 text-right text-disabled">
                Ticket Price
              </div>
            </div>
            <!-- <v-btn
                class="post-user-btns"
                density="default"
                size="small"
                color="primary"
                variant="tonal"
              >
                Buy
              </v-btn> -->
          </div>
          <p class="text-left text-body-2 text-medium-emphasis mb-4">
            {{ userToShow.description }}
          </p>
          <div class="d-flex flex-row text-body-1 font-weight-bold lists">
            <p class="mr-3" @click.stop="navigateToUserList('followers')">
              {{
                formatNumber(
                  userToShow.followersCount ? userToShow.followersCount : 0
                )
              }}
              <span class="text-disabled text-body-2">Followers</span>
            </p>
            <p class="mr-3" @click.stop="navigateToUserList('following')">
              {{
                formatNumber(
                  userToShow.followingCount ? userToShow.followingCount : 0
                )
              }}
              <span class="text-disabled text-body-2">Following</span>
            </p>
            <p class="mr-3" @click="goToTwitter">
              {{
                formatNumber(
                  userToShow.followers_count ? userToShow.followers_count : 0
                )
              }}
              <span class="text-disabled text-body-2"
                ><v-icon class="x-icon mr-n1"></v-icon> Followers</span
              >
            </p>
          </div>
        </div>

        <v-tabs
          v-model="tab"
          align-tabs="center"
          bg-color="surface"
          grow
          color="primary"
        >
          <v-tab value="posts">Posts</v-tab>
          <v-tab value="trade">Trade</v-tab>
          <v-tab value="videos">Videos</v-tab>
          <v-tab value="activity">Activity</v-tab>
        </v-tabs>

        <v-container class="px-0">
          <v-window v-model="tab">
            <v-window-item value="posts">
              <Feed
                :filterType="'personalPosts'"
                :profileUserId="userToShow.twitterId"
              />
            </v-window-item>
            <v-window-item value="videos" class="px-4">
              <ProfileVideos :profileUserId="userToShow.twitterId" />
            </v-window-item>
            <v-window-item value="trade" class="px-4">
              <template v-if="tab === 'trade'">
                <ProfileTrade
                  :userdata="userToShow"
                  @refetch="refetchUser"
                  @updatePrice="updatePrice"
                />
              </template>
            </v-window-item>
            <v-window-item value="activity">
              <template v-if="tab === 'activity'">
                <ProfileActivity :userdata="userToShow" />
              </template>
            </v-window-item>
          </v-window>
        </v-container>
      </div>
      <Tipping
        :show="showTipDialog"
        :subject="subject"
        :postId="postId"
        :type="'user'"
        @close="showTipDialog = false"
        @tip="handleTipAmount"
      />
    </template>

    <v-container
      v-else
      fluid
      fill-height
      class="d-flex h-100 align-center justify-center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-container>
  </div>
</template>

<script setup>
import ProfileActivity from '@/components/ProfileActivity.vue'
import ProfileTrade from '@/components/ProfileTrade.vue'
import FollowButton from '@/components/UserActions/FollowButton.vue'
import Tipping from '@/components/UserActions/Tipping.vue'
import Feed from '@/views/Feed'
import ProfileVideos from '@/views/ProfileVideos'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getHolderRoom, userProfile } from '../utils/apiUtils'
import { formatNumber } from '../utils/helpers'

const isOtherProfile = ref(false)
const profileUser = ref(null)
const isFollowingOther = ref(false)
const holderRoom = ref(null)
// const showTradePopup = ref(false)
const tab = ref('posts')
const isLoading = ref(true)

const store = useStore()
const router = useRouter()
const route = useRoute()

const showTipDialog = ref(false)
const subject = ref(null)
const postId = ref(null)

let stopParamWatcher = null

onMounted(async () => {
  await router.isReady()
  await fetchUserData()
  updateTitleBar()
  await getHolderRoomDetails()

  stopParamWatcher = watch(
    () => route.params.username,
    async (newUsername, oldUsername) => {
      if (newUsername !== oldUsername) {
        await fetchUserData()
        updateTitleBar()
        await getHolderRoomDetails()
      }
    }
  )
})

const navigateToUserList = (type) => {
  if (holderRoom.value.room.friendlyId) {
    router.push({ path: `/profile/${userToShow.value.username}/${type}` })
  }
}

const navigateToHolderRoom = () => {
  if (holderRoom.value.room.friendlyId) {
    router.push({ path: `/room/${holderRoom.value.room.friendlyId}` })
  }
}

const updateTitleBar = () => {
  if (route.path.startsWith('/profile')) {
    store.commit('updateAppBarTitle', userToShow.value.displayName)
  }
}

onUnmounted(() => {
  if (stopParamWatcher) {
    stopParamWatcher()
  }
  store.commit('updateAppBarTitle', null)
})

const handleTipAmount = (tipAmount) => {
  console.log('tipAmount', tipAmount)
}

const openTippingPopup = (user) => {
  subject.value = user.address
  postId.value = user._id
  showTipDialog.value = true
}
const goToTwitter = () => {
  window.open(`https://www.x.com/${userToShow.value.username}`, '_blank')
}

const fetchUserData = async () => {
  isLoading.value = true
  try {
    const usernameToFetch = route.params.username
      ? route.params.username
      : user.value.username
    isOtherProfile.value = route.params.username !== user.value.username
    const userProfileResponse = await userProfile(usernameToFetch)

    isFollowingOther.value = userProfileResponse.user.isCurrentUserFollowingUser
    profileUser.value = userProfileResponse.user
    console.log(profileUser.value)
  } catch (error) {
    console.log(error)
  } finally {
    isLoading.value = false
  }
}

const getHolderRoomDetails = async () => {
  try {
    const usernameToFetch = userToShow.value.username
    const holderCall = await getHolderRoom(usernameToFetch)
    console.log('holderCall', holderCall)
    if (holderCall.success) {
      holderRoom.value = holderCall
    } else {
      holderRoom.value = null
    }
  } catch (error) {
    console.log(error)
  }
}

const updatePrice = async (newPrice) => {
  profileUser.value.sharePrice = newPrice
}

const refetchUser = async () => {
  await fetchUserData()
}

const largerImageUrl = (normalImageUrl) => {
  return normalImageUrl.replace('_normal', '_400x400')
}

const user = computed(() => store.state.user)

const userToShow = computed(() => {
  return profileUser.value
})

const paramUser = computed(() => {
  console.log('route', route.fullPath)
  return route.fullPath
})
</script>

<style lang="scss" scoped>
.lists p {
  cursor: pointer;
}
.share-price {
  min-width: 100px;
  text-align: right;
}
.trade-btn {
  text-transform: capitalize !important;
}
.top-card {
  position: relative;
  &:before {
    background: rgb(255, 181, 5);
    background: linear-gradient(
      0deg,
      rgba(255, 181, 5, 0.2) 0%,
      rgba(255, 181, 5, 0) 61%
    );
    width: 100%;
    height: 100%;
    position: absolute;
    border-bottom: 1px solid #ffffff36;
    top: 0;
    left: 0;
    content: '';
  }
  .bg-image {
    background-size: 100%;
    width: 100%;
    height: 100%;
    min-height: 138px;

    position: relative;
    display: block;
    top: 0;
    left: 0;
    @media (min-width: 768px) {
      min-height: 230px;
    }
  }
}
.avatar {
  border: 1px rgba(var(--v-theme-brand)) solid;
}
.profile-page {
  padding: 20px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.followers,
.share-price {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
</style>
