import DOMPurify from 'dompurify'

/**
 * Parsing content, breaking it down into lines and then parsing each line into array of text and usernames
 * So that the usernames can be converted into router links directly during template generation
 * Hence avoiding use of injecting html in innerHTML or v-html
 */
export const processContent = (post) => {
  const paragraphs = []

  if (post.isDeleted || !post.content) {
    return []
  }

  const content = (post.postType === 'image' ? post.caption : post.content)
    .replace(/&quot;/g, '"')
    .replace(/&#x27;/g, "'")
    .replace(/&amp;/g, '&')
    .replace(/&#x2F;/g, '/')
    .replace(/&#x27;/g, "'")

  // Split content by line breaks into paragraphs
  const lines = content.split('\n')

  lines.forEach((line) => {
    let lastIndex = 0
    let paragraphParts = []
    let contentToReturn = line

    // Remove Tenor GIF URLs
    const gifUrlPattern = /https:\/\/media\.tenor\.com\/.+\.gif/g
    contentToReturn = contentToReturn.replace(gifUrlPattern, '')

    // Update YouTube URL pattern to include query parameters
    const youtubeUrlPattern =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]+(?:\?[\s\S]*)?/g
    contentToReturn = contentToReturn.replace(youtubeUrlPattern, '')

    // Trim and sanitize the content to be returned
    contentToReturn = DOMPurify.sanitize(contentToReturn.trim())

    // Combine username and URL patterns
    const urlPattern = /(\bhttps?:\/\/\S+[^ \t\n\r\f\v\b])/g
    const pattern = new RegExp(`@(\\w+)|${urlPattern.source}`, 'g')

    contentToReturn.replace(pattern, (match, username, url, offset) => {
      // Add text before the match
      if (offset > lastIndex) {
        paragraphParts.push({
          isUsername: false,
          isUrl: false,
          text: contentToReturn.slice(lastIndex, offset),
        })
      }

      // Check if match is a username or a URL
      if (username) {
        if (username.includes('_no_link')) {
          username = username.replace('_no_link', '')
          paragraphParts.push({ isUsername: false, text: username })
        } else {
          paragraphParts.push({ isUsername: true, text: username })
        }
      } else if (url) {
        paragraphParts.push({ isUsername: false, isUrl: true, text: url })
      }

      lastIndex = offset + match.length
    })

    // Add any remaining text after the last match
    if (lastIndex < contentToReturn.length) {
      paragraphParts.push({
        isUsername: false,
        isUrl: false,
        text: contentToReturn.slice(lastIndex),
      })
    }

    // Add this paragraph's parts to the paragraphs array
    if (paragraphParts.length > 0) {
      paragraphs.push(paragraphParts)
    }
  })

  return paragraphs
}

export const getTenorGifUrl = (post) => {
  if (post.isDeleted || !post.content) {
    return
  }
  const content = (post.postType === 'image' ? post.caption : post.content)
    .replace(/&quot;/g, '"')
    .replace(/&#x27;/g, "'")
    .replace(/&amp;/g, '&')
    .replace(/&#x2F;/g, '/') // Unescape the URL

  // Use regex to find the Tenor GIF URL
  const gifUrlMatch = content.match(/https:\/\/media\.tenor\.com\/.+\.gif/g)
  if (gifUrlMatch && gifUrlMatch[0]) {
    return gifUrlMatch[0]
  }

  return null
}

export const getYoutubeVideoUrl = (post) => {
  if (post.isDeleted || !post.content) {
    return null
  }

  const content = (post.postType === 'image' ? post.caption : post.content)
    .replace(/&quot;/g, '"')
    .replace(/&#x27;/g, "'")
    .replace(/&amp;/g, '&')
    .replace(/&#x2F;/g, '/')

  // Regular expression to match YouTube URLs and capture video ID
  const youtubeUrlPattern =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/
  const youtubeUrlMatch = content.match(youtubeUrlPattern)

  if (youtubeUrlMatch && youtubeUrlMatch[1]) {
    // Return the embeddable YouTube URL format
    return `https://www.youtube.com/embed/${youtubeUrlMatch[1]}`
  }

  return null
}
