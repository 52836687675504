<template>
  <div @click="openRoom" class="pb-4 pt-4 px-4 d-flex flex-row align-top">
    <v-icon
      v-if="room.isPinned"
      color="primary"
      size="small"
      class="mr-2 mt-4 text-disabled"
      >mdi-pin-outline</v-icon
    >
    <div class="d-flex flex-column justify-center align-center">
      <v-avatar
        :image="getSmallerImage(room.profileImageUrl)"
        size="40"
        color="surface"
      />
      <!-- <v-icon
        v-if="room.isShareholderRoom"
        icon="mdi-account-supervisor-circle"
        color="primary"
        class="mt-n4 holders"
      ></v-icon> -->
      <v-chip
        v-if="room.isShareholderRoom"
        variant="elevated"
        size="x-small"
        color="secondary"
        class="mt-n1"
        >Holders</v-chip
      >
    </div>

    <!-- Room Details -->
    <div class="flex-grow-1 ml-3 text-truncate rommchat">
      <!-- Room Name -->
      <div class="room-name">{{ room.name }}</div>
      <div
        v-if="room.latestMessage"
        class="latest-message-sender text-disabled"
      >
        {{ room.latestMessage.sender }}
      </div>
      <div
        v-if="room.latestMessage"
        class="latest-message-content font-italic text-disabled"
      >
        {{ room.latestMessage.content }}
      </div>
    </div>

    <!-- Menu -->
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn
          size="x-small"
          variant="plain"
          icon="mdi-dots-vertical"
          v-bind="props"
        ></v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="pinRoom()"
          prepend-icon="mdi-pin"
          class="clickable text-left"
        >
          {{ room.isPinned ? 'Unpin' : 'Pin' }}
        </v-list-item>
        <v-list-item
          @click="leaveRoom()"
          prepend-icon="mdi-exit-to-app"
          class="clickable text-left"
          >Leave
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
  <v-divider></v-divider>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  room: Object,
  sock: Object,
})
const emit = defineEmits(['refresh', 'setRoomsCache'])
const router = useRouter()
const openRoom = () => {
  emit('setRoomsCache')
  router.push({
    name: 'SingleRoom',
    params: { roomId: props.room.friendlyId },
  })
}

const getSmallerImage = (imageUrl) => {
  if (imageUrl.includes('twimg.com')) return imageUrl
  return `${imageUrl}?tr=w-40,h-40`
}

const socket = props.sock

const leaveRoom = () => {
  if (socket) {
    socket.emit('leaveRoom', props.room.friendlyId)
    emit('refresh')
  }
  // router.push({ name: 'Rooms' })
}

const pinRoom = () => {
  if (socket) {
    socket.emit('pinRoom', props.room.friendlyId)
  }
}

onMounted(() => {
  if (socket) {
    socket.on('pinnedRoom', () => {
      emit('refresh')
    })

    socket.on('unpinnedRoom', () => {
      emit('refresh')
    })
  }
})
</script>

<style scoped>
.holders {
  background-color: #4aa9c8;
  border-radius: 100%;
}
.title {
  font-weight: bold;
}

.room-name {
  font-weight: bold;
  cursor: pointer;
}

.latest-message-sender {
  font-size: 0.9em;
}

.latest-message-content {
  font-size: 0.8em;
}

.roomchat {
  cursor: pointer;
}
</style>
