import axios from 'axios'
import {
  buyShares,
  claimRewards,
  sellShares,
  tipping,
  transferWeb3,
} from './wallet'

const apiRequest = async (method, url, data = null) => {
  const contentType =
    method === 'delete' ? 'multipart/form-data' : 'application/json'
  const headers = {
    'Content-Type': contentType,
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }

  const apiUrl = `${process.env.VUE_APP_API_URL}${url}`

  try {
    const response = await axios({
      method,
      url: apiUrl,
      data,
      headers,
    })

    return response.data
  } catch (error) {
    console.log(error)
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    }
  }
}

export const customGet = async (url) => {
  return await apiRequest('get', url, '')
}

export const customPost = async (url, data) => {
  return await apiRequest('post', url, data)
}
export const customDelete = async (url, data) => {
  return await apiRequest('delete', url, data)
}

export const getBuyPrice = async (subjectAddress, amount, source = 'db') => {
  return await apiRequest('post', '/api/contract/getBuyPriceAfterFee', {
    subject: subjectAddress,
    amount,
    source,
  })
}

export const subjectToTokenId = async (subjectAddress) => {
  return await apiRequest('post', '/api/contract/subjectToTokenId', {
    subject: subjectAddress,
  })
}

export const nuke = async () => {
  return await apiRequest('post', '/api/wallet/nuke', {})
}

export const sweep = async (addresses) => {
  return await apiRequest('post', '/api/wallet/sweep', { addresses })
}

export const getSellPrice = async (subjectAddress, amount, source = 'db') => {
  return await apiRequest('post', '/api/contract/getSellPriceAfterFee', {
    subject: subjectAddress,
    amount,
    source,
  })
}

export const getBalance = async (account) => {
  return await apiRequest('post', '/api/contract/getBalance', { account })
}

export const buyTicket = async (
  subjectAddress,
  amount,
  price,
  pin,
  useWeb3Wallet = false,
  referrer = '0x0000000000000000000000000000000000000000',
  web3address
) => {
  if (useWeb3Wallet) {
    // todo fetch referrer information
    console.log('buying with web3 wallet', referrer)
    return await buyShares(
      subjectAddress,
      amount,
      true,
      referrer,
      price,
      web3address
    )
  } else {
    return await apiRequest('post', '/api/wallet/buy', {
      shareSubject: subjectAddress,
      amount,
      price,
      pin,
      network: 'avalanche',
    })
  }
}

export const sellTicket = async (
  subjectAddress,
  amount,
  pin,
  useWeb3Wallet = false,
  web3address
) => {
  if (useWeb3Wallet) {
    return await sellShares(subjectAddress, amount, web3address)
  } else {
    return await apiRequest('post', '/api/wallet/sell', {
      shareSubject: subjectAddress,
      amount,
      pin,
      network: 'avalanche',
    })
  }
}

export const transfer = async (
  address,
  tokenId,
  amount,
  pin,
  useWeb3Wallet = false,
  web3address
) => {
  console.log(
    'transfer',
    address,
    tokenId,
    amount,
    pin,
    useWeb3Wallet,
    web3address
  )
  if (useWeb3Wallet) {
    console.log('transfering with web3 wallet')
    return await transferWeb3(address, tokenId, amount, web3address)
  } else {
    console.log('transfering with api')
    return await apiRequest('post', '/api/wallet/transfer', {
      address,
      tokenId,
      amount,
      pin,
    })
  }
}

export const tip = async (
  subjectAddress,
  amount,
  post,
  type = 'post',
  pin,
  useWeb3Wallet,
  web3address
) => {
  if (useWeb3Wallet) {
    return await tipping(subjectAddress, amount, type + '_' + post, web3address)
  } else {
    return await apiRequest('post', '/api/wallet/tip', {
      shareSubject: subjectAddress,
      amount,
      pin,
      network: 'avalanche',
      post: type + '_' + post,
    })
  }
}

export const getPortfolioValue = async (username) => {
  return await apiRequest('get', `/api/users/portfolio/${username}`)
}

export const getAvaxPrice = () => apiRequest('get', '/api/contract/avaxPrice')

export const withdraw = async (to, amount, pin) => {
  return await apiRequest('post', '/api/wallet/withdraw', {
    to,
    amount,
    pin,
  })
}

export const userProfile = async (username) => {
  return await apiRequest('get', `/api/users/profile/${username}`)
}

export const getBalanceOf = async (address, tokenId) => {
  return await apiRequest('post', '/api/contract/getBalanceOf', {
    address,
    tokenId,
  })
}

export const getHolderRoom = async (username) => {
  return await apiRequest('get', `/api/chat/room/${username}`)
}

export const distribute = async (subject, useWeb3Wallet) => {
  if (useWeb3Wallet) {
    return await claimRewards(subject)
  } else {
    return await apiRequest('post', '/api/wallet/distribute', {
      shareSubject: subject,
    })
  }
}

export const pendingShareHolderFees = async (subject) => {
  return await apiRequest('post', '/api/contract/pendingShareHolderFees', {
    subject,
  })
}

export const getTransaction = async (hash) => {
  return await apiRequest('get', `/api/transactions/hash/${hash}`)
}
