<template>
  <div>
    <!-- Button to toggle dialog -->
    <v-btn
      size="large"
      density="compact"
      icon="mdi-file-gif-box"
      @click="toggleDialog"
      flat
    ></v-btn>

    <!-- Dialog component -->
    <v-dialog v-model="dialog" max-width="600px" max-height="600px">
      <v-card>
        <!-- Search Input -->
        <v-text-field
          v-model="searchTerm"
          @input="searchGifs"
          placeholder="Search Tenor"
          class="search-gif w-100 bg-surface"
          outlined
          :hide-details="true"
          clearable
        ></v-text-field>

        <!-- Grid for GIFs -->
        <div class="grid pa-1 mt-14" v-if="gifs.length > 0">
          <div v-for="(gif, index) in gifs" :key="index" class="grid-item ma-1">
            <img :src="gif.media_formats.nanogif.url" @click="selectGif(gif)" />
          </div>
        </div>
      </v-card>
      <p class="text-caption text-right">Powered By Tenor</p>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue'

// State for dialog visibility, search term, and gifs
const dialog = ref(false)
const searchTerm = ref('')
const gifs = ref([])
const emit = defineEmits(['select-gif'])

// Function to search for gifs
const searchGifs = (category) => {
  const apiKey = process.env.VUE_APP_TENOR_API
  const limit = 36
  const searchUrl =
    category !== 'suggest'
      ? `https://tenor.googleapis.com/v2/search?q=${searchTerm.value}&key=${apiKey}.&client_key=my_test_app&limit=${limit}`
      : `https://tenor.googleapis.com/v2/featured?key=${apiKey}&client_key=my_test_app&limit=${limit}`

  fetch(searchUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText)
      }
      return response.json()
    })
    .then((data) => {
      gifs.value = data.results
    })
    .catch((error) => {
      console.error(
        'There has been a problem with your fetch operation:',
        error
      )
    })
}

const reportShareEvent = async (gifId) => {
  const apiKey = process.env.VUE_APP_TENOR_API
  const shareUrl = `https://tenor.googleapis.com/v2/registershare?id=${gifId}&key=${apiKey}&client_key=my_test_app&q=excited`

  try {
    const response = await fetch(shareUrl, { method: 'POST' })

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText)
    }
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error)
  }
}

const toggleDialog = () => {
  dialog.value = !dialog.value
  if (dialog.value) {
    console.log('calling suggest')
    searchGifs('suggest')
  }
}

// Function to handle gif selection
const selectGif = (gif) => {
  reportShareEvent(gif.id)
  emit('select-gif', gif)
  gifs.value = []
  searchTerm.value = ''
  dialog.value = false
}
</script>
<style lang="scss" scoped>
.search-gif {
  position: fixed;
  z-index: 20;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-rows: 100px;
  .grid-item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

/* .masonry-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  img {
    height: 90px;
    width: auto;
    margin: 2px;
  }
} */
</style>
