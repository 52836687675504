<template>
  <div class="meta">
    <v-sheet color="surface" elevation="2" rounded class="mb-4">
      <v-row class="d-flex justify-space-between ma-0 primary">
        <v-col
          @click="showMetaPopup(`Total ticket buys and sells for this profile`)"
          cols="4"
          class="d-flex flex-column align-center"
        >
          <p class="text-h6 font-weight-bold align-center">
            <span class="text-green"
              >+{{ formatNumber(user.buys ? user.buys : 0) }}</span
            >
            /
            <span class="text-red">
              -{{ formatNumber(user.sells ? user.sells : 0) }}</span
            >
          </p>
          <span class="text-caption font-weight-light"> Buy / Sells </span>
        </v-col>
        <v-col
          @click="showMetaPopup(`Total supply of tickets`)"
          cols="4"
          class="d-flex flex-column align-center"
        >
          <span class="text-h6 font-weight-bold d-flex align-center">
            {{ formatNumber(user.supply ? user.supply : 0) }}
          </span>
          <span class="text-caption font-weight-light"> Supply </span>
        </v-col>
        <v-col
          @click="
            showMetaPopup(`Lifetime volume of tickets traded for this profile`)
          "
          cols="4"
          class="d-flex flex-column align-center"
        >
          <span class="text-h6 font-weight-bold d-flex align-center">
            <i :class="['avax-icon', 'small fade mr-1']"></i>
            {{ formatNumber(user.volume ? user.volume : 0) }}
          </span>
          <span class="text-caption font-weight-light"> Volume </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="d-flex justify-space-between ma-0 primary">
        <v-col
          @click="showMetaPopup(`Number of ticket holders of this profile`)"
          cols="4"
          class="d-flex flex-column align-center"
        >
          <span class="text-h6 font-weight-bold">
            {{ formatNumber(user.holders ? user.holders : 0) }}
          </span>
          <span class="text-caption font-weight-light"> Holders </span>
        </v-col>
        <v-col
          @click="
            showMetaPopup(
              `ve3,3 : How much percentage this user is loyal to it's holders`
            )
          "
          cols="4"
          class="d-flex flex-column align-center"
        >
          <span class="text-h6 font-weight-bold">
            {{ formatNumber(user.ve33Percent ? user.ve33Percent : 0) }}%
          </span>
          <span class="text-caption font-weight-light"> Loyalty % </span>
        </v-col>
        <v-col
          @click="showMetaPopup(`Total rewards distributed to it's holders`)"
          cols="4"
          class="d-flex flex-column align-center"
        >
          <span class="text-h6 font-weight-bold d-flex align-center">
            <i :class="['avax-icon', 'small fade mr-1']"></i>
            {{ formatNumber(user.feesDistributed ? user.feesDistributed : 0) }}
          </span>
          <span class="text-caption font-weight-light"> Holder Rewards </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row
        @click="showMetaPopup(`Total followers on X`)"
        class="d-flex justify-space-between ma-0 background-secondary"
      >
        <v-col cols="4" class="d-flex flex-column align-center">
          <span class="text-h6 font-weight-bold">
            {{ formatNumber(user.followersCount ? user.followersCount : 0) }}
          </span>
          <span class="text-caption font-weight-light"> Followers </span>
        </v-col>
        <v-col cols="4" class="d-flex flex-column align-center">
          <span class="text-h6 font-weight-bold">
            {{ formatNumber(user.followingCount ? user.followingCount : 0) }}
          </span>
          <span class="text-caption font-weight-light"> Following </span>
        </v-col>
        <v-col cols="4" class="d-flex flex-column align-center">
          <span class="text-h6 font-weight-bold">
            {{ formatNumber(user.followers_count ? user.followers_count : 0) }}
          </span>
          <span class="text-caption font-weight-light">
            <v-icon class="x-icon"></v-icon> Followers
          </span>
        </v-col>
      </v-row>
    </v-sheet>
    <MetaPopup
      :show="showMetaDialog"
      :text="metaData"
      @update:show="showMetaDialog = $event"
    />
  </div>
</template>
<script setup>
import { formatNumber } from '@/utils/helpers'
import { ref } from 'vue'
import MetaPopup from './MetaPopup.vue'

defineProps({
  user: Object,
})

const showMetaDialog = ref(false)
const metaData = ref('')
const showMetaPopup = (data) => {
  showMetaDialog.value = true
  metaData.value = data
}
</script>
