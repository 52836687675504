<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
        size="x-small"
        variant="plain"
        icon="mdi-dots-vertical"
        v-bind="props"
      ></v-btn>
    </template>
    <v-list>
      <v-list-item
        slim
        v-if="
          post?.user?.address === store.state.user.address && !post.isRepost
        "
        prepend-icon="mdi-pin-outline"
        @click="pinPost"
        class="clickable"
        >{{ isPinned ? 'Unpin' : 'Pin' }}</v-list-item
      >
      <v-list-item
        slim
        v-if="showReport"
        prepend-icon="mdi-alert-decagram-outline"
        @click="reportPost"
        class="clickable"
        >Report</v-list-item
      >
      <v-list-item
        slim
        v-if="showBlock"
        prepend-icon="mdi-block-helper"
        @click="blockUser"
        class="clickable"
        >Block
      </v-list-item>
      <v-list-item
        v-if="showShare"
        slim
        prepend-icon="mdi-share-variant"
        @click="emit('share', post)"
        class="clickable"
        >Share
      </v-list-item>
      <v-list-item
        slim
        v-if="
          post?.user?.address === store.state.user.address && !post.isRepost
        "
        prepend-icon="mdi-trash-can-outline"
        :title="`Delete`"
        @click="deletePost"
        class="clickable text-left"
      />
    </v-list>
  </v-menu>
  <template v-if="isDeleting">
    <v-overlay v-model="isDeleting" class="align-center justify-center">
      <v-progress-circular
        color="secondary"
        indeterminate
        size="32"
      ></v-progress-circular>
    </v-overlay>
  </template>
</template>
<script setup>
import axios from 'axios'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { customPost } from '../../utils/apiUtils'

const props = defineProps({
  post: Object,
  showShare: {
    type: Boolean,
    default: true,
  },
})

const isPinned = ref(props.post.isPinned || false)

const emit = defineEmits(['refresh', 'share'])

const store = useStore()

const isDeleting = ref(false)

const showBlock = computed(() => {
  if (!props.post?.isRepost) {
    return props.post?.user?.address !== store.state.user.address
  } else {
    return props.post?.originalPoster?.address !== store.state.user.address
  }
})
const showReport = computed(() => {
  if (!props.post?.isRepost) {
    return props.post?.user?.address !== store.state.user.address
  } else {
    return props.post?.originalPoster?.address !== store.state.user.address
  }
})

const deletePost = async () => {
  isDeleting.value = true
  let url = `${process.env.VUE_APP_API_URL}/api/${props.post.postType}/delete/${props.post.friendlyId}`

  console.log(url)

  console.log(props.post)

  try {
    const response = await axios.delete(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
        //add a bearer token if you have one from localstorage token
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    console.log(response)
    emit('refresh')
    store.dispatch('showSnackbar', {
      show: true,
      message: 'Successfully deleted!',
      color: 'success',
      timeout: 2000, // Optional. Default is 3000
    })
  } catch (error) {
    console.log(error)
    store.dispatch('showSnackbar', {
      show: true,
      message: error.message,
      color: 'error',
      timeout: 2000, // Optional. Default is 3000
    })
  } finally {
    isDeleting.value = false
  }
}

const pinPost = async () => {
  let url = `/api/posts/pin/${props.post.postId}`

  try {
    const response = await customPost(url, {})

    if (response.success) {
      emit('refresh')
      store.dispatch('showSnackbar', {
        show: true,
        message: response.message,
        color: 'success',
        timeout: 2000, // Optional. Default is 3000
      })
    } else {
      store.dispatch('showSnackbar', {
        show: true,
        message: response.message,
        color: 'error',
        timeout: 2000, // Optional. Default is 3000
      })
    }
  } catch (error) {
    console.log(error)
    store.dispatch('showSnackbar', {
      show: true,
      message: error.message,
      color: 'error',
      timeout: 2000, // Optional. Default is 3000
    })
  }
}

const reportPost = async () => {
  const id = props.post.isRepost
    ? props.post.originalPostFriendlyId
    : props.post.friendlyId

  let url = `${process.env.VUE_APP_API_URL}/api/posts/report/${id}`

  console.log(url)

  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          //add a bearer token if you have one from localstorage token
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )

    store.dispatch('showSnackbar', {
      show: true,
      message: response.data.message,
      color: 'error',
      timeout: 2000, // Optional. Default is 3000
    })
  } catch (error) {
    console.log(error)
    store.dispatch('showSnackbar', {
      show: true,
      message: error.message,
      color: 'error',
      timeout: 2000, // Optional. Default is 3000
    })
  }
}
const blockUser = async () => {
  const id = props.post.isRepost
    ? props.post.originalPostFriendlyId
    : props.post.friendlyId
  let url = `${process.env.VUE_APP_API_URL}/api/users/block/${id}`

  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          //add a bearer token if you have one from localstorage token
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )

    emit('refresh')
    store.commit('removeBlockedUsersFromCache', response.data.blockedUserId)
    store.dispatch('showSnackbar', {
      show: true,
      message: 'User successfully blocked!',
      color: 'success',
      timeout: 2000, // Optional. Default is 3000
    })
  } catch (error) {
    console.log(error)
    let errorMessage = error.message
    if (error.response && error.response.data && error.response.data.error) {
      errorMessage = error.response.data.error
    }
    store.dispatch('showSnackbar', {
      show: true,
      message: errorMessage,
      color: 'error',
      timeout: 2000, // Optional. Default is 3000
    })
  }
}
</script>
