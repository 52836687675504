<template>
  <div v-if="!isLoading" class="pb-4">
    <div
      v-if="filter == 'leaderboard' && !filterList"
      class="my-4 mb-2 mx-2 mx-md-0 d-flex flex-row align-center justify-center"
    >
      <v-btn
        append-icon="mdi-unfold-more-horizontal"
        size="default"
        variant="flat"
        class="d-flex justify-space-between ml-0 flex-1-0"
        :ripple="false"
        color="control"
      >
        Category
        <span class="text-primary ml-2">{{ selectedField }}</span>
        <v-menu activator="parent">
          <v-list class="category-select">
            <v-list-item
              v-for="(category, index) in categories"
              :key="index"
              :value="index"
              :class="category === selectedField ? 'text-primary' : ''"
              @click="fetchSortedData(category)"
            >
              <v-list-item-title>{{ category }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </div>
    <v-sheet
      v-if="sweepMode && mdAndUp"
      rounded
      class="bottom-slider mt-3 mx-md-0 mx-2 px-4 py-2 d-flex flex-row align-center"
    >
      <!-- Slider for selecting users based on position -->
      <div class="sweep-slider d-flex flex-row align-center">
        <v-chip size="small" variant="outlined" label class="mr-4">
          <v-icon start icon="mdi-broom"></v-icon>
          {{ selectedUsers.length }}
        </v-chip>

        <v-slider
          v-model="sliderValue"
          :max="users.length"
          :step="1"
          hide-details
          @input="updateSelection"
          class="mr-4"
          @touchstart.stop
          @touchmove.stop
          @touchend.stop
          :disabled="isSweeping"
        ></v-slider>
      </div>
      <v-spacer></v-spacer>
      <div class="mr-4 d-flex flex-row align-end">
        <span
          v-if="!hasEnoughBalance && totalSweepValue > 0"
          class="text-caption text-disabled mr-2"
          >Not Enough Balance</span
        >
        <span
          class="text-subtitle-2 d-flex flex-row align-center justify-center"
          >{{ formatNumber(totalSweepValue) }}
          <i :class="['avax-icon ml-1', 'x-small']"></i
        ></span>
      </div>
      <v-btn
        size="small"
        @click.stop="sweepTickets"
        :loading="isSweeping"
        :disabled="!hasEnoughBalance"
        color="primary"
        :title="!hasEnoughBalance ? 'Not Enough Balance' : 'Sweep Tickets'"
        >Buy</v-btn
      >
    </v-sheet>
    <v-row class="px-4 mt-2">
      <v-col cols="4" class="text-left text-overline text-disabled">User</v-col>
      <v-col cols="8" class="text-right text-overline text-disabled">{{
        selectedField
      }}</v-col>
    </v-row>
    <v-divider class="mb-2"></v-divider>

    <v-row
      @click="toggleExpandActions(index)"
      v-for="(user, index) in users"
      :key="index"
      :class="rowClasses[index]"
    >
      <v-col cols="8" class="d-flex align-center flex-row justify-start">
        <v-checkbox-btn
          density="compact"
          hide-details
          flat
          :disabled="isSweeping"
          :ripple="false"
          class="select-user"
          @click.stop
          v-if="sweepMode"
          v-model="selectedUsers"
          :value="{
            address: user.address,
            price: user.buyPriceAfterFee,
          }"
        ></v-checkbox-btn>
        <v-avatar color="surface-variant" class="mx-4">
          <v-img
            :src="user.profileImageUrl"
            max-height="50"
            max-width="50"
          ></v-img>
        </v-avatar>
        <v-menu :transition="false" open-on-hover>
          <template v-slot:activator="{ props }">
            <span v-bind="props">
              {{ user.displayName }}
            </span>
          </template>
          <UserPopup :user="user" />
        </v-menu>
      </v-col>

      <v-col cols="4" class="d-flex align-center flex-row justify-end">
        <div class="mr-4 d-flex align-center justify-end">
          <span>{{ getFilteredData(user) }}</span>
          <i
            :class="['avax-icon ml-1', 'small']"
            v-if="iconFilter == 'avax-icon'"
          ></i>
          <v-icon class="x-icon" v-if="iconFilter == 'x-icon'"></v-icon>
        </div>
      </v-col>
      <v-expand-transition>
        <div
          class="expand-actions justify-center d-flex flex-row align-center mb-4 px-7"
          v-if="expandedRow === index"
        >
          <v-btn
            @click="navigateToProfile(user.username)"
            variant="outlined"
            class="mr-3 text-capitalize"
            size="small"
            >Profile</v-btn
          >
          <v-btn
            variant="outlined"
            class="mr-3 text-capitalize"
            size="small"
            @click.stop="openTradePopup(user)"
            >Trade</v-btn
          >
        </div>
      </v-expand-transition>
      <v-divider></v-divider>
    </v-row>

    <TradeTicket
      v-if="showTradePopup"
      :user="selectedUser"
      @close="showTradePopup = false"
    />
  </div>
  <LeaderboardSkeleton :quantity="10" v-else />
  <v-app-bar v-if="!mdAndUp" location="bottom" order="1">
    <v-progress-linear
      class="position-absolute"
      indeterminate
      color="primary"
      v-if="isSweeping"
    ></v-progress-linear>

    <v-sheet
      v-if="sweepMode"
      rounded
      class="bottom-slider mx-md-0 mx-2 px-4 py-2 d-flex flex-row align-center w-100"
    >
      <!-- Slider for selecting users based on position -->
      <div class="sweep-slider d-flex flex-row align-center">
        <v-chip size="small" variant="outlined" label class="mr-4">
          <v-icon start icon="mdi-broom"></v-icon>
          {{ selectedUsers.length }}
        </v-chip>

        <v-slider
          v-model="sliderValue"
          :max="users.length"
          :step="1"
          hide-details
          @input="updateSelection"
          class="mr-4"
          @touchstart.stop
          @touchmove.stop
          @touchend.stop
          :disabled="isSweeping"
        ></v-slider>
      </div>
      <v-spacer></v-spacer>

      <div class="mr-4 d-flex flex-column align-end">
        <span
          class="text-subtitle-2 d-flex flex-row align-center justify-center"
          >{{ formatNumber(totalSweepValue) }}
          <i :class="['avax-icon ml-1', 'x-small']"></i
        ></span>
      </div>
      <span
        v-if="!hasEnoughBalance && totalSweepValue > 0"
        class="text-caption text-disabled text-center"
        >Low <br />
        Balance</span
      >

      <v-btn
        v-else
        size="small"
        @click.stop="sweepTickets"
        variant="elevated"
        color="primary"
        :loading="isSweeping"
        :disabled="!hasEnoughBalance && totalSweepValue > 0"
        >Buy</v-btn
      >
    </v-sheet>
  </v-app-bar>
</template>

<script setup>
import { sweep } from '@/utils/apiUtils'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useStore } from 'vuex'
import { customGet, customPost, getBalance } from '../../utils/apiUtils'
import { formatNumber } from '../../utils/helpers'
import LeaderboardSkeleton from '../Loaders/LeaderboardSkeleton.vue'
import UserPopup from '../Post/UserPopup.vue'
import TradeTicket from '../Tickets/TradeTicket.vue'

const props = defineProps({
  type: String,
  filterList: {
    type: String,
    default: null,
  },
})
const store = useStore()
const users = ref([])
const router = useRouter()
const { mdAndUp } = useDisplay()
const isLoading = ref(true)
const sweepMode = ref(true)
const selectedUsers = ref([]) // Object to track selected users
const sliderValue = ref(0) // Slider value
const totalSweepValue = ref(0.0)
const isSweeping = ref(false)
const maxWithdrawAmount = ref(0)
// const showTradePopup = ref(false)
// const selectedUser = ref(null)

const expandedRow = ref(null)

const user = computed(() => store.state.user)

const hasEnoughBalance = computed(() => {
  return maxWithdrawAmount.value > totalSweepValue.value
})

const resetSweepData = () => {
  selectedUsers.value = []
  sliderValue.value = 0
  totalSweepValue.value = 0
}

const updateSelection = () => {
  selectedUsers.value = []
  totalSweepValue.value = 0
  for (let i = 0; i < sliderValue.value; i++) {
    selectedUsers.value.push({
      address: users.value[i].address,
      price: users.value[i].buyPriceAfterFee,
    })
    totalSweepValue.value += parseFloat(
      users.value[i].buyPriceAfterFee.toFixed(2)
    )
  }
}
watch(selectedUsers, (newSelection) => {
  // Reset the total sweep value to 0 before recalculating
  totalSweepValue.value = 0

  // Loop through the selected users and add up their buyPriceAfterFee
  newSelection.forEach((selectedUser) => {
    const user = users.value.find((u) => u.address === selectedUser.address)
    if (user) {
      totalSweepValue.value += parseFloat(user.buyPriceAfterFee.toFixed(2))
    }
  })
})

const refetchPrices = async () => {
  if (users.value.length > 0) {
    const response = await customPost('/api/contract/getMultiPrices', {
      addresses: users.value.map((user) => user.address),
    })

    if (response && response.success) {
      const pricesMap = new Map(
        response.prices.map((price) => [price.address, price.price])
      )

      users.value.forEach((user) => {
        const newPrice = pricesMap.get(user.address)
        if (newPrice && user.buyPriceAfterFee !== newPrice) {
          user.buyPriceAfterFee = newPrice
        }
      })
    }
  }
}

const sweepTickets = async () => {
  isSweeping.value = true
  try {
    const sweeping = await sweep(selectedUsers.value)

    if (sweeping.success) {
      if (sweeping.txHashes) {
        for (let i = 0; i < sweeping.txHashes.length; i++) {
          await store.commit('setPendingTransaction', sweeping.txHashes[i])
        }
      }

      await fetchUsers()
      store.dispatch('showSnackbar', {
        show: true,
        message: `${sweeping.txHashes.length} tickets queued for purchase`,
        color: 'info',
        timeout: 2000, // Optional. Default is 3000
      })
      resetSweepData()
    }
  } catch (error) {
    store.dispatch('showSnackbar', {
      show: true,
      message: 'Failed to purchase the tickets. Please try again',
      color: 'error',
      timeout: 2000, // Optional. Default is 3000
    })
  } finally {
    isSweeping.value = false
  }
}

watch(sliderValue, updateSelection)

const rowClasses = computed(() => {
  return users.value.map((user, index) => {
    let classes = []
    if (expandedRow.value === index) {
      classes.push('bg-surface')
    } else {
      classes.push('bg-background')
    }
    if (
      selectedUsers.value.some(
        (selectedUser) => selectedUser.address === user.address
      )
    ) {
      classes.push('bg-highlightSurface')
    }
    return classes
  })
})

// Function to toggle row expansion
const toggleExpandActions = (index) => {
  if (expandedRow.value === index) {
    expandedRow.value = null // Collapse if the same row is clicked again
  } else {
    expandedRow.value = index // Expand the clicked row
  }
}

const filter = props.type
const selectedFilter = ref(null)
const selectedField = ref('Ticket Price')
const iconFilter = ref('avax-icon')
const showTradePopup = ref(false)
const selectedUser = ref(null)
const categories = [
  'Ticket Price',
  'X Followers',
  'Legend Followers',
  'Holders',
  'Supply',
  'Ticket Holder Rewards',
  'Fees Collected',
  'Referral Fees Collected',
  'Tips Received',
  'Tips Sent',
  'Volume',
  'Buys',
  'Sells',
]

const combinedMapping = {
  'Ticket Price': { field: 'buyPrice', icon: 'avax-icon' },
  Holders: { field: 'holders', icon: '' },
  'X Followers': { field: 'followers_count', icon: 'x-icon' },
  'Legend Followers': { field: 'followersCount', icon: 'legendary-icon' },
  Supply: { field: 'supply', icon: '' },
  'Ticket Holder Rewards': { field: 'feesDistributed', icon: 'avax-icon' },
  'Fees Collected': { field: 'feesCollected', icon: 'avax-icon' },
  'Referral Fees Collected': {
    field: 'referralFeesCollected',
    icon: 'avax-icon',
  },
  'Tips Received': { field: 'tipsCollected', icon: 'avax-icon' },
  'Tips Sent': { field: 'tipsDistributed', icon: 'avax-icon' },
  Volume: { field: 'volume', icon: 'avax-icon' },
  Buys: { field: 'buys', icon: '' },
  Sells: { field: 'sells', icon: '' },
}

const fetchBalance = async () => {
  const balanceData = await getBalance(user.value.address)
  if (balanceData.success) {
    // balance.value = parseFloat(balanceData.balance)
    maxWithdrawAmount.value = parseFloat(balanceData.maxWithdraw)
  }
}

onMounted(async () => {
  if (props.filterList) {
    selectedField.value = props.filterList
    await fetchSortedData(props.filterList)
  } else {
    await fetchUsers()
  }
  await fetchBalance()
  console.log(maxWithdrawAmount.value)
})

const intervalId = setInterval(async () => {
  await refetchPrices()
}, 10000)

onUnmounted(() => {
  clearInterval(intervalId)
})

// const openTradePopup = (user) => {
//   selectedUser.value = user
//   showTradePopup.value = true
// }
const fetchUsers = async () => {
  isLoading.value = true
  let url = `/api/users/top`
  if (props.type === 'new') {
    url = `/api/users/new`
  } else if (props.type === 'trending') {
    url = `/api/users/trending`
  }
  try {
    const response = await customGet(url)
    users.value = response.users
  } catch (error) {
    console.error('Error fetching trending users:', error)
  }
  isLoading.value = false
}

const openTradePopup = (user) => {
  selectedUser.value = user
  showTradePopup.value = true
}

const getFilteredData = (user) => {
  if (!selectedFilter.value) return user.sharePrice
  const field = user[selectedFilter.value]
  return field && field > 0 ? formatNumber(field) : 0
}

const navigateToProfile = (user) => {
  router.push({ name: 'Profile', params: { username: user } })
}

const fetchSortedData = async (category) => {
  isLoading.value = true
  selectedField.value = category
  try {
    const { field, icon } = combinedMapping[selectedField.value] || {}

    if (field && icon !== undefined) {
      selectedFilter.value = field
      iconFilter.value = icon
      const response = await customGet(`/api/users/filter?filter=${field}`)

      if (response.success) {
        users.value = response.users
      }
    }
  } catch (error) {
    console.error('An error occurred while fetching data:', error)
  }
  isLoading.value = false
}
</script>

<!-- Global styles -->
<style lang="scss" scoped>
.sweep-slider {
  max-width: 500px;
  min-width: 225px;
}
.select-user {
  max-width: 20px;
}
.category-select {
  max-height: 300px;
  overflow-y: auto;
}
</style>
