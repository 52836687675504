<template>
  <v-progress-linear
    v-if="isUploading"
    indeterminate
    color="primary"
  ></v-progress-linear>
  <div :class="['video-upload-container', themeClass]">
    <v-container>
      <div class="wrapper-file-input" v-if="!videoFile" @click="openFileInput">
        <div class="file-input-box">
          <h4>
            <i class="fa-solid fa-upload"></i>
            Choose Video to Upload
          </h4>
          <input
            ref="fileInput"
            type="file"
            hidden
            @change="previewVideo"
            accept="video/*"
          />
        </div>
        <small>Files Supported: MP4, AVI, MOV, etc.</small>
      </div>
      <div class="wrapper-file-section" v-if="videoFile">
        <h6 class="text-overline">Selected Video</h6>
        <v-btn class="mb-2" @click="removeVideo" prepend-icon="mdi-close">
          {{ videoFile.name }}
        </v-btn>

        <div class="video-preview">
          <video
            v-if="videoPreview"
            playsinline
            autoplay
            loop
            muted="false"
            :src="videoPreview"
          ></video>
        </div>
        <v-text-field
          label="Enter Caption"
          type="text"
          v-model="title"
          variant="solo"
          :disabled="isUploading"
          class="mb-0"
        ></v-text-field>
        <v-alert
          v-if="captionError"
          type="error"
          dense
          outlined
          class="alert-small mt-0 mb-4"
        >
          {{ captionError }}
        </v-alert>
        <v-btn
          @click="uploadVideo"
          v-if="videoFile"
          color="primary"
          prepend-icon="mdi-upload"
          :loading="isUploading"
          :disabled="isUploading"
        >
          Post
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import { computed, ref } from 'vue'
import { useTheme } from 'vuetify'
import { useStore } from 'vuex'

export default {
  components: {},
  setup() {
    const store = useStore()
    const theme = useTheme()
    const videoFile = ref(null)
    const videoPreview = ref(null)
    const fileInput = ref(null)
    const title = ref('')
    const isUploading = ref(false)
    const captionError = ref('')

    const previewVideo = (event) => {
      const file = event.target.files[0]
      if (file) {
        videoFile.value = file
        videoPreview.value = URL.createObjectURL(file)
      }
    }

    const removeVideo = () => {
      videoFile.value = null
      videoPreview.value = null
    }

    const openFileInput = () => {
      fileInput.value.click()
    }

    const uploadVideo = async () => {
      if (!title.value) {
        captionError.value = 'Caption is required'
        return
      }
      isUploading.value = true
      const formData = new FormData()
      formData.append('video', videoFile.value)
      formData.append('title', title.value)
      // Add other form data like title, description, etc.

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/video/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              //add a bearer token if you have one from localstorage token
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        console.log('Upload successful:', response.data)
        videoFile.value = null
        store.dispatch('showSnackbar', {
          show: true,
          message: 'Posted Successfully!',
          color: 'success',
        })
      } catch (error) {
        store.dispatch('showSnackbar', {
          show: true,
          message: error.response.data.error || error.message,
          color: 'error',
          // timeout: 2000, // Optional. Default is 3000
        })
        console.log('Error uploading video:', error)
      }
      isUploading.value = false
    }

    const themeClass = computed(() => {
      return theme.current.value.dark ? 'dark-mode-class' : 'light-mode-class'
    })

    return {
      //data
      videoFile,
      videoPreview,
      fileInput,
      isUploading,
      title,
      captionError,
      //method
      previewVideo,
      removeVideo,
      openFileInput,
      uploadVideo,
      //computed
      themeClass,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/global.scss';
.post-tabs .v-tabs-slider {
  top: 0 !important;
  bottom: auto !important;
}
.alert-small {
  padding: 4px 10px;
  font-size: 0.675rem;
}
.video-preview {
  height: 100%;
  width: 100%;
  margin: 0 0 1rem 0;
  video {
    width: 65%;
    object-fit: cover;
    max-width: 500px;
    @media (min-width: 768px) {
      // Tablet and above
      max-width: 300px;
    }
  }
}
.video-upload-container {
  flex-direction: column;
  align-items: center;
  display: grid;
  height: 90%;
}
.file-input-box {
  padding: 4rem 2rem;
  border-style: dashed;
  width: 80%;
  margin: 0 auto 1rem;
  border-width: 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
}
.input-box {
  width: 90%;
  border-radius: 4px;
  border: 1px #000000a6 solid;
  font-size: 1.1rem;
  padding: 1rem;
}
.light-mode-class {
  .file-input-box {
    color: #000;
  }
}

.dark-mode-class {
  .file-input-box {
    color: #fff;
  }
}
</style>
