<template>
  <div v-if="!isLoading">
    <template v-if="transactions.length > 0">
      <v-card
        v-for="transaction in transactions"
        :color="!tonal ? '' : transaction.isBuy ? 'green' : 'red'"
        :variant="!tonal ? 'flat' : 'tonal'"
        :key="transaction._id"
        :class="{ 'mb-3': true, new: transaction.isNew }"
      >
        <v-card-text>
          <div class="d-flex flex-row justify-wrap-around">
            <div class="align-start d-flex mr-2 pt-2">
              <v-icon
                v-if="transaction.isBuy"
                icon="mdi-triangle"
                size="small"
                color="green"
              />
              <v-icon
                v-else
                icon="mdi-triangle-down"
                size="small"
                color="red"
              />
            </div>
            <div class="avatars mr-3 d-flex flex-column">
              <div class="d-flex flex-row">
                <v-avatar
                  v-if="transaction?.traderUserId?.profileImageUrl"
                  size="32"
                  variant="elevated"
                  :image="transaction.traderUserId.profileImageUrl"
                ></v-avatar>
                <v-avatar size="32" v-else color="surface-variant"></v-avatar>

                <v-avatar
                  size="32"
                  v-if="transaction?.subjectUserId?.username"
                  class="ml-n4 mt-1"
                  variant="elevated"
                  :image="transaction.subjectUserId.profileImageUrl"
                ></v-avatar>
                <v-avatar
                  v-else
                  size="32"
                  class="ml-n4 mt-1"
                  color="surface-variant"
                ></v-avatar>
              </div>
            </div>
            <div class="desc text-body-2 mr-3">
              <p class="text-left text-body-2">
                <router-link
                  v-if="transaction?.traderUserId?.username"
                  class="text-primary link"
                  :to="`/profile/${transaction.traderUserId.username}`"
                >
                  {{ getTraderName(transaction) }}
                </router-link>
                <span v-else>{{ getTraderName(transaction) }}</span>
                {{ transaction.isBuy ? 'bought' : 'sold' }}
                <router-link
                  v-if="transaction?.subjectUserId?.username"
                  class="text-primary link"
                  :to="`/profile/${transaction.subjectUserId.username}`"
                  >{{ getSubjectName(transaction) }}'s</router-link
                >
                <span v-else>{{ getSubjectName(transaction) }}</span>
                {{ transaction.shareAmount }} ticket{{
                  transaction.shareAmount > 1 ? 's' : ''
                }}
              </p>
              <p class="text-caption text-disabled">
                {{ displayTime(transaction.updatedAt) }}
              </p>
            </div>
            <v-spacer></v-spacer>
            <div
              :class="[
                'price font-weight-bold justify-end d-flex flex-row align-start text-right',
              ]"
            >
              <span
                v-if="!tonal"
                :class="transaction.isBuy ? 'text-green' : 'text-red'"
              >
                {{ transaction.price }}
              </span>
              <span v-else>
                {{ transaction.price }}
              </span>
              <i :class="['avax-icon', 'small fade ml-1 ']"></i>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <p
      v-else
      class="text-body-1 mt-3 text-center font-weight-bold text-disabled"
    >
      No Tickets Activity
    </p>
  </div>
  <div v-else>
    <v-card v-for="n in 10" :key="n">
      <v-card-text>
        <div class="d-flex flex-row justify-wrap-around">
          <div class="avatars mr-3 d-flex flex-row">
            <v-skeleton-loader size="32" type="avatar"></v-skeleton-loader>
          </div>
          <v-skeleton-loader
            class="mx-auto border"
            width="100%"
            type="list-item-two-line"
          ></v-skeleton-loader>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { onMounted, onUnmounted, ref } from 'vue'
import { customPost } from '../../utils/apiUtils'
import { shortenAddress } from '../../utils/helpers'
dayjs.extend(relativeTime)

defineProps({
  user: { type: Object },
  tonal: { type: Boolean, default: true },
})
const isLoading = ref(true)
const transactions = ref([])
const updateInterval = ref(null)

const displayTime = (createdAt) => {
  return dayjs(createdAt).fromNow()
}

const getSubjectName = (transaction) => {
  if (transaction.subjectUserId) {
    return transaction.subjectUserId.displayName
  } else {
    return shortenAddress(transaction.subject)
  }
}

const getTraderName = (transaction) => {
  if (transaction.traderUserId) {
    return transaction.traderUserId.displayName
  } else {
    return shortenAddress(transaction.trader)
  }
}

const getData = async () => {
  isLoading.value = true
  try {
    const response = await customPost(`/api/transactions/user`, {})
    if (response && response.success) {
      transactions.value = response.transactions
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  } finally {
    isLoading.value = false
  }
}

const fetchNewTransactions = async () => {
  try {
    const response = await customPost(`/api/transactions/user`, {})

    if (response && response.success) {
      // Filter out transactions that are already in the existing array
      const newTransactions = response.transactions
        .filter(
          (newTrans) =>
            !transactions.value.find(
              (existingTrans) => existingTrans._id === newTrans._id
            )
        )
        .map((trans) => ({ ...trans, isNew: true })) // Mark each new transaction as new

      if (newTransactions.length > 0) {
        // Prepend new transactions to the start of the array
        transactions.value.unshift(...newTransactions)
      }
    }
  } catch (error) {
    console.error(error)
  }
}

onMounted(async () => {
  await getData()

  updateInterval.value = setInterval(async () => {
    await fetchNewTransactions()
  }, 30000)
})

onUnmounted(() => {
  if (updateInterval.value) {
    clearInterval(updateInterval.value)
  }
})
</script>
<style>
.no-overlay {
  .v-btn__overlay,
  .v-card__overlay {
    background: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
.new {
  animation: newTransaction 1s ease-in-out;
}
@keyframes newTransaction {
  0%,
  100% {
    background-color: transparent; /* Use 'transparent' instead of 'none' for background-color */
  }
  50% {
    background-color: rgba(255, 200, 0, 0.354);
  }
}
</style>
