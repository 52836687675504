<template>
  <div>
    <div class="flake-user d-flex align-center">
      <FollowButton color="white" :user="user" />

      <v-btn
        class="capitalize flake-follow-btn ml-2"
        size="small"
        variant="outlined"
        color="white"
        @click="showTradePopup = !showTradePopup"
      >
        Trade
      </v-btn>
      <TradeTicket
        v-if="showTradePopup"
        :user="user"
        @close="showTradePopup = false"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import TradeTicket from '../Tickets/TradeTicket.vue'
import FollowButton from '../UserActions/FollowButton.vue'

export default {
  props: ['user'],
  components: { TradeTicket, FollowButton },
  setup() {
    const showTradePopup = ref(false)
    return {
      showTradePopup,
    }
  },
}
</script>

<!-- Global styles -->
<style>
.flake-follow-btn {
  .v-btn__loader {
    transform: scale(0.7);
  }
}
</style>

<!-- Scoped styles -->
<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
  margin-right: 5px;
}

.always-white {
  color: white !important;
}
</style>
