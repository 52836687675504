<template>
  <v-list-item class="comment-item mb-2">
    <template v-slot:prepend>
      <v-avatar
        v-if="comment.user?.profileImageUrl"
        class="mr-2"
        size="32"
        :image="comment.user?.profileImageUrl"
      ></v-avatar>
      <v-avatar
        v-else
        class="mr-2"
        size="32"
        color="surface-variant"
      ></v-avatar>
    </template>

    <v-list-item-title class="text-caption">{{
      comment.user?.displayName
    }}</v-list-item-title>
    <ParsedContent
      v-for="(paragraph, index) in parsedContent"
      :key="index"
      :paragraph="paragraph"
      @openLink="processOpenLink"
    />
    <TenorGif class="mb-1" :tenorGifUrl="tenorGifUrl" />
    <p class="text-disabled text-caption">
      {{ displayTime(comment.createdAt) }}
    </p>
  </v-list-item>
</template>
<script setup>
import { getTenorGifUrl, processContent } from '@/utils/postUtils'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { computed } from 'vue'
import ParsedContent from '../PostText/ParsedContent.vue'
import TenorGif from '../PostText/TenorGif.vue'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

const emit = defineEmits(['openLink'])
const props = defineProps({
  comment: Object,
})

/**
 * Getting link item from parsed content component
 * @param {*} linkItem
 */
const processOpenLink = (linkItem) => {
  emit('openLink', linkItem)
}
const displayTime = (createdAt) => {
  return dayjs(createdAt).fromNow()
}
const tenorGifUrl = computed(() => getTenorGifUrl(props.comment))
const parsedContent = computed(() => processContent(props.comment))
</script>
