<template>
  <v-dialog v-model="showTipDialog" max-width="300px" :persistent="isTipping">
    <v-card>
      <v-progress-linear
        v-if="isTipping"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-title>Enter Tip Amount</v-card-title>
      <v-card-text>
        <v-text-field
          class="tip-amount"
          type="number"
          v-model="tipAmount"
          :disabled="isTipping"
          label="Amount"
          hide-details
          hide-spin-buttons
          variant="solo-filled"
        >
          <template v-if="tipUSDValue" v-slot:append-inner>
            <span
              class="text-disabled text-subtitle-2 align-end d-flex h-100 mb-2"
              >${{ formatNumber(tipUSDValue ? tipUSDValue : 0) }}</span
            >
          </template>
        </v-text-field>
        <p
          class="balance d-flex flex-row justify-end text-caption align-center mt-2"
        >
          Balance: <i :class="['avax-icon', 'x-small mr-1']"></i>
          {{ formatNumber(maxWithdrawAmount ? maxWithdrawAmount : 0) }}
        </p>
        <v-text-field
          class="mt-3"
          v-if="pinEnabled"
          v-model="pin"
          label="Enter your PIN"
          type="password"
          variant="solo-filled"
          hide-details="auto"
          :disabled="isTipping"
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn @click="closeDialog" :disabled="isTipping">Cancel</v-btn>
        <v-btn :loading="isTipping" :disabled="isTipping" @click="submitTip"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { getAvaxPrice, getBalance, tip } from '@/utils/apiUtils'
import { formatNumber } from '@/utils/helpers'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  show: Boolean,
  subject: String,
  postId: String,
  type: String,
})

const emit = defineEmits(['close', 'tip'])
const store = useStore()
const tipAmount = ref('')
const isTipping = ref(false)
const showTipDialog = ref(props.show)
const pin = ref('')
const balance = ref(0)
const maxWithdrawAmount = ref(0)
const avaxPrice = ref(0)
const usingMetaMask = computed(() => store.state.user?.useWeb3Wallet)

const user = computed(() => store.state.user)

const fetchBalance = async () => {
  if (user.value) {
    try {
      const balanceData = await getBalance(user.value.address)
      if (balanceData.success) {
        balance.value = usingMetaMask.value
          ? parseFloat(balanceData.web3Balance)
          : parseFloat(balanceData.balance)

        maxWithdrawAmount.value = usingMetaMask.value
          ? parseFloat(balanceData.maxWithdrawWeb3)
          : parseFloat(balanceData.maxWithdraw)
      }
    } catch (error) {
      store.dispatch('showSnackbar', {
        show: true,
        message: error.response?.data?.error || error.message,
        color: 'error',
        // timeout: 2000, // Optional. Default is 3000
      })
      console.log('Error uploading video:', error)
    }
  }
}
const fetchAvaxPrice = async () => {
  try {
    const avaxPriceData = await getAvaxPrice()
    if (avaxPriceData.success) {
      avaxPrice.value = avaxPriceData.price
    }
  } catch (error) {
    store.dispatch('showSnackbar', {
      show: true,
      message: error.response?.data?.error || error.message,
      color: 'error',
      // timeout: 2000, // Optional. Default is 3000
    })
    console.log('Error uploading video:', error)
  }
}

const tipUSDValue = computed(() => {
  if (avaxPrice.value > 0) {
    return avaxPrice.value * tipAmount.value
  }
  return null
})

watch(
  () => props.show,
  async (newVal) => {
    showTipDialog.value = newVal
    await fetchBalance()
    await fetchAvaxPrice()
  }
)

const closeDialog = () => {
  showTipDialog.value = false
  emit('close') // Emit an event to close the dialog
}

const pinEnabled = computed(() => {
  return store.state.user.pinRequired
})

const submitTip = async () => {
  if (pinEnabled.value && pin.value === '') {
    store.dispatch('showSnackbar', {
      show: true,
      message: 'Please enter your PIN.',
      color: 'warning',
      timeout: 2000,
    })
    return
  }
  try {
    isTipping.value = true
    const shareSubject = props.subject
    const amount = tipAmount.value
    const post = props.postId
    const type = props.type ? props.type : 'post'

    const response = await tip(
      shareSubject,
      amount,
      post,
      type,
      pin.value,
      store.state.user?.useWeb3Wallet,
      store.state.user?.web3address ? store.state.user.web3address : null
    )

    // Check the response
    if (response.success) {
      console.log('Tip successful, transaction hash:', response)

      // Update the tip count in the UI
      if (response.tipDollars) {
        emit('tip', response.tipDollars)
      }

      store.commit('setPendingTransaction', response.txHash)

      // Show success Snackbar
      store.dispatch('showSnackbar', {
        show: true,
        message: `Tip transaction is pending. <a href="${process.env.VUE_APP_EXPLORER_TX}${response.txHash}" target="_blank" style="color:#fff">View Status</a>!`,
        color: 'info',
        timeout: 3000,
      })

      pin.value = ''

      // Close the dialog
      emit('close')
    } else {
      console.log('Tip failed:', response.message)

      pin.value = ''
      // Show failure Snackbar
      store.dispatch('showSnackbar', {
        show: true,
        message: `Tip failed: ${response.message}`,
        color: 'error',
        timeout: 2000,
      })
    }
  } catch (error) {
    pin.value = ''
    console.log('An error occurred while tipping:', error)

    // Show error Snackbar
    store.dispatch('showSnackbar', {
      show: true,
      message: `An error occurred: ${error.message}`,
      color: 'error',
      timeout: 2000,
    })
  }
  isTipping.value = false
}
</script>
<style lang="scss">
.tip-amount input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}
.tip-amount input::-webkit-outer-spin-button,
.tip-amount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
