<template>
  <v-container fluid>
    <v-row class="room-item-skeleton" v-for="index in quantity" :key="index">
      <v-col cols="2" class="d-flex align-center">
        <v-skeleton-loader
          class="no-bg-skeleton"
          type="avatar"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="10" class="d-flex flex-column align-start">
        <v-skeleton-loader
          class="no-bg-skeleton"
          type="text"
          width="70%"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="no-bg-skeleton"
          type="text"
          width="50%"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="no-bg-skeleton"
          type="text"
          width="50%"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from 'vue'
export default {
  props: ['quantity'],
  components: {},
  setup() {
    const count = ref(['1', '2', '3', '4', '5'])

    return {
      count,
    }
  },
}
</script>

<style lang="scss">
.room-item-skeleton {
  .no-bg-skeleton {
    background: none;
  }
  .v-skeleton-loader__avatar {
    margin: 0 !important;
  }
  .v-skeleton-loader__text {
    margin: 0 0 12px 0 !important;
  }
}
</style>
