import '@mdi/font/css/materialdesignicons.css'
import mitt from 'mitt'
import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import App from './App.vue'
import './assets/global.scss'
import './registerServiceWorker'
import router from './router'
import store from './store'

const customDarkTheme = {
  dark: true,
  colors: {
    control: '#31364E',
    brand: '#ffb505',
    lightBrand: '#a97600',
    background: '#1e2234',
    primary: '#ffb505',
    surface: '#121520',
    secondary: '#0a5c73',
    error: '#ff5722',
    buy: '#576751',
    sell: '#675151',
    lightRed: '#f4433645',
    lightGreen: '#4caf5024',
    formElement: '#f2f2f2',
    highlightSurface: '#171b29',
  },
}

const customLightTheme = {
  dark: false,
  colors: {
    control: '#E1E5EC', // Lighter shade for controls
    brand: '#ffb505', // Brand color retained
    lightBrand: '#ffcc33', // Lighter variant of the brand color
    background: '#f3f3f3', // Clean, white background
    primary: '#DAA520', // A golden yellow, offering better contrast on light backgrounds
    surface: '#FFFFFF', // Very light grey for surfaces
    secondary: '#347B98', // Calming blue for contrast
    error: '#E57373', // Softer red for error messages
    buy: '#7C9982', // Gentler green for buy actions
    sell: '#997878', // Subdued red for sell actions
    lightRed: '#F8BDBD', // Light red for subtle highlights
    lightGreen: '#C8E6C9', // Light green for success messages or highlights
    formElement: '#000',
    highlightSurface: '#12121278',
  },
}

// const customDarkTheme = {
//   dark: true,
//   colors: {
// control: '#31364E',
// brand: '#ffb505',
// lightBrand: '#a97600',
// background: '#1e2234',
// primary: '#ffb505',
// surface: '#121520',
// secondary: '#0a5c73',
// error: '#ff5722',
// buy: '#576751',
// sell: '#675151',
// lightRed: '#f4433645',
// lightGreen: '#4caf5024',
//   },
// }

// const customLightTheme = {
//   dark: false,
//   colors: {
//     brand: '#ffb505',
//     lightBrand: '#a97600',
//     background: '#f1f2f7',
//     surface: '#fff',
//     primary: '#d9a023',
//     secondary: '#0077a3',
//     error: '#ff5722',
//     buy: '#576751',
//     sell: '#675151',
//     lightRed: '#f4433645',
//     lightGreen: '#4caf5024',
//   },
// }

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // This is already the default value - only for display purposes
  },
  theme: {
    defaultTheme: 'customDarkTheme',
    themes: {
      customDarkTheme,
      customLightTheme,
    },
  },
})

const emitter = mitt()

const app = createApp(App)

app
  .use(router)
  .use(store)
  .use(vuetify)
  .provide('emitter', emitter)
  .mount('#app')

app.config.errorHandler = (err, instance, info) => {
  //TODO: handle error, e.g. report to a service
  console.log('ERROR', err, instance, info)
}
