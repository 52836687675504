<template>
  <div class="flake">
    <div>
      <div class="flake-user d-flex align-center">
        <v-avatar class="mr-0">
          <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-avatar>
        <v-skeleton-loader
          class="mr-0 no-bg"
          type="text"
          width="200"
          color="none"
        ></v-skeleton-loader>
      </div>
    </div>
    <div class="action-buttons flake-action-buttons">
      <v-skeleton-loader
        class="mb-7 mr-6"
        type="button"
        :elevation="1"
        height="30"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mb-7 mr-6"
        type="button"
        :elevation="1"
        height="30"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mb-7 mr-6"
        type="button"
        :elevation="1"
        height="30"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mb-1 mr-6"
        type="button"
        :elevation="1"
        height="30"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
export default {
  props: ['video', 'isCurrentSlide'],
  components: {},
  setup(props) {
    const videoRef = ref(null)

    const handleCanPlay = () => {
      videoRef.value.play()
    }

    watch(
      () => props.isCurrentSlide,
      (newVal) => {
        if (newVal) {
          videoRef.value.play()
        } else {
          videoRef.value.pause()
        }
      }
    )

    onMounted(() => {
      // if (props.isCurrentSlide) {
      //   videoRef.value.play()
      // }
    })

    return {
      videoRef,
      handleCanPlay,
    }
  },
}
</script>

<style lang="scss" scoped>
.no-bg {
  background-color: transparent;
}
.flake {
  flex-grow: 1;
  width: auto;
  position: relative;
  height: 100%;
  @media (min-width: 768px) {
    // Tablet and above
    width: 500px;
    position: relative;
    margin: 0 auto;
  }
}
.flake-user {
  position: absolute;
  bottom: 1rem;
  left: 0.5rem;
}
.action-buttons {
  position: absolute;
  bottom: 1rem;
  right: 0;
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.action-buttons {
  position: absolute;
  bottom: 1rem;
  right: 0;
}
.flake-action-buttons {
  display: grid;
  grid-template-columns: 1fr;
  .always-white {
    color: white !important;
  }
  .v-btn {
    padding: 0;
  }
  .button-wrap {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    span {
      font-weight: bold;
      font-size: 1rem;
    }
  }
}
</style>
