<template>
  <div class="video-grid" v-if="!isLoading">
    <div
      v-for="(video, index) in videosWithThumbnails"
      :key="index"
      class="video-thumbnail"
      @click="navigateToSingleVideo(video.friendlyId)"
    >
      <img :src="video.thumbnailUrl" alt="Video Thumbnail" />
      <v-img
        :src="video.thumbnailUrl"
        cover
        class="rounded-lg video-image"
        width="113"
        height="200"
      ></v-img>
      <v-chip class="views" prepend-icon="mdi-play-outline" variant="text">
        {{ video.views }}
      </v-chip>
    </div>
  </div>
  <div class="video-grid" v-else>
    <v-skeleton-loader
      v-for="index in 6"
      :key="index"
      class="no-bg-skeleton"
      type="ossein"
      width="113"
      height="200"
      rounded
    ></v-skeleton-loader>
  </div>
  <div
    v-if="!isLoading && (!videosWithThumbnails || !videosWithThumbnails.length)"
  >
    <h6 class="text-center mt-6 text-h6">No Videos</h6>
  </div>
</template>

<script setup>
import axios from 'axios'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const videos = ref([])
const isLoading = ref(true)

const props = defineProps({
  profileUserId: {
    type: String,
    default: null,
  },
  pageOrigin: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: null,
  },
})

const api = async () => {
  isLoading.value = true
  try {
    let addendum = '?filterType=videos'
    if (props.profileUserId != null) {
      addendum += '&profileUserId=' + props.profileUserId
    }
    if (props.pageOrigin != null) {
      addendum += '&pageOrigin=' + props.pageOrigin
    }
    if (props.type != null) {
      addendum += '&type=' + props.type
    }

    let url = `${process.env.VUE_APP_API_URL}/api/posts/curated-feed` + addendum

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
        //add a bearer token if you have one from localstorage token
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })

    if (response.data.posts) {
      videos.value = response.data.posts
    }
  } catch (error) {
    console.log('Error uploading video:', error)
  } finally {
    isLoading.value = false
  }
}

onMounted(async () => {
  await api() // Load initial posts
})

const videosWithThumbnails = computed(() => {
  return videos.value.map((video) => ({
    ...video,
    thumbnailUrl: `${video.content}/ik-thumbnail.jpg?tr=w-113,h-200`,
  }))
})

const navigateToSingleVideo = (postFriendlyId) => {
  router.push({
    name: 'SingleVideo',
    params: { postFriendlyId: postFriendlyId },
  })
}
</script>

<style lang="scss" scoped>
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(113px, 1fr));
  grid-gap: 1rem;
  padding: 0px;
  .video-thumbnail {
    width: 113px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .views {
      z-index: 10;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
