<template>
  <div class="d-flex flex-row align-center px-4 my-4">
    <v-btn
      size="default"
      class="mr-4"
      color="primary"
      v-if="mdAndUp"
      @click="navigateToCreateChatroom"
      >Create New</v-btn
    >
    <v-text-field
      clearable
      label="Search rooms by name"
      variant="solo"
      v-model="searchQuery"
      @click:clear="searchQuery = ''"
      bg-color="control"
      hide-details
    ></v-text-field>
  </div>
  <v-divider></v-divider>
  <v-container v-if="!isLoading" fluid class="pl-0 pr-0">
    <template v-if="filteredRooms.length > 0">
      <div v-for="room in filteredRooms" :key="room.roomId" class="roomItem">
        <RoomItem
          @setRoomsCache="setRoomsCache"
          @refresh="fetchRooms"
          :room="room"
          :sock="socket"
        />
      </div>
    </template>
    <h6 v-else class="text-center mt-6 text-h6">No rooms</h6>
  </v-container>

  <ChatRoomSkeleton :quantity="8" v-else />

  <v-btn
    v-if="!mdAndUp"
    icon="mdi-plus"
    color="primary"
    class="create-new"
    @click="navigateToCreateChatroom"
  >
  </v-btn>
</template>

<script setup>
import RoomItem from '@/components/ChatRooms/RoomItem.vue'
import ChatRoomSkeleton from '@/components/Loaders/ChatRoomSkeleton.vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useStore } from 'vuex'
import { customGet } from '../utils/apiUtils'
import { getSocket } from '../utils/socket'

const store = useStore()
const router = useRouter()
const socket = getSocket()
const { mdAndUp } = useDisplay()

const rooms = ref(store.state.roomsCache || [])
const isLoading = ref(store.state.roomsCache ? false : true) // if cache doesn't exist, do initial loading skeleton
const searchQuery = ref('')

const setRoomsCache = () => {
  store.commit('setRoomsCache', rooms.value)
}
/**
 * Clear feed cache when going away from feed to any other page
 * other than the single post page
 */
onBeforeRouteLeave((to, from) => {
  // Clear the cache only if navigating away from feed but not to SinglePost
  if (from.name === 'Rooms' && to.name !== 'SingleRoom') {
    store.commit('setRoomsCache', [])
  }
})

const filteredRooms = computed(() => {
  if (!searchQuery.value) {
    return rooms.value
  }
  return rooms.value.filter((room) =>
    room.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  )
})

const fetchRooms = async () => {
  try {
    isLoading.value = true
    const apiUrl = `/api/chat/rooms`
    const response = await customGet(apiUrl)
    if (response.success) {
      const newRooms = response.rooms
      if (JSON.stringify(newRooms) !== JSON.stringify(rooms.value)) {
        rooms.value = newRooms
      }
    } else {
      console.log('Error fetching rooms:', response)
    }
  } catch (error) {
    console.log('Error fetching rooms:', error)
  } finally {
    isLoading.value = false
  }
}

const refetchRooms = async () => {
  try {
    const apiUrl = `/api/chat/rooms`
    const response = await customGet(apiUrl)
    if (response.success) {
      const newRooms = response.rooms
      if (JSON.stringify(newRooms) !== JSON.stringify(rooms.value)) {
        rooms.value = newRooms
      }
    } else {
      console.log('Error fetching rooms:', response)
    }
  } catch (error) {
    console.log('Error fetching rooms:', error)
  }
}

const navigateToCreateChatroom = () => {
  router.push({ name: 'CreateRoom' })
}

const intervalId = setInterval(() => {
  refetchRooms()
}, 10000)

onMounted(async () => {
  if (rooms.value.length === 0) {
    await fetchRooms()
  } else {
    await refetchRooms()
  }
})

onUnmounted(() => {
  clearInterval(intervalId)

  if (socket) {
    socket.disconnect()
  }
})
</script>

<style scoped>
/* Your CSS goes here */
.create-new {
  position: fixed;
  bottom: 6rem;
  right: 2rem;
  @media (min-width: 768px) {
    bottom: 6rem;
    right: 2rem;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-30px);
}

.roomItem {
  cursor: pointer;
}
</style>
