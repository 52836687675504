<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Privacy Policy</h1>
        <p>Last Updated: 10/25/2023</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Introduction</h2>
        <p>
          Welcome to LegendaryApp.io. We respect your privacy and are committed
          to protecting it. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you visit our website
          legendaryapp.io.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Information We Collect</h2>
        <p>
          We may collect information that you voluntarily provide to us, such as
          your name, email address, and other contact details when you register
          for an account, subscribe to a newsletter, or submit a contact form.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>Automatic Data Collection</h3>
        <p>
          When you visit our website, we may also collect certain information
          automatically, including: IP Address Browser Type Operating System
          Access Times Pages Viewed
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>How We Use Your Information</h3>
        <p>
          We may use the information we collect from you to: Provide and
          maintain our services Improve the user experience Send newsletters and
          promotional materials Monitor and analyze usage and trends
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>How We Share Your Information</h3>
        <p>
          We do not sell or rent your personal information to third parties. We
          may share your information with trusted partners who assist in
          operating our website and conducting our business, provided those
          parties agree to keep this information confidential.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>Cookies</h3>
        <p>
          We may use cookies to collect information about your preferences and
          to optimize our website. You can choose to disable cookies through
          your browser settings.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>Security</h3>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>Changes to This Policy</h3>
        <p>
          We reserve the right to update or change our Privacy Policy at any
          time. Your continued use of the website following any changes
          constitutes your acceptance of the updated policy.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at team@legendaryapp.io.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
