<template>
  <v-card
    :ripple="false"
    :class="[
      ' drop post-card  no-overlay',
      isHoldersOnly ? 'holders-only' : '',
      isDarkMode ? 'dark' : 'light',
    ]"
    variant="text"
    @click.middle="handleMouseDown"
    @click.stop="navigateToPost"
    v-if="!isHoldersOnly || post.canViewHolderPost || isMyPost"
  >
    <p class="text-left text-body-2 ml-12 pl-6 mb-n3 mt-3" v-if="post.isRepost">
      <span class="text-disabled"
        ><v-icon size="x-small" icon="mdi-repeat"></v-icon> reposted by
      </span>
      <span
        class="font-weight-bold clickable"
        @click.middle="openInNewTab('Profile', reposter)"
        @click.stop="navigateTo('Profile', reposter)"
        >{{ reposter.displayName }}</span
      >
    </p>
    <div class="d-flex">
      <v-avatar
        @click.stop="navigateTo('Profile', postUser)"
        @click.middle="navigateTo('Profile', postUser, $event)"
        :image="getSmallerImage(postUser.profileImageUrl)"
        color="surface"
        size="40"
        class="mt-4 ml-4 clickable"
      >
        <!-- <img :src="postUser.profileImageUrl" :alt="postUser.displayName" /> -->
      </v-avatar>

      <div class="w-100">
        <v-card-text class="pb-0">
          <div class="d-flex align-start w-100 mb-2">
            <div
              class="mb-0 text-left username"
              @click.stop="navigateTo('Profile', postUser)"
              @click.middle="openInNewTab('Profile', postUser)"
            >
              <v-menu :transition="false" open-on-hover>
                <template v-slot:activator="{ props }">
                  <div v-bind="props" class="text-truncate-container">
                    <span class="text-subtitle-2 font-weight-bold mr-1"
                      >{{ postUser.displayName }}
                    </span>
                    <span class="text-caption text-disabled"
                      >@{{ postUser.username }}
                    </span>
                  </div>
                </template>
                <UserPopup :user="postUser" />
              </v-menu>
            </div>
            <span class="ml-1 mt-1 text-caption text-disabled">
              {{ displayTime(post.createdAt) }}
            </span>
            <span class="ml-auto"></span>
            <PostMoreMenu
              @share="showShareForPost"
              @refresh="$emit('refresh')"
              :post="post"
              :showShare="!isHoldersOnly"
            />
          </div>
          <v-chip
            v-bind="props"
            v-if="isHoldersOnly"
            prepend-icon="mdi-badge-account-horizontal-outline"
            color="secondary"
            size="x-small"
            class="mt-n5 mr-2"
            variant="flat"
            label
          >
            Holder's Only
          </v-chip>
          <v-chip
            v-bind="props"
            v-if="isPinned"
            prepend-icon="mdi-pin-outline"
            color="secondary"
            size="x-small"
            class="mt-n5"
            variant="flat"
            label
          >
            Pinned
          </v-chip>
          <div class="text-left post-content">
            <ParsedContent
              v-for="(paragraph, index) in parsedContent"
              :key="index"
              :paragraph="paragraph"
              @openLink="processOpenLink"
            />
            <a
              v-if="showMoreLinkVisible"
              class="text-primary mb-2 d-block"
              @click.stop="navigateToPost"
            >
              Show More
            </a>

            <v-img
              v-if="post.postType === 'image'"
              :src="getOptimisedImage(post.content)"
              class="mb-3 w-100 rounded-lg post-image"
              max-width="500"
              @click.stop="showImagePopup(post)"
            ></v-img>
            <TenorGif :tenorGifUrl="tenorGifUrl" />
            <YoutubeVideo
              :youtubeVideoUrl="youtubeVideoUrl"
              v-if="youtubeVideoUrl"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            size="small"
            color="surface-variant"
            variant="text"
            class="no-overlay post-action-btn"
            @click.stop="$emit('showQuickReply', post)"
          >
            <template v-slot:prepend>
              <v-icon size="x-large" icon="mdi-comment-outline"></v-icon>
            </template>
            {{ post.commentCount ? post.commentCount : 0 }}
          </v-btn>

          <Repost :post="post" />

          <v-btn
            :ripple="false"
            :disabled="isLiking"
            size="small"
            color="surface-variant"
            variant="text"
            @click.stop="toggleHeart"
            class="no-overlay post-action-btn"
          >
            <template v-if="hasHearted" v-slot:prepend>
              <v-icon
                class="liked"
                size="large"
                icon="mdi-heart liked"
              ></v-icon>
            </template>
            <template v-else v-slot:prepend>
              <v-icon
                class="like"
                size="large"
                icon="mdi-heart-outline"
              ></v-icon>
            </template>
            {{ formatNumber(postHeartCount) }}</v-btn
          >

          <v-btn
            size="small"
            color="surface-variant"
            variant="text"
            class="no-overlay post-action-btn tip"
            @click.stop="showTipDialogForPost(post)"
          >
            <template v-slot:prepend>
              <v-icon
                size="x-large"
                :class="[
                  post.tipDollars && post.tipDollars > 0 ? 'tipped' : '',
                  'tip',
                ]"
                icon="mdi-currency-usd"
              ></v-icon>
            </template>
            {{ formatNumber(post.tipDollars ? post.tipDollars.toFixed(2) : 0) }}
          </v-btn>
          <v-btn
            :ripple="false"
            :disabled="isBookmarking"
            size="small"
            color="surface-variant"
            variant="text"
            @click.stop="toggleBookmark"
            class="no-overlay post-action-btn"
          >
            <template v-if="hasBookmarked" v-slot:prepend>
              <v-icon
                class="bookmarked"
                size="x-large"
                icon="mdi-bookmark bookmarked"
              ></v-icon>
            </template>
            <template v-else v-slot:prepend>
              <v-icon
                size="x-large"
                class="bookmark"
                icon="mdi-bookmark-outline"
              ></v-icon>
            </template>
            {{ formatNumber(postBookmarkCount) }}</v-btn
          >
        </v-card-actions>
      </div>
    </div>
    <!-- Slot for children comments -->
    <slot name="children-comments"></slot>
  </v-card>
  <HoldersOnly :post="post" v-else />
  <v-divider></v-divider>
  <Tipping
    :show="showTipDialog"
    :subject="subject"
    :postId="postId"
    @close="showTipDialog = false"
    @tip="handleTipAmount"
  />
  <Share
    :show="showShareDialog"
    :urlAddendum="urlAddendum"
    :postType="postType"
    @close="showShareDialog = false"
  />
</template>

<script setup>
import Share from '@/components/UserActions/Share.vue'
import { formatNumber } from '@/utils/helpers'
import {
  getTenorGifUrl,
  getYoutubeVideoUrl,
  processContent,
} from '@/utils/postUtils'
import axios from 'axios'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useTheme } from 'vuetify'
import { useStore } from 'vuex'
import HoldersOnly from './Post/HoldersOnly.vue'
import UserPopup from './Post/UserPopup.vue'
import ParsedContent from './PostText/ParsedContent.vue'
import PostMoreMenu from './PostText/PostMoreMenu.vue'
import TenorGif from './PostText/TenorGif.vue'
import Tipping from './UserActions/Tipping.vue'
import YoutubeVideo from './YoutubeVideo.vue'

dayjs.extend(relativeTime)

import Repost from './Repost.vue'

const emit = defineEmits([
  'navigateToPost',
  'refresh',
  'showQuickReply',
  'openLink',
])
const props = defineProps({
  post: Object,
})

const store = useStore()
const theme = useTheme()
const router = useRouter()
const hasHearted = ref(false)
const isLiking = ref(false)
const hasBookmarked = ref(false)
const isBookmarking = ref(false)
const showTipDialog = ref(false)
const isPinned = ref(props.post.isPinned || false)
const subject = ref(null)
const postId = ref(null)
const currentPost = ref(null)
const urlAddendum = ref(null)
const postType = ref(null)
const showShareDialog = ref(false)
const isContentExpanded = ref(false)
const contentLimit = 300 // Set the content limit

const isDarkMode = computed(() => {
  return theme.global.current.value.dark
})

const getSmallerImage = (imageUrl) => {
  if (imageUrl.includes('twimg.com')) return imageUrl
  return `${imageUrl}?tr=w-40,h-40`
}

const isMyPost = computed(() => {
  if (user.value) return props.post.user._id === user.value._id
  return false
})

const user = computed(() => store.state.user)

const navigateToPost = () => {
  if (!window.getSelection().toString()) {
    emit('navigateToPost', props.post)
  }
}

const isHoldersOnly = computed(() => {
  if (props.post.postPermissions) {
    return props.post.postPermissions === 'holders'
  }
  return false
})

const handleMouseDown = () => {
  if (!window.getSelection().toString()) {
    const routeData = router.resolve({
      name: 'SinglePost',
      params: { id: props.post.postId },
    })
    window.open(routeData.href, '_blank')
  }
}
const getOptimisedImage = (url, large) => {
  // Check if the URL already has query parameters
  const hasQueryParams = url.includes('?')
  const width = large ? 1920 : 500

  // Define the transformation parameters
  const transformationParams = `tr=w-${width},q-80,f-auto`

  // Append the transformation parameters to the URL
  if (hasQueryParams) {
    // If URL already has query parameters, append with '&'
    return `${url}&${transformationParams}`
  } else {
    // If URL doesn't have query parameters, append with '?'
    return `${url}?${transformationParams}`
  }
}

const openInNewTab = (profile, user) => {
  const routeData = router.resolve({
    name: profile,
    params: { username: user.username },
  })
  window.open(routeData.href, '_blank')
}

const processOpenLink = (linkItem) => {
  emit('openLink', linkItem)
}

// Setting default user basd on repost. The post user data always populates with postUser.
const postUser = ref(
  props.post.isRepost ? props.post.originalPoster : props.post.user
)
// if repost, set reposter user
const reposter = ref(props.post.user || null)

const postHeartCount = ref(props.post.heartCount || 0)
const postBookmarkCount = ref(props.post.bookmarkCount || 0)

hasHearted.value = props.post.isHearted
hasBookmarked.value = props.post.isBookmarked

const tenorGifUrl = computed(() => getTenorGifUrl(props.post))

/**
 * Parsing content and breaking it down into array of text and usernames
 * So that the usernames can be converted into router links directly during template generation
 * Hence avoiding use of innerHTML or v-html
 *
 * Also limiting the content to contentLimit initially to trim long posts
 */
const parsedContent = computed(() => {
  let contentToProcess

  if (props.post.postType === 'image') {
    // For image type posts, work with the caption
    let truncatedCaption = props.post.caption.substring(0, contentLimit)
    truncatedCaption += props.post.caption.length > contentLimit ? '...' : ''
    contentToProcess = { ...props.post, caption: truncatedCaption }
  } else {
    // For text type posts, work with the content
    let truncatedContent = props.post.content.substring(0, contentLimit)
    truncatedContent += props.post.content.length > contentLimit ? '...' : ''
    contentToProcess = { ...props.post, content: truncatedContent }
  }

  return isContentExpanded.value
    ? processContent(props.post)
    : processContent(contentToProcess)
})

const youtubeVideoUrl = computed(() => getYoutubeVideoUrl(props.post))

const showImagePopup = (postItem) => {
  store.dispatch('showImagePopup', {
    show: true,
    imageUrl: getOptimisedImage(postItem.content, true),
    caption: postItem.caption,
  })
}

const showMoreLinkVisible = computed(() => {
  return (
    !isContentExpanded.value &&
    ((props.post.postType === 'image' &&
      props.post.caption.length > contentLimit) ||
      (props.post.postType === 'text' &&
        props.post.content.length > contentLimit))
  )
})

const showTipDialogForPost = (post) => {
  console.log(post)
  currentPost.value = post
  subject.value = post.user.address
  postId.value = post.postId
  showTipDialog.value = true
}
const showShareForPost = (post) => {
  urlAddendum.value = '/feed/post/' + post.postId
  postType.value = 'post'
  showShareDialog.value = true
}

const handleTipAmount = (tipAmount) => {
  if (currentPost.value) {
    currentPost.value.tipDollars += tipAmount
  }
}

onMounted(() => {})

/**
 *
 * @param {*} createdAt Date
 * Method to convert date object into readable time
 */
const displayTime = (createdAt) => {
  const date = dayjs(createdAt)
  const now = dayjs()

  const diffInSeconds = now.diff(date, 'second')
  const diffInMinutes = now.diff(date, 'minute')
  const diffInHours = now.diff(date, 'hour')

  let displayTime

  if (diffInSeconds < 60) {
    // Less than a minute
    displayTime = `${diffInSeconds}s` // e.g., "3s"
  } else if (diffInMinutes < 60) {
    // Less than an hour but more than a minute
    displayTime = `${diffInMinutes}m` // e.g., "5m"
  } else if (diffInHours < 24) {
    // Less than 24 hours but more than an hour
    displayTime = `${diffInHours}h` // e.g., "3h"
  } else {
    // More than 24 hours
    displayTime = date.format('D MMM') // e.g., "15 Oct"
  }
  return displayTime
}

const navigateTo = (routeName, user, event = null) => {
  if (event && event.button === 1) {
    // Middle mouse button clicked - open in a new tab
    const routeData = router.resolve({
      name: routeName,
      params: { username: user.username },
    })
    window.open(routeData.href, '_blank')
  } else {
    router.push({ name: routeName, params: { username: user.username } })
  }
}

/**
 * Action Button Methods
 * Toggle Heart, Delete Post, Toggle Bookmark
 */
// TODO: Updated post array content so that it reflects in cache
//Leaving the above comment for whoever initially put it in, but I now update the cache when appropriate
const toggleHeart = async () => {
  isLiking.value = true
  // Optimistically update UI
  hasHearted.value = !hasHearted.value
  postHeartCount.value += hasHearted.value ? 1 : -1

  const friendlyId = props.post.isRepost
    ? props.post.originalPostFriendlyId
    : props.post.friendlyId

  let url = `${process.env.VUE_APP_API_URL}/api/posts/${
    hasHearted.value ? 'heart' : 'unheart'
  }/${friendlyId}`

  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    // Optionally update UI based on response
    postHeartCount.value = response.data.heartCount
    //store.commit('updateSinglePostCache', props.post)
  } catch (error) {
    // Revert optimistic UI updates on error
    hasHearted.value = !hasHearted.value
    postHeartCount.value += hasHearted.value ? 1 : -1

    store.dispatch('showSnackbar', {
      show: true,
      message: error.message,
      color: 'error',
      timeout: 2000,
    })
  }
  isLiking.value = false
}

// TODO: Updated post array content so that it reflects in cache
//Leaving the above comment for whoever initially put it in, but I now update the cache when appropriate
const toggleBookmark = async () => {
  isBookmarking.value = true
  // Optimistic UI Update
  hasBookmarked.value = !hasBookmarked.value
  postBookmarkCount.value += hasBookmarked.value ? 1 : -1

  const friendlyId = props.post.isRepost
    ? props.post.originalPostFriendlyId
    : props.post.friendlyId

  let url = `${process.env.VUE_APP_API_URL}/api/posts/bookmark/${friendlyId}`

  if (!hasBookmarked.value) {
    url = `${process.env.VUE_APP_API_URL}/api/posts/removeBookmark/${friendlyId}`
  }

  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    // Update with actual response data
    postBookmarkCount.value = response.data.bookmarkCount
    //store.commit('updateSinglePostCache', props.post)
  } catch (error) {
    // Revert optimistic UI update on error
    hasBookmarked.value = !hasBookmarked.value
    postBookmarkCount.value += hasBookmarked.value ? 1 : -1
    console.log(error)
    store.dispatch('showSnackbar', {
      show: true,
      message: error.message,
      color: 'error',
      timeout: 2000,
    })
  }
  isBookmarking.value = false
}
</script>
<style lang="scss">
.text-truncate-container {
  max-width: 100%; // Adjust as needed
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 65vw;
  @media (min-width: 768px) {
    max-width: auto;
  }
}
.no-overlay {
  .v-btn__overlay,
  .v-card__overlay {
    background: transparent !important;
  }
}
.tip {
  .v-btn__prepend {
    margin-right: 0;
  }
}
</style>
<style lang="scss" scoped>
.holders-only {
  &.dark {
    &:after {
      background-color: rgb(8 92 115 / 13%);
    }
  }
  &.light {
    &:after {
      background-color: rgb(52 123 152 / 14%);
    }
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .holder-icon {
    padding: 0.75rem;
    border-radius: 100%;
  }
}
.post-content {
  overflow-wrap: anywhere;
}
.post-action-btn {
  opacity: 1 !important;
}
.post-image {
  max-width: 500px;
  max-height: 500px;
}
.username {
  max-width: 70%;
  cursor: pointer;
}

.usernameLink {
  cursor: pointer;
}
.post-user-btns {
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
}
.trade-btn {
  text-transform: capitalize !important;
}
.drop {
  border-radius: 0;
}
.liking {
  animation: heartbeat 1s infinite;
}
.liked {
  animation: heartbeatFinish 0.5s 1;
}
.bookmarked {
  animation: bookmarked-animation 0.5s 1;
}
.bookmarking {
  animation: bookmarkBeat 1s infinite;
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: #ffb502;
}
@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
@keyframes heartbeatFinish {
  0% {
    transform: scale(0.25);
  }
  20% {
    transform: scale(0.75);
  }

  60% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes bookmarked-animation {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
@keyframes rotateAndSqueeze {
  from {
    transform: rotate(0deg) scaleY(1);
  }
  50% {
    transform: rotate(180deg) scaleY(0.5);
  }
  to {
    transform: rotate(360deg) scaleY(1);
  }
}
</style>
