<template>
  <div v-for="i in quantity" :key="i">
    <v-card class="drop tweet-card d-flex pt-1 pb-1" variant="text">
      <v-avatar class="mt-4 ml-3">
        <v-skeleton-loader type="avatar"></v-skeleton-loader>
      </v-avatar>

      <div class="full-width">
        <v-card-text>
          <div class="text-left">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>

            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-skeleton-loader
            class="mr-6 ml-3"
            type="button"
            height="30"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mr-6"
            type="button"
            height="30"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mr-6"
            type="button"
            height="30"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mr-6"
            type="button"
            height="30"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mr-6"
            type="button"
            height="30"
          ></v-skeleton-loader>
        </v-card-actions>
      </div>
    </v-card>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  props: ['quantity'],
  components: {},
  setup() {
    const count = ref(['1', '2', '3', '4', '5'])

    return {
      count,
    }
  },
}
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}
.v-icon.liked {
  color: red;
}
.drop {
  border-radius: 0;
}
</style>
