import io from 'socket.io-client'

export function getSocket() {
  const token = localStorage.getItem('token')
  if (token) {
    const socket = io(process.env.VUE_APP_WS_URL, {
      auth: { token },
      withCredentials: true,
    })

    socket.on('connect_error', (error) => {
      console.error('Connection Error', error)
    })

    socket.on('error', (error) => {
      console.error('Error', error)
    })

    return socket
  }
  return null
}
