<template>
  <div class="grid-container">
    <div class="trade-box" v-if="user.tokenId || user.isMe">
      <v-card
        :color="!user.tokenId && user && user.isMe ? 'secondary' : 'surface'"
        :class="{ 'gradient-animation': advancedTrading }"
      >
        <v-card-item>
          <v-card-subtitle
            class="text-left tradeTitle d-flex flex-row align-center justify-space-between"
          >
            <div class="d-flex align-center">
              <v-icon icon="mdi-swap-horizontal"></v-icon>
              {{
                !user.tokenId && user && user.isMe
                  ? 'Activate Trading'
                  : advancedTrading
                  ? 'Advance Trade'
                  : 'Trade'
              }}
            </div>
            <v-spacer></v-spacer>

            <div class="d-flex align-center">
              <v-switch
                density="compact"
                @click="toggleAdvancedTrading"
                class="advanced-switch"
                hide-details
              ></v-switch>
            </div>
          </v-card-subtitle>
          <div
            class="d-flex flex-column align-items-center"
            v-if="!user.tokenId && user && user.isMe"
          >
            <p class="text-body-1 text-center mb-3">
              Get your first ticket for free to activate trading of your
              tickets. Claim your ticket below.
            </p>
            <div class="button-container d-flex justify-center mb-3">
              <v-btn
                :disabled="isBuying"
                :loading="isBuying"
                class="flake-follow-btn"
                size="large"
                @click="processTransaction('free')"
                variant="elevated"
                color="primary"
              >
                Claim Free Ticket
              </v-btn>
            </div>
          </div>
          <div v-else>
            <div class="justify-center">
              <v-row>
                <v-col cols="6">
                  <div class="d-flex flex-column w-100 justify-center">
                    <p class="text-subtitle-2 text-disabled text-center">
                      Total Buy Price
                    </p>

                    <h5 class="d-flex align-center text-h5 mb-4 justify-center">
                      <i :class="['avax-icon', 'large mr-1']"></i>
                      {{ buyPricingMultiple }}
                    </h5>
                    <v-btn
                      v-if="user.tokenId"
                      :disabled="isBuying"
                      :class="[
                        'flake-follow-btn',
                        //   size === 'x-large' ? 'flex-grow-1 mb-4' : '',
                      ]"
                      size="large"
                      @click="processTransaction('buy')"
                      variant="elevated"
                      color="green"
                    >
                      Buy
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex flex-column w-100 justify-center">
                    <p class="text-subtitle-2 text-disabled text-center">
                      Total Sell Price
                    </p>

                    <h5
                      :class="[
                        'd-flex align-center text-h5 mb-4 justify-center',
                        sellTicketNumber > balance ? 'text-disabled' : '',
                      ]"
                    >
                      <i :class="['avax-icon', 'large mr-1']"></i>
                      {{ sellPricing }}
                    </h5>
                    <v-btn
                      :disabled="isBuying || sellTicketNumber > balance"
                      :class="[
                        'flake-follow-btn',
                        // size === 'x-large' ? 'flex-grow-1 mb-4' : '',
                      ]"
                      size="large"
                      @click="processTransaction('sell')"
                      variant="elevated"
                      color="red"
                    >
                      Sell
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row class="align-center justify-center mt-3 mb-3">
                <v-col cols="8">
                  <v-row class="align-center justify-center">
                    <v-col cols="3" class="justify-center text-center">
                      <v-btn
                        :disabled="isBuying || ticketNumber === 1"
                        :class="['flake-follow-btn']"
                        size="x-small"
                        @click="ticketAction('-')"
                        icon="mdi-minus-thick"
                        variant="outlined"
                      />
                    </v-col>
                    <v-col class="flex-grow-1 flex-shrink-1 text-center">
                      <span class="text-h5 mr-1">{{ ticketNumber }}</span>
                      <span class="text-disabled"
                        >ticket{{ ticketNumber > 1 ? 's' : '' }}</span
                      >
                    </v-col>
                    <v-col cols="3" class="justify-center text-center">
                      <v-btn
                        :disabled="isBuying"
                        :class="['flake-follow-btn']"
                        size="x-small"
                        @click="ticketAction('+')"
                        elevated="3"
                        icon="mdi-plus-thick"
                        variant="outlined"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-item>
        <v-divider></v-divider>
        <!-- ADVANCED TRADING -->
        <v-expand-transition>
          <div v-show="advancedTrading">
            <v-divider></v-divider>
            <v-card-item>
              <div class="d-flex flex-row align-center">
                <span
                  class="text-caption text-disabled font-weight-thin"
                  v-if="tradeHappening"
                >
                  <v-progress-circular
                    v-if="tradeHappening"
                    indeterminate
                    color="primary"
                    size="14"
                    class="ml-1"
                  ></v-progress-circular>
                  Trading in progress. Slippage Recommended
                </span>
                <span v-else class="text-overline"> Buy Slippage </span>
                <v-icon
                  icon="mdi-information-outline"
                  class="text-disabled ml-1"
                  size="x-small"
                  @click="
                    showMetaPopup(
                      `Set your buy slippage to allocate additional AVAX beyond the ticket's current price. This feature is particularly useful in scenarios where the ticket is highly sought-after and its price is rapidly fluctuating. By doing so, you enhance the likelihood of successfully purchasing the ticket during volatile price movements. Rest assured, any excess AVAX not used in the transaction will be promptly returned to your wallet.`
                    )
                  "
                />
              </div>
              <span
                class="text-h5 text-center d-block d-flex flex-row align-center justify-center multiply"
                >{{ multiple.toFixed(1) }}
                <v-icon
                  class="multiply-icon mt-1"
                  icon="mdi-close"
                  size="x-small"
              /></span>

              <div class="slider px-5 mb-2">
                <v-slider
                  v-model="multiple"
                  :min="1"
                  :max="10"
                  hide-details
                  @update:modelValue="priceAction"
                  @touchstart.stop
                  @touchmove.stop
                  @touchend.stop
                ></v-slider>
              </div>
            </v-card-item>
          </div>
        </v-expand-transition>
      </v-card>
      <v-divider></v-divider>
      <v-sheet
        color="surface"
        elevation="2"
        rounded
        class="mb-3 pa-2 d-flex flex-row justify-space-between align-center"
      >
        <p class="d-flex flex-row justify-center text-disabled align-center">
          <v-icon
            size="large"
            class="mr-1"
            icon="mdi-hand-extended-outline"
          ></v-icon>
          <span class="text-high-emphasis mr-1 font-weight-bold"
            >{{ balance }} tickets</span
          >
          owned
        </p>
        <v-spacer></v-spacer>
        <Balance size="small" />
      </v-sheet>
    </div>
    <v-card class="mb-3" v-else>
      <v-card-text class="text-center text-body-2"
        >Ticket trading for {{ user.displayName }} is not active
        yet</v-card-text
      ></v-card
    >
  </div>
  <TradeConfirmation
    :show="showTradeConfirmationDialog"
    :isBuy="isBuy"
    :amount="amount"
    :price="price"
    :subject="subject"
    :username="user.username"
    @close="resetDialog"
    @reload="processRefresh"
  />
  <MetaPopup
    :show="showMetaDialog"
    :text="metaData"
    @update:show="showMetaDialog = $event"
  />
</template>
<script setup>
import Balance from '@/components/Balance.vue'
import MetaPopup from '@/components/Tickets/MetaPopup.vue'
import TradeConfirmation from '@/components/UserActions/TradeConfirmation.vue'
import {
  customPost,
  getBalanceOf,
  getBuyPrice,
  getSellPrice,
  pendingShareHolderFees,
  userProfile,
} from '@/utils/apiUtils'
import { formatNumber } from '@/utils/helpers'
import { onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const props = defineProps({
  userdata: Object,
})

const showMetaDialog = ref(false)
const metaData = ref('')
const showMetaPopup = (data) => {
  showMetaDialog.value = true
  metaData.value = data
}

const emit = defineEmits(['refetch', 'updatePrice'])

console.log(props.userdata)
const store = useStore()
const router = useRouter()
const route = useRoute()
const isBuying = ref(false)
const user = ref(props.userdata)
const buyPricing = ref(0)
const buyPricingMultiple = ref(0)
const sellPricing = ref(0)
const buyTicketNumber = ref(1)
const sellTicketNumber = ref(1)
const ticketNumber = ref(1)
const showTradeConfirmationDialog = ref(false)
const multiple = ref(1)
const advancedTrading = ref(false)
const tradeHappening = ref(false)

const amount = ref(1)
const isBuy = ref(true)
const subject = ref(props.userdata.address)
const price = ref(null)

const pendingFees = ref(0)
const myFees = ref(0)
const bounty = ref(0)
const balance = ref(0)

const resetDialog = () => {
  isBuying.value = false
  showTradeConfirmationDialog.value = false
}

const toggleAdvancedTrading = () => {
  advancedTrading.value = !advancedTrading.value
  multiple.value = 1
}

const processRefresh = () => {
  updatePricing(props.userdata.address, 1, 1)
  updateFees()
  getTokenBalance()
  fetchUserData()
  multiple.value = 1
}

const intervalId = setInterval(async () => {
  if (!props.userdata) {
    return
  }

  if (!props.userdata.address) {
    return
  }

  if (!props.userdata.tokenId) {
    return
  }

  await updatePricing(
    props.userdata.address,
    buyTicketNumber.value,
    sellTicketNumber.value
  )

  await getTokenBalance()
  await fetchUserData()
  await updateFees()
}, 8000)

const intervalTrades = setInterval(async () => {
  if (!props.userdata) {
    return
  }

  if (!props.userdata.address) {
    return
  }

  await checkTrades()
}, 3000)

const processTransaction = async (actionType) => {
  isBuying.value = true

  if (actionType == 'buy') {
    price.value = parseFloat(buyPricingMultiple.value)
    amount.value = buyTicketNumber.value
    isBuy.value = true
  } else if (actionType == 'free') {
    price.value = parseFloat(0)
    amount.value = 1
    isBuy.value = true
  } else {
    price.value = parseFloat(sellPricing.value)
    amount.value = sellTicketNumber.value
    isBuy.value = false
  }
  showTradeConfirmationDialog.value = true
}

onMounted(async () => {
  await updatePricing(props.userdata.address, 1, 1)
  await fetchUserData()
  await getTokenBalance()
  await updateFees()
  await router.isReady()
})

onUnmounted(() => {
  clearInterval(intervalId)
  clearInterval(intervalTrades)
})

const updateTicketNumbers = (
  actionType,
  ticketNumber,
  buyTicketNumber,
  sellTicketNumber,
  supply
) => {
  if (actionType === '+') {
    ticketNumber.value++
    buyTicketNumber.value++
    sellTicketNumber.value = Math.min(ticketNumber.value, supply)
  } else if (ticketNumber.value > 1) {
    ticketNumber.value--
    buyTicketNumber.value = ticketNumber.value
    sellTicketNumber.value = Math.max(sellTicketNumber.value - 1, 1)
  }
}

const updatePricing = async (address, buyNumber, sellNumber) => {
  const [fetchBuyPrice, fetchSellPrice] = await Promise.all([
    getBuyPrice(address, buyNumber, 'rpc'),
    getSellPrice(address, sellNumber, 'rpc'),
  ])

  if (fetchBuyPrice.success) {
    buyPricing.value = fetchBuyPrice.price
    buyPricingMultiple.value =
      advancedTrading.value && multiple.value > 1
        ? formatNumber(fetchBuyPrice.price * multiple.value)
        : fetchBuyPrice.price
  }

  if (fetchSellPrice.success) {
    sellPricing.value = fetchSellPrice.price
  }
}

const updateFees = async () => {
  try {
    if (props?.userdata?.address) {
      const getPendingFees = await pendingShareHolderFees(
        props.userdata.address
      )

      if (getPendingFees.success) {
        pendingFees.value = formatNumber(getPendingFees.pendingFees)
        bounty.value = getPendingFees.pendingFees * 0.01
        console.log('bounty', bounty.value)
        if (
          pendingFees.value > 0 &&
          props.userdata?.supply > 0 &&
          balance.value > 0
        ) {
          myFees.value = formatNumber(
            (pendingFees.value / props.userdata.supply) * balance.value
          )
        } else {
          myFees.value = 0
        }
      } else {
        pendingFees.value = 0
        myFees.value = 0
      }
    } else {
      pendingFees.value = 0
      myFees.value = 0
    }
  } catch (error) {
    console.log(error)
  }
}

const getTokenBalance = async () => {
  try {
    if (
      props.userdata &&
      props.userdata.tokenId &&
      store.state?.user?.address
    ) {
      const usingMetaMask = store.state.user?.useWeb3Wallet
      const address = usingMetaMask
        ? store.state.user?.web3address
        : store.state.user?.address

      let tokenBalance = await getBalanceOf(address, props.userdata.tokenId)

      if (tokenBalance.success) {
        balance.value = parseFloat(tokenBalance.balance)
      }
    } else {
      balance.value = 0
    }
  } catch (error) {
    console.log(error)
  }
}

const checkTrades = async () => {
  const url = '/api/transactions/slippage'
  const response = await customPost(url, {
    subject: props.userdata.address,
  })

  if (response && response.success) {
    if (response.slippage) {
      tradeHappening.value = true
      advancedTrading.value = true
    } else if (!response.slippage && tradeHappening.value) {
      tradeHappening.value = false
      processRefresh()
    }
  }
}

const fetchUserData = async () => {
  try {
    const usernameToFetch = route.params.username
      ? route.params.username
      : store.state.user.username

    const userProfileResponse = await userProfile(usernameToFetch)

    user.value = userProfileResponse.user
    emit('updatePrice', userProfileResponse.user.sharePrice)
  } catch (error) {
    console.log(error)
  }
}

const ticketAction = async (actionType) => {
  const supply = props.userdata?.supply ? props.userdata.supply - 1 : 1

  updateTicketNumbers(
    actionType,
    ticketNumber,
    buyTicketNumber,
    sellTicketNumber,
    supply
  )
  await updatePricing(
    props.userdata.address,
    buyTicketNumber.value,
    sellTicketNumber.value
  )
}

const priceAction = () => {
  buyPricingMultiple.value = formatNumber(
    parseFloat(buyPricing.value) * multiple.value
  )
}
</script>
<style scoped>
.multiply {
  .multiply-icon {
    font-size: 1.2rem;
    opacity: 0.7;
  }
}
.advanced-switch {
  position: absolute;
  top: 0;
  right: 16px;
}
.meta .v-col {
  cursor: pointer;
}
.tradeTitle {
  margin-bottom: 10px;
}

.ticketAction {
  background-color: #212437;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.ticketAction:hover {
  background-color: #212437;
}

/** animation for when activating advanced trading */
.gradient-animation {
  position: relative;
  overflow: hidden;
  @media (min-width: 768px) {
    &:after {
      height: 600%;
      width: 600%;
      top: -100%;
      left: -100%;
      animation: shineEffectLarge 0.8s cubic-bezier(0.76, 0, 0.24, 1) forwards !important;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    transform: rotate(30deg);
    z-index: -1;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 181, 5, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%
    );

    animation: shineEffect 0.8s cubic-bezier(0.76, 0, 0.24, 1) forwards;
  }
}

@keyframes shineEffect {
  0% {
    top: -110%;
    left: -210%;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: -30%;
    left: -30%;
    opacity: 0;
  }
}
@keyframes shineEffectLarge {
  0% {
    top: -200%;
    left: -210%;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: -30%;
    left: -30%;
    opacity: 0;
  }
}
</style>
