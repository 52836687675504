<template>
  <v-dialog @click:outside="closeDialog" v-model="dialog" max-width="350">
    <v-card>
      <v-card-title class="d-flex flex-row align-start">
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="d-flex flex-row align-start">
        <v-icon
          icon="mdi-information-outline"
          size="x-large"
          class="mr-2"
        ></v-icon>
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-btn block @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  show: Boolean,
  text: String,
})

const emit = defineEmits(['update:show'])

const dialog = ref(props.show)

watch(
  () => props.show,
  (newVal) => {
    dialog.value = newVal
  }
)

const closeDialog = () => {
  dialog.value = false
  emit('update:show', false)
}
</script>

<style scoped>
/* Add any custom styles here */
</style>
