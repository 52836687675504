<template>
  <p :class="['mb-3', mdAndUp ? 'text-body-1' : 'text-body-2]']">
    <template v-for="(part, partIndex) in paragraph" :key="partIndex">
      <router-link
        class="text-primary link"
        v-if="part.isUsername"
        @click.stop
        :to="{ name: 'Profile', params: { username: part.text } }"
      >
        @{{ part.text }}
      </router-link>
      <a
        v-else-if="part.isUrl"
        @click.stop="$emit('openLink', part.text)"
        target="_blank"
        rel="noopener"
        class="text-primary"
      >
        {{ part.text }}
      </a>
      <template v-else>{{ part.text }}</template>
    </template>
  </p>
</template>

<script setup>
defineEmits(['openLink'])
defineProps({ paragraph: Object })
import { useDisplay } from 'vuetify'
const { mdAndUp } = useDisplay()
</script>
