<!-- src/components/Nav.vue -->
<template>
  <div class="flake-action-buttons">
    <v-btn
      density="compact"
      variant="text"
      stacked
      :ripple="false"
      class="mb-5 no-overlay"
      color="white"
      @click="toggleHeart"
    >
      <template v-if="hasHearted" v-slot:prepend>
        <v-icon class="liked" size="large" icon="mdi-heart liked"></v-icon>
      </template>
      <template v-else v-slot:prepend>
        <v-icon
          :class="isLiking ? 'liking' : ''"
          size="large"
          icon="mdi-heart-outline"
        ></v-icon>
      </template>
      {{ postHeartCount }}</v-btn
    >

    <v-btn
      density="compact"
      variant="text"
      stacked
      prepend-icon="mdi-comment-outline"
      :ripple="false"
      class="mb-5 mr-0"
      color="white"
      @click="$emit('showComments')"
    >
      {{ video.commentCount }}
    </v-btn>
    <v-btn
      density="compact"
      variant="text"
      stacked
      prepend-icon="mdi-currency-usd"
      :ripple="false"
      class="mb-5 mr-0"
      color="white"
      @click="showTipping"
    >
      {{ video.tipDollars ? formatDollar(video.tipDollars) : 0 }}
    </v-btn>
    <v-btn
      density="compact"
      variant="text"
      stacked
      :ripple="false"
      class="mb-5 no-overlay"
      color="white"
      @click="toggleBookmark"
    >
      <template v-if="hasBookmarked" v-slot:prepend>
        <v-icon
          class="bookmarked"
          size="large"
          icon="mdi-bookmark bookmarked"
        ></v-icon>
      </template>
      <template v-else v-slot:prepend>
        <v-icon
          :class="isBookmarking ? 'bookmarking' : ''"
          size="large"
          icon="mdi-bookmark-outline"
        ></v-icon>
      </template>
      {{ postBookmarkCount }}</v-btn
    >
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn
          density="compact"
          variant="text"
          stacked
          icon="mdi-dots-vertical"
          v-bind="props"
        ></v-btn>
      </template>
      <v-list>
        <v-list-item
          slim
          prepend-icon="mdi-share-variant"
          @click="showShare"
          class="clickable"
          >Share
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import axios from 'axios'
import { inject, ref } from 'vue'
import { useStore } from 'vuex'
import { formatDollar } from '../../utils/helpers'

export default {
  props: ['video'],
  emits: ['showTipDialog', 'showComments', 'showShareDialog'],
  components: {},
  setup(props) {
    const store = useStore()
    const emitter = inject('emitter')
    const hasHearted = ref(false)
    const postHeartCount = ref(props.video?.heartCount || 0)
    const postBookmarkCount = ref(props.video?.bookmarkCount || 0)
    const isLiking = ref(false)
    const hasBookmarked = ref(false)
    const isBookmarking = ref(false)

    hasHearted.value = props.video?.isHearted
    hasBookmarked.value = props.video?.isBookmarked

    const showTipping = () => {
      emitter.emit('showTipDialogHome', props.video)
    }
    const showShare = () => {
      emitter.emit('showShareDialogHome', props.video)
    }

    const toggleHeart = async () => {
      isLiking.value = true
      let url = `${process.env.VUE_APP_API_URL}/api/posts/heart/${props.video.friendlyId}`

      if (hasHearted.value) {
        url = `${process.env.VUE_APP_API_URL}/api/posts/unheart/${props.video.friendlyId}`
      }

      try {
        const response = await axios.post(
          url,
          {},
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              //add a bearer token if you have one from localstorage token
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )

        hasHearted.value = !hasHearted.value
        console.log(response.data)
        postHeartCount.value = response.data.heartCount
      } catch (error) {
        console.log(error)
        store.dispatch('showSnackbar', {
          show: true,
          message: error.message,
          color: 'error',
          timeout: 2000, // Optional. Default is 3000
        })
      }
      isLiking.value = false
    }
    const toggleBookmark = async () => {
      isBookmarking.value = true
      let url = `${process.env.VUE_APP_API_URL}/api/posts/bookmark/${props.video.friendlyId}`

      if (hasBookmarked.value) {
        url = `${process.env.VUE_APP_API_URL}/api/posts/removeBookmark/${props.video.friendlyId}`
      }

      try {
        const response = await axios.post(
          url,
          {},
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              //add a bearer token if you have one from localstorage token
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )

        hasBookmarked.value = !hasBookmarked.value
        console.log(response.data)
        postBookmarkCount.value = response.data.bookmarkCount
      } catch (error) {
        console.log(error)
        store.dispatch('showSnackbar', {
          show: true,
          message: error.message,
          color: 'error',
          timeout: 2000, // Optional. Default is 3000
        })
      }
      isBookmarking.value = false
    }

    return {
      hasHearted,
      postHeartCount,
      isLiking,
      toggleHeart,
      showTipping,
      showShare,
      formatDollar,
      hasBookmarked,
      isBookmarking,
      postBookmarkCount,
      toggleBookmark,
    }
  },
}
</script>

<style lang="scss" scoped>
.flake-action-buttons {
  display: grid;
  grid-template-columns: 1fr;
  .always-white {
    color: white !important;
  }
  .v-btn {
    padding: 0;
  }
  .button-wrap {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    span {
      font-weight: bold;
      font-size: 1rem;
    }
  }
}
.liking {
  animation: heartbeat 1s infinite;
}
.liked {
  /* animation: heartbeatFinish 0.5s 1; */
}
.bookmarking {
  animation: bookmarkBeat 1s infinite;
}
.bookmarked {
  /* animation: bookmarkBeatFinish 0.5s 1; */
  /* color: gold; */
}
@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
@keyframes heartbeatFinish {
  0% {
    transform: scale(0.25);
  }
  20% {
    transform: scale(0.75);
  }

  60% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes bookmarkBeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
@keyframes bookmarkBeatFinish {
  0% {
    transform: scale(0.25);
  }
  20% {
    transform: scale(0.75);
  }

  60% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
</style>
