<template>
  <div
    :class="[
      'position-relative',
      position === 'header' ? `${position} mr-2` : 'px-3',
    ]"
  >
    <v-menu
      v-model="showAutocomplete"
      :close-on-content-click="false"
      activator="parent"
    >
      <template v-slot:activator>
        <v-text-field
          :class="[
            'search-box',
            position === 'page' ? '' : 'my-2 header-search',
          ]"
          flat
          variant="solo"
          clearable
          label="Search by username"
          v-model="searchQuery"
          @click:clear="onClear"
          :bg-color="position === 'header' ? 'background' : 'surface'"
          hide-details
          :density="position === 'page' ? 'default' : 'compact'"
          :center-affix="position === 'header'"
          :single-line="position === 'header'"
        ></v-text-field>
      </template>
      <v-sheet
        :class="['auto-suggest', position === 'header' ? '' : 'mx-3']"
        v-if="usernames.length > 0"
      >
        <v-list-item
          v-for="user in usernames"
          :key="user.id"
          class="custom-autocomplete-item"
          @click="goToProfile(user.id)"
          :prepend-avatar="user.profileImage"
          :title="`@${user.username}`"
          :subtitle="user.displayName"
        />
      </v-sheet>
    </v-menu>
  </div>
</template>

<script setup>
import axios from 'axios'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

defineProps({
  position: {
    type: String,
    default: 'page',
  },
})

const router = useRouter()
const usernames = ref([])
const searchQuery = ref('')
const showAutocomplete = ref(false)

watch(searchQuery, async (newVal) => {
  if (newVal.length > 0) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/api/users/searchusers?q=${newVal}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      usernames.value = response.data.users.map((user) => ({
        id: user.username,
        username: user.username,
        displayName: user.displayName,
        profileImage: user.profileImageUrl,
        title: `@${user.username} - ${user.displayName}`,
      }))
      showAutocomplete.value = true
    } catch (error) {
      console.error('API Error:', error)
      showAutocomplete.value = false
    }
  } else {
    showAutocomplete.value = false
  }
})

const onClear = () => {
  searchQuery.value = ''
}

const goToProfile = (id) => {
  router.push(`/profile/${id}`)
}
</script>

<style lang="scss" scoped>
.header-search {
  width: 300px;
  min-width: 200px;
}
/* .header {
  .custom-autocomplete {
    top: 40px;
  }
} */
.auto-suggest {
  top: 40px;
  max-height: 300px;
}
</style>
