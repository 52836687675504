<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Terms of Service</h1>
        <p>Last Updated: 10/25/2023</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>1. Introduction</h2>
        <p>
          By accessing or using our website, you agree to abide by these Terms
          of Service and any additional terms and conditions that may apply.
          These Terms of Service govern your use of our website, any content or
          services available on or through the website, including any subdomains
          thereof. We reserve the right to change these terms at any time
          without prior notice, and your continued use of the website
          constitutes agreement to all such changes.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>2. Use of the Service</h2>
        <p>
          You are responsible for your use of the Service and for any content
          you post or information you share, including compliance with
          applicable laws, rules, and regulations. You should only provide
          content that you are comfortable sharing with others. You must not
          post content that is illegal, hateful, obscene, or promotes violence.
          We reserve the right to remove any content that violates these terms
          or is harmful to us, our users, or third parties, or for any other
          reason at our sole discretion.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>3. Account Registration</h2>
        <p>
          To use certain features of our Service, you may be required to create
          an account. You are responsible for safeguarding your account and for
          any activities or actions occurring under your account. You agree to
          provide accurate, current, and complete information during the
          registration process and to update such information to keep it
          accurate, current, and complete. We reserve the right to suspend or
          terminate your account and access to the Service for any reason,
          including but not limited to, a violation of these Terms of Service.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>4. Limitation of Liability</h2>
        <p>
          To the maximum extent permitted by applicable law, we will not be
          liable for any direct, indirect, incidental, special, consequential,
          or punitive damages, including, but not limited to, loss of profits,
          data, or use, whether in an action in contract, tort, or otherwise,
          arising out of or in any way connected with the use of or inability to
          use our Service. We make no representations or warranties about the
          reliability, availability, or accuracy of the content, services, or
          information provided through the Service.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>5. Contact Information</h2>
        <p>
          If you have any questions about these Terms of Service, please contact
          us at team@legendaryapp.io.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
