<template>
  <div class="video-item">
    <div @click="$emit('toggleSound')" class="video-cover"></div>
    <div class="video-container">
      <v-icon
        v-if="isMuted"
        size="x-small"
        variant="plain"
        class="muted-icon text-disabled"
        >mdi-volume-off</v-icon
      >
      <video
        ref="videoRef"
        :src="decodedVideoSrc"
        @canplay="handleCanPlay"
        playsinline
        :muted="isMuted"
        loop
        class="video-item"
        preload="auto"
      ></video>
    </div>

    <div :class="['flake-left ml-1 text-left', expanded ? 'expanded' : '']">
      <div
        class="flake-user d-flex align-center mb-1 cursor-pointer"
        @click="navigateToProfile(video.user.username)"
      >
        <v-avatar
          v-if="video.user.profileImageUrl"
          :image="video.user.profileImageUrl"
          class="mr-3"
        ></v-avatar>
        <v-avatar size="32" v-else color="surface-variant"></v-avatar>

        <div class="w-75 mr-3">
          <p class="font-weight-boldalways-white text-truncate">
            {{ video.user.displayName }}
          </p>
        </div>
      </div>
      <template v-if="caption">
        <p
          @click="expandCaption(caption)"
          class="caption-text text-left"
          v-if="!expanded"
        >
          {{ formatToOneLine(caption) }}
        </p>
        <p
          class="w-75 text-left caption-text"
          @click="expanded = !expanded"
          v-else
        >
          {{ caption }}
        </p>
      </template>
      <VideoUserButtons class="mt-2" :user="video.user" />
    </div>

    <VideoButtons
      class="action-buttons"
      @showComments="handleShowComments"
      :video="video"
    />
    <!-- Bottom Sheet for Comments -->
    <v-bottom-sheet
      class="bottom-comments"
      max-height="500"
      v-model="showCommentsSheet"
      scrollable
    >
      <v-sheet class="comment-sheet fill-height">
        <div class="comments-container">
          <VideoComments :post="video" />
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- ... -->
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import VideoButtons from './VideoButtons.vue'
import VideoComments from './VideoComments.vue'
import VideoUserButtons from './VideoUserButtons.vue'

const props = defineProps({
  video: Object,
  isCurrentSlide: Boolean,
  isSingleVideo: Boolean,
  isMuted: Boolean,
})
defineEmits(['toggleSound'])
const videoRef = ref(null)
const expanded = ref(false)
const showCommentsSheet = ref(false)
const caption = ref(null)
const router = useRouter()

const decodedVideoSrc = computed(() => {
  return decodeURIComponent(props.video.content)
})

const navigateToProfile = (user) => {
  router.push({ name: 'Profile', params: { username: user } })
}

const handleShowComments = () => {
  showCommentsSheet.value = true
}

const handleCanPlay = () => {
  if (videoRef.value && props.isCurrentSlide) {
    videoRef.value.play()
  }
}

const expandCaption = (text) => {
  if (text.length > 35) {
    expanded.value = !expanded.value
  }
}

const formatToOneLine = (text) => {
  const maxLength = 35

  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...'
  }

  return text
}

watch(
  () => props.isCurrentSlide,
  (newVal) => {
    // Ensure videoRef.value exists and is a video element
    if (videoRef.value && videoRef.value instanceof HTMLVideoElement) {
      if (newVal) {
        videoRef.value.play().catch((error) => {
          console.error('Error playing video:', error)
        })
      } else {
        videoRef.value.pause()
      }
    }
  }
)

const playVideo = () => {
  if (videoRef.value && videoRef.value instanceof HTMLVideoElement) {
    videoRef.value.play().catch((error) => {
      console.error('Error trying to play the video:', error)
    })
  }
}

const handleLoadedMetadata = () => {
  setTimeout(() => {
    videoRef.value
      .play()
      .catch((error) => console.error('Error trying to play the video:', error))
  }, 200)
}
onMounted(() => {
  caption.value = props.video.caption
    .replace(/&quot;/g, '"')
    .replace(/&#x27;/g, "'")
    .replace(/&amp;/g, '&')
    .replace(/&#x2F;/g, '/')
    .replace(/&#x2F;/g, '/')
    .replace(/&#x27;/g, "'")
  if (props.isSingleVideo) {
    videoRef.value.addEventListener('loadedmetadata', handleLoadedMetadata)
  }

  // Play video if it's the current slide or if it's a single video
  if (props.isCurrentSlide || props.isSingleVideo) {
    playVideo()
    // videoRef.value = videojs(videoRef.value, {
    //   sources: [{ src: decodedVideoSrc.value, type: 'video/mp4' }],
    //   autoplay: true,
    // })
  }
})

// Remove event listener on unmounted
onUnmounted(() => {
  if (videoRef.value) {
    videoRef.value.removeEventListener('loadedmetadata', handleLoadedMetadata)
  }
})
</script>

<style lang="scss" scoped>
.bottom-comments {
  bottom: 56px;
}
.always-white {
  color: #fff !important;
}
.video-item {
  width: 100%;
  position: relative;
  height: calc(100vh - 64px - 72px); // removing header and bottom nav
  @media (min-width: 768px) {
    // Tablet and above
    width: 551px;
    position: relative;
    margin: 0 auto;
    height: calc(100vh - 64px); // removing header and bottom nav
  }
  &:after {
    display: block;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5648853291316527) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
    height: 30%;
    position: absolute;
    bottom: 0;
    content: '';
  }
  .video-cover {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.comments-container {
  padding-bottom: 70px;
}
.flake-left {
  position: absolute;
  bottom: 1rem;
  left: 0.5rem;
  z-index: 2;
}
.action-buttons {
  position: absolute;
  bottom: 1rem;
  right: 0;
  z-index: 3;
}
.caption-text {
  color: white !important;
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}
.muted-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 24px;
  z-index: 10;
}
</style>
