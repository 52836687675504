<template>
  <div v-if="pendingTransactions.length > 0">
    <v-card
      v-for="transaction in pendingTransactions"
      :color="'yellow'"
      :variant="'tonal'"
      :key="transaction"
      :class="'mb-3'"
    >
      <v-card-text>
        <div class="d-flex flex-row justify-wrap-around">
          <div class="desc text-body-2 mr-3">
            <p class="text-left text-body-2">
              You have a pending transaction.
              <a :href="createLink(transaction)" target="_blank">View status</a>
            </p>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
  <p v-else class="text-body-1 mt-3 text-center font-weight-bold text-disabled">
    No Pending Transactions
  </p>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const pendingTransactions = ref(store.state.pendingTransactions || [])

console.log('pendingTransactions', pendingTransactions)

watch(
  () => store.state.pendingTransactions,
  async (newVal) => {
    pendingTransactions.value = newVal
  },
  { deep: true }
)

const createLink = (transaction) => {
  const baseUrl = process.env.VUE_APP_EXPLORER_TX
  return `${baseUrl}${transaction}`
}
</script>

<style>
.no-overlay {
  .v-btn__overlay,
  .v-card__overlay {
    background: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
.new {
  animation: newTransaction 1s ease-in-out;
}
@keyframes newTransaction {
  0%,
  100% {
    background-color: transparent; /* Use 'transparent' instead of 'none' for background-color */
  }
  50% {
    background-color: rgba(255, 200, 0, 0.354);
  }
}
</style>
