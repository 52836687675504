import axios from 'axios'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { getTransaction } from '../utils/apiUtils'

export default createStore({
  plugins: [
    createPersistedState({
      paths: ['user'],
    }),
  ],
  state: {
    user: null,
    appBarTitle: null,
    snackbar: {
      show: false,
      message: '',
      color: 'success',
      location: 'bottom',
    },
    imagePopup: {
      show: false,
      imageUrl: null,
      caption: null,
    },
    feedCache: null,
    lastSeenTimestampCache: null,
    firstSeenTimestampCache: null,
    roomsCache: null,
    postCache: null,
    notifications: [],
    pendingTransactions: [],
    completedTransactions: [],
    videoMuted: false,
    feedScrollPosition: {},
  },
  mutations: {
    setFeedScrollPosition(state, payload) {
      state.feedScrollPosition[payload.attributeName] = payload.position
    },
    setVideoMuted(state, vol) {
      state.videoMuted = vol
    },
    setFeedCache(state, feed) {
      state.feedCache = feed
    },
    setLastSeenTimestampCache(state, value) {
      state.lastSeenTimestampCache = value
    },
    setFirstSeenTimestampCache(state, value) {
      state.firstSeenTimestampCache = value
    },
    setPinRequired(state, value) {
      state.user.pinRequired = value
    },
    setWallet(state, payload) {
      state.user.web3address = payload.wallet
      state.user.web3verified = payload.verified
    },
    setUseWeb3Wallet(state, value) {
      state.user.useWeb3Wallet = value
    },
    setRoomsCache(state, rooms) {
      state.roomsCache = rooms
    },
    setUser(state, user) {
      state.user = user
    },
    setPostCache(state, post) {
      state.postCache = post
    },
    setPendingTransaction(state, transaction) {
      state.pendingTransactions.push(transaction)
    },
    setCompletedTransaction(state, transaction) {
      state.completedTransactions.push(transaction)
    },
    removePendingTransaction(state, transaction) {
      state.pendingTransactions = state.pendingTransactions.filter(
        (tx) => tx !== transaction
      )
      state.completedTransactions.push(transaction)
    },
    updateSinglePostCache(state, postToUpdate) {
      let postIndex = null
      let updatedPost = postToUpdate.value

      if (state.feedCache) {
        postIndex = state.feedCache.findIndex(
          (post) => post.postId === updatedPost.postId
        )
        if (postIndex !== -1) {
          state.feedCache[postIndex] = updatedPost
        }
      }
    },
    removeBlockedUsersFromCache(state, userToBlockId) {
      //The user can be blocked from multiple areas, we only need to filter the cache if it has been set, other scenarious have been handled
      if (state.feedCache) {
        state.feedCache = state.feedCache.filter(
          (post) => post.user._id !== userToBlockId
        )
      }
    },
    updateAppBarTitle(state, title) {
      state.appBarTitle = title
    },
    setSnackbar(state, payload) {
      state.snackbar.show = payload.show
      state.snackbar.message = payload.message
      state.snackbar.color = payload.color
    },
    setImagePop(state, payload) {
      state.imagePopup.show = payload.show
      state.imagePopup.imageUrl = payload.imageUrl
      state.imagePopup.caption = payload.caption
    },
    setNotifications(state, notifications) {
      state.notifications = notifications
    },
  },
  actions: {
    clearFeedCache({ commit }) {
      commit('setFeedCache', [])
      commit('setLastSeenTimestampCache', null)
    },
    async fetchUserData({ commit }, token) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/api/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const data = await response.json()
        commit('setUser', data.user)
      } catch (error) {
        console.log('Error fetching user data:', error)
      }
    },
    async fetchNotifications({ commit }, token) {
      if (!token) return
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/notifications`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        commit('setNotifications', response.data.notifications)
      } catch (error) {
        console.log('Error fetching notifications:', error)
      }
    },
    async checkPendingTransactions({ commit }) {
      const pendingTransactions = this.state.pendingTransactions
      if (pendingTransactions.length === 0) return
      for (let i = 0; i < pendingTransactions.length; i++) {
        const tx = pendingTransactions[i]
        const receipt = await getTransaction(tx)
        if (receipt.success) {
          this.dispatch('showSnackbar', {
            show: true,
            message: 'Transaction confirmed! May take a few seconds to update.',
            color: 'success',
            timeout: 3000,
          })
          commit('removePendingTransaction', tx)
        } else if (
          !receipt.success &&
          receipt.message == 'Transaction failed'
        ) {
          this.dispatch('showSnackbar', {
            show: true,
            message: 'Transaction failed.',
            color: 'error',
            timeout: 3000,
          })
          commit('removePendingTransaction', tx)
        }
      }
    },
    showSnackbar({ commit }, payload) {
      commit('setSnackbar', payload)
      setTimeout(() => {
        commit('setSnackbar', { show: false, message: '', color: 'success' })
      }, payload.timeout || 5000) // Default timeout is 3 seconds
    },
    showImagePopup({ commit }, payload) {
      commit('setImagePop', payload)
    },
  },
})
