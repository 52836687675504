<template>
  <v-sheet
    class="pb-4 gif-selected"
    v-if="gifSelected"
    color="background"
    elevation="10"
  >
    <v-btn
      @click="gifSelected = null"
      icon="mdi-close"
      variant="text"
      class="close-btn"
    ></v-btn>
    <TenorGif
      :tenorGifUrl="gifSelected.media_formats.gif.url"
      class="ml-auto mr-auto"
    />
  </v-sheet>
  <v-sheet
    v-if="showAutocomplete"
    class="custom-autocomplete pt-2 pb-2 pl-1 pr-1"
    color="background"
    elevation="10"
  >
    <v-list-item
      v-for="user in usernames"
      :key="user.id"
      class="custom-autocomplete-item"
      @click="replaceUsername(user.id)"
      :prepend-avatar="user.profileImage"
      :title="`@${user.username}`"
      :subtitle="user.displayName"
    >
    </v-list-item>
  </v-sheet>

  <v-card flat tile class="fixed-comment-input fixed-bottom">
    <v-card-actions class="d-flex align-center">
      <template v-if="user">
        <v-avatar class="mr-3" :image="user.profileImageUrl"></v-avatar>
      </template>

      <v-textarea
        v-model="textContent"
        :label="textContent === '' ? 'Post a comment' : ''"
        outlined
        variant="solo-filled"
        full-width
        :disabled="isUploading"
        hide-details
        :rows="1"
        @focus="isExpanded = true"
        @keydown.enter="onEnterPress"
      >
        <template v-slot:append-inner>
          <GifSelector @select-gif="handleGifSelect"></GifSelector>
        </template>
      </v-textarea>
      <v-btn
        color="primary"
        :disabled="isUploading"
        :loading="isUploading"
        @click="postContent"
        ><v-icon icon="mdi-send"></v-icon
      ></v-btn>
    </v-card-actions>
  </v-card>
  <v-card class="chat-box" v-if="post && post.userBanned">
    <v-card-actions class="d-flex align-center">
      <template v-if="user">
        <v-avatar class="mr-3" :image="user.profileImageUrl"></v-avatar>
      </template>
      <div>You are unable to comment on this users post</div>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import axios from 'axios'
import { computed, nextTick, ref, watch } from 'vue'
import { useStore } from 'vuex'
import GifSelector from '../PostText/GifSelector.vue'
import TenorGif from '../PostText/TenorGif.vue'
const props = defineProps({ post: Object })
const emit = defineEmits(['refetch-replies'])
const store = useStore()
const imageFile = ref(null)
const textContent = ref('')
const isUploading = ref(false)
const isExpanded = ref(false)
const gifSelected = ref(null)

const showAutocomplete = ref(false)
const usernames = ref([])

const user = computed(() => {
  return store.state.user
})

const onEnterPress = (event) => {
  event.preventDefault()
  if (usernames.value.length === 1) {
    replaceUsername(usernames.value[0].id)
  }
}
let inTaggingMode = false // Flag to indicate if you're in "user tagging mode"
let atPosition = -1 // Position of the '@' symbol

watch(textContent, async (newVal) => {
  try {
    isExpanded.value = newVal !== ''

    const cursorPosition = document.querySelector('textarea').selectionStart
    const charBeforeCursor = newVal.charAt(cursorPosition - 1)

    if (charBeforeCursor === '@') {
      inTaggingMode = true // We're now in tagging mode
      atPosition = cursorPosition - 1 // Store the position of '@'
    }

    // Check if there are any spaces between '@' and the current cursor position
    const stringBetweenAtAndCursor = newVal.substring(
      atPosition + 1,
      cursorPosition
    )
    const hasSpace = stringBetweenAtAndCursor.includes(' ')

    if (inTaggingMode && !hasSpace) {
      const nextChar = newVal.charAt(cursorPosition)
      if (nextChar !== ' ') {
        const query = newVal.substring(atPosition + 1, cursorPosition) // Extract the substring after '@'
        if (query.length > 0) {
          // Make sure query is not empty
          showAutocomplete.value = true

          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/api/users/searchusers?q=${query}`,
            {
              headers: {
                'Content-Type': 'application/json',
                //add a bearer token if you have one from localstorage token
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          )
          if (response?.data?.success) {
            usernames.value = response.data.users.map((user) => ({
              id: user.username,
              username: user.username,
              displayName: user.displayName,
              profileImage: user.profileImageUrl,
              title: `@${user.username} - ${user.displayName}`,
            }))
          } else {
            console.log('error', response.data)
            usernames.value = []
          }
          return
        }
      }
    }

    // Reset tagging mode if a space is encountered or if cursor goes back before '@'
    if (hasSpace || cursorPosition <= atPosition) {
      inTaggingMode = false
      atPosition = -1
    }

    showAutocomplete.value = false
  } catch (error) {
    console.log('error', error)
  }
})

const replaceUsername = (newVal) => {
  try {
    if (!newVal) return // Add this line to handle undefined or null values

    const userObj = usernames.value.find((user) => user.id === newVal)
    if (!userObj) return

    const newUsername = userObj.username

    const textareaElem = document.querySelector('textarea')
    const cursorPosition = textareaElem.selectionStart
    const atPosition = textContent.value.lastIndexOf('@', cursorPosition - 1)
    const beforeAt = textContent.value.substring(0, atPosition)
    const afterAt = textContent.value.substring(cursorPosition)

    textContent.value = `${beforeAt}@${newUsername} ${afterAt}`
    showAutocomplete.value = false
    textareaElem.selectionStart = textareaElem.selectionEnd =
      atPosition + newUsername.length + 2

    nextTick(() => {
      textareaElem.focus()
      showAutocomplete.value = false
    })
  } catch (error) {
    console.log('error', error)
  }
}

const handleGifSelect = (gif) => {
  gifSelected.value = gif
}

const postContent = async () => {
  isUploading.value = true
  const formData = new FormData()
  let contentType = 'application/json'

  let isImage = false

  if (imageFile.value) {
    formData.append('image', imageFile.value)
    isImage = true
    contentType = 'multipart/form-data'
  }
  let combinedContent = textContent.value

  if (gifSelected.value) {
    combinedContent += `\n${gifSelected.value.media_formats.gif.url}`
  }

  formData.append('content', combinedContent)

  let url = isImage
    ? `${process.env.VUE_APP_API_URL}/api/image/upload`
    : `${process.env.VUE_APP_API_URL}/api/text/upload`

  // Append the friendlyId to the URL if it's provided
  if (props.post?.friendlyId) {
    url += `/${props.post.friendlyId}`
  }

  url += '?videoReply=reply'
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': contentType,
        //add a bearer token if you have one from localstorage token
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    console.log(response)
    imageFile.value = null
    // fileInput.value.value = null
    //resetting text field
    textContent.value = ''
    isExpanded.value = false
    gifSelected.value = null

    store.dispatch('showSnackbar', {
      show: true,
      message: 'Posted Successfully!',
      color: 'success',
      // timeout: 2000, // Optional. Default is 3000
    })
    // Clearing feed cache in case person goes back to feed
    store.dispatch('clearFeedCache')

    emit('refetch-replies', props.post)
  } catch (error) {
    console.log('Error uploading video:', error)
    store.dispatch('showSnackbar', {
      show: true,
      message: error.message,
      color: 'error',
      // timeout: 2000, // Optional. Default is 3000
    })
  }
  isUploading.value = false
}
</script>
<style lang="scss" scoped>
.fixed-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.custom-autocomplete {
  bottom: 72px;
  position: fixed;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}
.gif-selected {
  bottom: 72px;
  position: fixed;
  overflow-y: auto;
  width: 100%;
}
</style>
