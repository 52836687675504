<template>
  <v-card
    :ripple="false"
    class="drop post-card pt-1 pb-1 no-overlay holders-only mb-4"
    variant="text"
  >
    <p class="text-left text-body-2 ml-12 pl-6 mb-n3 mt-3" v-if="post.isRepost">
      <span class="text-disabled"
        ><v-icon size="x-small" icon="mdi-repeat"></v-icon> reposted by
      </span>
      <span
        class="font-weight-bold clickable"
        @click.middle="openInNewTab('Profile', reposter)"
        @click.stop="navigateTo('Profile', reposter)"
        >{{ reposter.displayName }}</span
      >
    </p>
    <div class="d-flex">
      <v-avatar
        @click.stop="navigateTo('Profile', postUser)"
        @click.middle="navigateTo('Profile', postUser, $event)"
        class="mt-4 ml-4 clickable"
        :image="getSmallerImage(postUser.profileImageUrl)"
        color="surface"
      >
      </v-avatar>

      <div class="w-100">
        <v-card-text class="pb-0">
          <div class="d-flex align-center w-100 mb-2">
            <div
              class="mb-0 text-left username"
              @click.stop="navigateTo('Profile', postUser)"
              @click.middle="openInNewTab('Profile', postUser)"
            >
              <v-menu :transition="false" open-on-hover>
                <template v-slot:activator="{ props }">
                  <div v-bind="props" class="text-truncate-container">
                    <span class="text-subtitle-2 font-weight-bold mr-1"
                      >{{ postUser.displayName }}
                    </span>
                    <span class="text-caption text-disabled"
                      >@{{ postUser.username }}
                    </span>
                  </div>
                </template>
                <UserPopup :user="postUser" />
              </v-menu>
            </div>
            <span class="ml-1 mt-1 text-caption text-disabled">
              {{ displayTime(post.createdAt) }}
            </span>
            <span class="ml-auto"></span>
            <PostMoreMenu
              @share="showShareForPost"
              @refresh="$emit('refresh')"
              :post="post"
            />
          </div>
          <div class="text-left post-content">
            <v-sheet
              rounded
              class="d-flex pa-6 flex-column justify-start align-left"
            >
              <div class="mb-4 d-flex flex-row align-center">
                <div>
                  <h6 class="text-h6 text-disabled text-uppercase">
                    Holder's Only
                  </h6>
                  <span class="text-caption text-disabled">
                    Own
                    <span class="text-high-emphasis text-truncate"
                      >{{ postUser.displayName }}'s</span
                    >
                    ticket to view this
                  </span>
                </div>
                <v-spacer></v-spacer>
                <v-icon
                  icon="mdi-lock-outline"
                  size="x-large"
                  class="holders-only mr-1"
                ></v-icon>
              </div>
              <v-divider></v-divider>
              <div class="buy-details mt-4 d-flex flex-row align-center w-100">
                <div class="text-caption d-flex flex-row">
                  <span class="text-disabled mr-1">Ticket Price</span>
                  {{ formatNumber(post.user.buyPriceAfterFee) }}
                  <i :class="['avax-icon mr-1 fade', 'small']"></i>
                </div>
                <v-spacer></v-spacer>
                <div>
                  <v-btn
                    class="capitalize flake-follow-btn ml-2 trade-btn post-user-btns"
                    size="x-small"
                    variant="outlined"
                    @click="showTradePopup = true"
                  >
                    Trade
                  </v-btn>
                  <TradeTicket
                    v-if="showTradePopup"
                    :user="post.user"
                    @close="showTradePopup = false"
                    @refetchUsers="processPurchase"
                  />
                </div>
              </div>
            </v-sheet>
          </div>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>
<script setup>
import { formatNumber } from '@/utils/helpers'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import PostMoreMenu from '../PostText/PostMoreMenu.vue'
import TradeTicket from '../Tickets/TradeTicket.vue'
import UserPopup from './UserPopup.vue'

dayjs.extend(relativeTime)

// const emit = defineEmits([
//   //   'navigateToPost',
//   'refresh',
//   'showQuickReply',
//   'openLink',
// ])
const props = defineProps({
  post: Object,
})

console.log(props.post)

const getSmallerImage = (imageUrl) => {
  if (imageUrl.includes('twimg.com')) return imageUrl
  return `${imageUrl}?tr=w-40,h-40`
}

const processPurchase = () => {
  console.log('successfully purchased')
  // proceed to single post
}
// const store = useStore()
const router = useRouter()
const hasHearted = ref(false)
// const isLiking = ref(false)
const hasBookmarked = ref(false)
// const isBookmarking = ref(false)
// const showTipDialog = ref(false)
// const subject = ref(null)
// const postId = ref(null)
// const currentPost = ref(null)
const urlAddendum = ref(null)
const postType = ref(null)
const showShareDialog = ref(false)
const showTradePopup = ref(false)

// const navigateToPost = () => {
//   if (!window.getSelection().toString()) {
//     emit('navigateToPost', props.post)
//   }
// }

// const handleMouseDown = () => {
//   if (!window.getSelection().toString()) {
//     const routeData = router.resolve({
//       name: 'SinglePost',
//       params: { id: props.post.postId },
//     })
//     window.open(routeData.href, '_blank')
//   }
// }
const openInNewTab = (profile, user) => {
  const routeData = router.resolve({
    name: profile,
    params: { username: user.username },
  })
  window.open(routeData.href, '_blank')
}

// Setting default user basd on repost. The post user data always populates with postUser.
const postUser = ref(
  props.post.isRepost ? props.post.originalPoster : props.post.user
)
// if repost, set reposter user
const reposter = ref(props.post.user || null)

// const postHeartCount = ref(props.post.heartCount || 0)
// const postBookmarkCount = ref(props.post.bookmarkCount || 0)

hasHearted.value = props.post.isHearted
hasBookmarked.value = props.post.isBookmarked

// const showTipDialogForPost = (post) => {
//   console.log(post)
//   currentPost.value = post
//   subject.value = post.user.address
//   postId.value = post.postId
//   showTipDialog.value = true
// }
const showShareForPost = (post) => {
  urlAddendum.value = '/feed/post/' + post.postId
  postType.value = 'post'
  showShareDialog.value = true
}

// const handleTipAmount = (tipAmount) => {
//   if (currentPost.value) {
//     currentPost.value.tipDollars += tipAmount
//   }
// }

onMounted(() => {})

/**
 *
 * @param {*} createdAt Date
 * Method to convert date object into readable time
 */
const displayTime = (createdAt) => {
  const date = dayjs(createdAt)
  const now = dayjs()

  const diffInSeconds = now.diff(date, 'second')
  const diffInMinutes = now.diff(date, 'minute')
  const diffInHours = now.diff(date, 'hour')

  let displayTime

  if (diffInSeconds < 60) {
    // Less than a minute
    displayTime = `${diffInSeconds}s` // e.g., "3s"
  } else if (diffInMinutes < 60) {
    // Less than an hour but more than a minute
    displayTime = `${diffInMinutes}m` // e.g., "5m"
  } else if (diffInHours < 24) {
    // Less than 24 hours but more than an hour
    displayTime = `${diffInHours}h` // e.g., "3h"
  } else {
    // More than 24 hours
    displayTime = date.format('D MMM') // e.g., "15 Oct"
  }
  return displayTime
}

const navigateTo = (routeName, user, event = null) => {
  if (event && event.button === 1) {
    // Middle mouse button clicked - open in a new tab
    const routeData = router.resolve({
      name: routeName,
      params: { username: user.username },
    })
    window.open(routeData.href, '_blank')
  } else {
    router.push({ name: routeName, params: { username: user.username } })
  }
}

/**
 * Action Button Methods
 * Toggle Heart, Delete Post, Toggle Bookmark
 */
// TODO: Updated post array content so that it reflects in cache
//Leaving the above comment for whoever initially put it in, but I now update the cache when appropriate
// const toggleHeart = async () => {
//   isLiking.value = true
//   // Optimistically update UI
//   hasHearted.value = !hasHearted.value
//   postHeartCount.value += hasHearted.value ? 1 : -1

//   const friendlyId = props.post.isRepost
//     ? props.post.originalPostFriendlyId
//     : props.post.friendlyId

//   let url = `${process.env.VUE_APP_API_URL}/api/posts/${
//     hasHearted.value ? 'heart' : 'unheart'
//   }/${friendlyId}`

//   try {
//     const response = await axios.post(
//       url,
//       {},
//       {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//         },
//       }
//     )
//     // Optionally update UI based on response
//     postHeartCount.value = response.data.heartCount
//     //store.commit('updateSinglePostCache', props.post)
//   } catch (error) {
//     // Revert optimistic UI updates on error
//     hasHearted.value = !hasHearted.value
//     postHeartCount.value += hasHearted.value ? 1 : -1

//     store.dispatch('showSnackbar', {
//       show: true,
//       message: error.message,
//       color: 'error',
//       timeout: 2000,
//     })
//   }
//   isLiking.value = false
// }

// TODO: Updated post array content so that it reflects in cache
//Leaving the above comment for whoever initially put it in, but I now update the cache when appropriate
// const toggleBookmark = async () => {
//   isBookmarking.value = true
//   // Optimistic UI Update
//   hasBookmarked.value = !hasBookmarked.value
//   postBookmarkCount.value += hasBookmarked.value ? 1 : -1

//   const friendlyId = props.post.isRepost
//     ? props.post.originalPostFriendlyId
//     : props.post.friendlyId

//   let url = `${process.env.VUE_APP_API_URL}/api/posts/bookmark/${friendlyId}`

//   if (!hasBookmarked.value) {
//     url = `${process.env.VUE_APP_API_URL}/api/posts/removeBookmark/${friendlyId}`
//   }

//   try {
//     const response = await axios.post(
//       url,
//       {},
//       {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//         },
//       }
//     )
//     // Update with actual response data
//     postBookmarkCount.value = response.data.bookmarkCount
//     //store.commit('updateSinglePostCache', props.post)
//   } catch (error) {
//     // Revert optimistic UI update on error
//     hasBookmarked.value = !hasBookmarked.value
//     postBookmarkCount.value += hasBookmarked.value ? 1 : -1
//     console.log(error)
//     store.dispatch('showSnackbar', {
//       show: true,
//       message: error.message,
//       color: 'error',
//       timeout: 2000,
//     })
//   }
//   isBookmarking.value = false
// }
</script>
