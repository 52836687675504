<template>
  <v-tabs
    v-model="tab"
    align-tabs="center"
    bg-color="surface"
    grow
    color="primary"
  >
    <v-tab value="pending">Pending</v-tab>
    <v-tab value="tickets">Tickets</v-tab>
    <v-tab value="tipping">Tips</v-tab>
    <v-tab value="claims">Claims</v-tab>
    <v-tab value="transfers">Transfers</v-tab>
  </v-tabs>

  <v-container class="px-0">
    <v-window v-model="tab">
      <v-window-item value="pending"> <Pending /> </v-window-item>
      <v-window-item value="tickets" class="px-4">
        <template v-if="tab === 'tickets'"> <Tickets /> </template>
      </v-window-item>
      <v-window-item value="tipping" class="px-4">
        <template v-if="tab === 'tipping'"> <Tips /> </template>
      </v-window-item>
      <v-window-item value="claims" class="px-4">
        <template v-if="tab === 'claims'"> <Claims /> </template>
      </v-window-item>
      <v-window-item value="transfers" class="px-4">
        <template v-if="tab === 'transfers'"> <Transfers /> </template>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import Claims from '../components/UserActivity/Claims.vue'
import Pending from '../components/UserActivity/Pending.vue'
import Tickets from '../components/UserActivity/Tickets.vue'
import Tips from '../components/UserActivity/Tips.vue'
import Transfers from '../components/UserActivity/Transfers.vue'
const tab = ref('pending')
</script>
