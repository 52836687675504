<template>
  <v-btn
    :ripple="false"
    size="small"
    color="surface-variant"
    variant="text"
    class="no-overlay"
    :id="`menu-activator-${post.postId}`"
    @click.stop="handleClick()"
  >
    <template v-if="hasReposted" v-slot:prepend>
      <v-icon class="reposted" size="x-large" icon="mdi-repeat liked"></v-icon>
    </template>
    <template v-else v-slot:prepend>
      <v-icon
        :class="['repost', isReposting ? 'reposting' : '']"
        size="x-large"
        icon="mdi-repeat"
      ></v-icon>
    </template>
    <!-- <v-icon
        :class="isReposting ? 'reposting' : ''"
        size="x-large"
        icon="mdi-repeat"
      ></v-icon> -->
    {{ formatNumber(repostCount) }}
  </v-btn>
  <!-- <v-menu :activator="`#menu-activator-${post.postId}`">
    <v-list>
      <v-list-item :value="post.postId">
        <v-list-item-title
          @click="doQuoteRepost = true"
          class="pt-2 pb-2 pl-3 pr-3"
          >Repost</v-list-item-title
        >
        <v-list-item-title class="pt-2 pb-2 pl-3 pr-3">Quote</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <RepostPopup
    :post="post"
    @close="doQuoteRepost = false"
    v-if="doQuoteRepost"
  /> -->
</template>
<script setup>
import { formatNumber } from '@/utils/helpers'
import axios from 'axios'
import { inject, ref } from 'vue'
import { useStore } from 'vuex'
import { customPost } from '../utils/apiUtils'
const props = defineProps({
  post: Object,
})

const emitter = inject('emitter')

const hasReposted = ref(props.post.hasReposted || false)
const repostCount = ref(props.post.repostCount ? props.post.repostCount : 0)
const store = useStore()
const isReposting = ref(false)

const handleClick = async () => {
  if (hasReposted.value) {
    deleteRepost()
  } else {
    repostContent()
  }
}

const deleteRepost = async () => {
  try {
    if (hasReposted.value) {
      const url = `/api/text/deleteRepost/${props.post.friendlyId}`
      const response = await customPost(url, {})

      if (response.success) {
        console.log('removed id', response.removedId, response.originalId)
        hasReposted.value = false
        repostCount.value--

        // Optionally notify the user of success
        store.dispatch('showSnackbar', {
          show: true,
          message: 'Repost Deleted Successfully!',
          color: 'success',
        })

        emitter.emit('filterId', {
          removedId: response.removedId,
          originalId: response.originalId,
        })
      } else {
        store.dispatch('showSnackbar', {
          show: true,
          message: 'An error occurred while deleting the repost.',
          color: 'error',
        })
      }
    } else {
      store.dispatch('showSnackbar', {
        show: true,
        message: 'You have not reposted this post.',
        color: 'error',
      })
    }

    // Emit an event or do something else to update the UI
    // ...
  } catch (error) {
    console.log('Error deleting repost:', error)

    // Notify the user of the error
    store.dispatch('showSnackbar', {
      show: true,
      message: error.message,
      color: 'error',
    })
  }
}

const repostContent = async () => {
  isReposting.value = true

  try {
    const postData = {
      repostFriendlyId: props.post.isRepost
        ? props.post.originalPostFriendlyId
        : props.post.friendlyId,
      content: '', // Assuming reposts don't have additional content, adjust as needed
    }

    let url =
      props.post.postType === 'image'
        ? `${process.env.VUE_APP_API_URL}/api/image/upload`
        : `${process.env.VUE_APP_API_URL}/api/text/upload`

    const response = await axios.post(url, postData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })

    // Log the response or do something with it
    console.log(response)
    hasReposted.value = !hasReposted.value
    repostCount.value++

    // Optionally notify the user of success
    store.dispatch('showSnackbar', {
      show: true,
      message: 'Reposted Successfully!',
      color: 'success',
    })

    // Emit an event or do something else to update the UI
    // ...
  } catch (error) {
    console.log('Error reposting:', error)

    // Notify the user of the error
    store.dispatch('showSnackbar', {
      show: true,
      message: error.message,
      color: 'error',
    })
  }

  isReposting.value = false
}

// const doQuoteRepost = ref(false)
</script>

<style lang="scss" scoped>
.reposting {
  animation: repost-animation 0.5s infinite;
}

@keyframes repost-animation {
  from {
    transform: rotate(0deg) scaleY(1);
  }
  50% {
    transform: rotate(180deg) scaleY(0.8);
  }
  to {
    transform: rotate(360deg) scaleY(1);
  }
}
</style>
