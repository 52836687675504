<template>
  <v-container class="pt-8">
    <SearchBox />
  </v-container>
  <v-tabs
    v-model="tab"
    align-tabs="center"
    bg-color="surface"
    grow
    color="primary"
  >
    <v-tab value="leaderboard">Leaderboard</v-tab>
    <v-tab value="trending">Trending</v-tab>
    <v-tab value="new">New</v-tab>
  </v-tabs>

  <v-window v-model="tab">
    <v-window-item value="leaderboard">
      <Leaderboard type="leaderboard" v-if="tab === 'leaderboard'" />
    </v-window-item>
    <v-window-item value="trending">
      <Leaderboard type="trending" v-if="tab === 'trending'" />
    </v-window-item>
    <v-window-item value="new">
      <Leaderboard type="new" v-if="tab === 'new'" />
    </v-window-item>
  </v-window>
</template>

<script setup>
import SearchBox from '@/components/SearchBox.vue'
import { ref } from 'vue'
import Leaderboard from '../components/Discovery/Leaderboard.vue'

const tab = ref('one') // Initialize to the first tab
</script>

<style lang="scss" scoped>
.profile-page {
  padding: 20px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.followers,
.share-price {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.custom-autocomplete {
  position: relative;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid #30313b;
  border-radius: 4px;
  z-index: 1000;
  left: 0%;
  top: -20px;
  background-color: #323958;
}

.custom-autocomplete-item {
  padding: 10px;
  display: flex;
  align-items: left;
  cursor: pointer;
  border-bottom: 1px solid rgb(48, 50, 59);
  &:hover {
    background-color: #131520;
  }
}

.user-image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.user-details {
  margin-left: 20px;
  text-align: left;
  .username {
    font-weight: bold;
    color: #a5a5a5;
    font-style: italic;
    font-size: 12px;
  }
  .display-name {
    font-weight: bold;
    color: #fff;
    font-size: 16px;
  }
}
</style>
