export const formatNumber = (param) => {
  const num = parseFloat(param)
  // Check if the number has more than 6 digits (Millions)
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'm'
  }
  // Check if the number has more than 4 digits (Thousands)
  else if (num >= 10000) {
    return (num / 1000).toFixed(1) + 'k'
  }
  // For numbers with up to 4 digits, round to a maximum of 3 decimal places
  else {
    return parseFloat(num.toFixed(4))
  }
}

export const formatNumberTwoDecimals = (param) => {
  const num = parseFloat(param)
  // Check if the number has more than 6 digits (Millions)
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'm'
  }
  // Check if the number has more than 4 digits (Thousands)
  else if (num >= 10000) {
    return (num / 1000).toFixed(1) + 'k'
  }
  // For numbers with up to 4 digits, round to a maximum of 3 decimal places
  else {
    return parseFloat(num.toFixed(2))
  }
}

export const formatDollar = (num) => {
  return parseFloat(num.toFixed(2))
}

export const shortenAddress = (address) => {
  if (!address) return ''
  const prefix = address.slice(0, 4)
  const suffix = address.slice(-3)
  return `${prefix}...${suffix}`
}
