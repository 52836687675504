<template>
  <v-dialog
    gutter="0"
    v-model="dialog"
    scrim="secondary"
    class="pa-0 quick-reply-dialog"
    :fullscreen="!mdAndUp"
    scrollable
    persistent
  >
    <v-card class="drop tweet-card d-flex pt-0 pb-0" color="surface">
      <v-progress-linear
        v-if="isUploading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-title class="pl-0 pr-0">
        <div class="d-flex align-center">
          <v-btn
            v-if="!mdAndUp"
            variant="text"
            size="large"
            @click="$emit('closeDialog')"
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="ml-md-3">Quick Reply</span>
          <template v-if="mdAndUp">
            <v-spacer></v-spacer>
            <v-btn
              size="small"
              color="surface-variant"
              variant="text"
              @click="$emit('closeDialog')"
              icon="mdi-close"
              class="mr-md-2"
            >
            </v-btn>
          </template>
        </div>
      </v-card-title>

      <v-card-text class="pa-0">
        <PostItem :post="post" />
        <v-divider></v-divider>
        <v-timeline
          v-if="post && !post.userBanned"
          truncate-line="end"
          class="ml-4 quick-reply-timeline"
          density="compact"
          side="end"
          align="start"
          height="auto"
        >
          <v-timeline-item density="compact" width="100%" size="large">
            <template #icon>
              <v-avatar
                v-if="user.profileImageUrl"
                size="40"
                :image="user.profileImageUrl"
              ></v-avatar>
              <v-avatar size="40" v-else variant="surface"></v-avatar>
            </template>
            <div class="d-flex flex-row pa-4 pl-0 pt-0 pb-3 position-relative">
              <v-textarea
                ref="quickReplyTextarea"
                v-model="textContent"
                hide-details
                label="Post your reply"
                rows="3"
                flat
                :autofocus="true"
                variant="solo-filled"
                :disabled="isUploading"
                @paste="handlePaste"
                @keydown.enter="onEnterPress"
              ></v-textarea>
              <v-sheet
                v-if="showAutocomplete"
                class="custom-autocomplete pt-2 pb-2 pl-1 pr-1"
                color="background"
                elevation="10"
              >
                <v-list-item
                  v-for="user in usernames"
                  :key="user.id"
                  class="custom-autocomplete-item"
                  @click="replaceUsername(user.id)"
                  :prepend-avatar="user.profileImage"
                  :title="`@${user.username}`"
                  :subtitle="user.displayName"
                >
                </v-list-item>
              </v-sheet>
            </div>
            <div class="text-left pa-4 pl-0 pt-0">
              <div class="position-relative" v-if="gifSelected">
                <v-btn
                  @click="gifSelected = null"
                  icon="mdi-close"
                  variant="text"
                  class="close-btn"
                ></v-btn>
                <TenorGif
                  :tenorGifUrl="gifSelected.media_formats.gif.url"
                  class="mb-3"
                />
              </div>
              <div class="d-flex justify-space-between">
                <input
                  ref="fileInput"
                  type="file"
                  hidden
                  @change="previewImage"
                  accept="image/*"
                  :disabled="isUploading"
                />
                <v-btn
                  size="large"
                  density="compact"
                  icon="mdi-image"
                  flat
                  @click="openFileInput"
                  class="mr-1"
                ></v-btn>
                <GifSelector @select-gif="handleGifSelect"></GifSelector>
                <div>
                  <v-btn
                    id="open-emoji"
                    size="large"
                    density="compact"
                    @click="openEmojiSelector = !openEmojiSelector"
                    icon
                    flat
                    class="mr-1"
                  >
                    <v-icon icon="mdi-emoticon-happy-outline" />
                  </v-btn>
                  <v-menu
                    :close-on-content-click="false"
                    attach="body"
                    :v-model="openEmojiSelector"
                    activator="#open-emoji"
                  >
                    <EmojiPicker
                      v-if="onSelectEmoji"
                      :theme="
                        isDarkMode === 'customDarkTheme' ? 'dark' : 'light'
                      "
                      class="emoji-selector"
                      disable-skin-tones
                      :native="true"
                      @select="onSelectEmoji"
                    />
                  </v-menu>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :disabled="isUploading"
                  :loading="isUploading"
                  @click="postContent"
                  >Post</v-btn
                >
              </div>
            </div>
            <div class="wrapper-file-section pb-3" v-if="imageFile">
              <h6 class="text-overline">Selected Image</h6>
              <v-btn
                class="mb-2 remove-image"
                @click="removeImage"
                prepend-icon="mdi-close"
                color="secondary"
                variant="tonal"
              >
                <span class="text-ellipsis"> {{ imageFile.name }} </span>
              </v-btn>

              <div class="image-preview">
                <v-img v-if="imagePreview" cover :src="imagePreview"></v-img>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
        <v-card class="chat-box" v-if="post && post.userBanned">
          <v-card-actions class="d-flex align-center">
            <template v-if="user">
              <v-avatar class="mr-3" :image="user.profileImageUrl"></v-avatar>
            </template>
            <div>You are unable to comment on this users post</div>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import axios from 'axios'
import { computed, nextTick, ref, watch, watchEffect } from 'vue'
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import { useDisplay, useTheme } from 'vuetify'
import { useStore } from 'vuex'
import PostItem from '../PostItem.vue'
import GifSelector from './GifSelector.vue'
import TenorGif from './TenorGif.vue'

const props = defineProps({
  post: Object,
  showDialog: Boolean,
})
const emit = defineEmits(['closeDialog', 'replyPosted', 'refetch-replies'])
const store = useStore()
const theme = useTheme()

// const router = useRouter()
const isDarkMode = computed(() => {
  return theme.global.name.value
})
const imageFile = ref(null)
const imagePreview = ref(null)
const fileInput = ref(null)
// const level = ref('Everyone')
const textContent = ref('')
const isUploading = ref(false)
const showAutocomplete = ref(false)
const gifSelected = ref(null)
const dialog = ref(props.showDialog || false)
const quickReplyTextarea = ref(null)
const { mdAndUp } = useDisplay()
const openEmojiSelector = ref(false)

const usernames = ref([])

const onSelectEmoji = (emoji) => {
  // Ensure the textarea is focused
  const textarea = quickReplyTextarea.value
  textarea.focus()

  const startPos = textarea.selectionStart
  const endPos = textarea.selectionEnd

  // Update the textContent value
  const textBefore = textContent.value.substring(0, startPos)
  const textAfter = textContent.value.substring(endPos)

  textContent.value = textBefore + emoji.i + textAfter

  nextTick(() => {
    // Set the cursor position right after the inserted emoji
    const newCursorPos = startPos + emoji.i.length
    quickReplyTextarea.value.focus()
    quickReplyTextarea.value.setSelectionRange(newCursorPos, newCursorPos)
  })

  // Close the emoji selector if necessary
  openEmojiSelector.value = false
  /*
    // result
    { 
        i: "😚", 
        n: ["kissing face"], 
        r: "1f61a", // with skin tone
        t: "neutral", // skin tone
        u: "1f61a" // without tone
    }
    */
}

let inTaggingMode = false // Flag to indicate if you're in "user tagging mode"
let atPosition = -1 // Position of the '@' symbol

watchEffect(() => {
  console.log(dialog.value)
  console.log(props.showDialog)
  dialog.value = props.showDialog // Update localDialog whenever showDialog changes
})

watch(textContent, async (newVal) => {
  try {
    const cursorPosition = document.querySelector(
      '.quick-reply-timeline textarea'
    ).selectionStart
    const charBeforeCursor = newVal.charAt(cursorPosition - 1)

    if (charBeforeCursor === '@') {
      inTaggingMode = true // We're now in tagging mode
      atPosition = cursorPosition - 1 // Store the position of '@'
    }

    // Check if there are any spaces between '@' and the current cursor position
    const stringBetweenAtAndCursor = newVal.substring(
      atPosition + 1,
      cursorPosition
    )
    const hasSpace = stringBetweenAtAndCursor.includes(' ')

    if (inTaggingMode && !hasSpace) {
      const nextChar = newVal.charAt(cursorPosition)
      if (nextChar !== ' ') {
        const query = newVal.substring(atPosition + 1, cursorPosition) // Extract the substring after '@'
        if (query.length > 0) {
          // Make sure query is not empty
          showAutocomplete.value = true

          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/api/users/searchusers?q=${query}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          )
          if (response?.data?.success) {
            usernames.value = response.data.users.map((user) => ({
              id: user.username,
              username: user.username,
              displayName: user.displayName,
              profileImage: user.profileImageUrl,
              title: `@${user.username} - ${user.displayName}`,
            }))
            console.log('usernames', usernames.value)
          } else {
            console.log('error', response.data)
            usernames.value = []
          }
          return
        }
      }
    }

    // Reset tagging mode if a space is encountered or if cursor goes back before '@'
    if (hasSpace || cursorPosition <= atPosition) {
      inTaggingMode = false
      atPosition = -1
    }

    showAutocomplete.value = false
  } catch (error) {
    console.log('error', error)
  }
})

const onEnterPress = (event) => {
  if (inTaggingMode && usernames.value.length === 1) {
    event.preventDefault()
    replaceUsername(usernames.value[0].id)
    inTaggingMode = false
  }
}

const replaceUsername = (newVal) => {
  try {
    if (!newVal) return // Add this line to handle undefined or null values

    const userObj = usernames.value.find((user) => user.id === newVal)
    console.log('userObj', userObj)
    if (!userObj) return

    const newUsername = userObj.username

    const textareaElem = document.querySelector(
      '.quick-reply-timeline textarea'
    )
    const cursorPosition = textareaElem.selectionStart
    const atPosition = textContent.value.lastIndexOf('@', cursorPosition - 1)
    const beforeAt = textContent.value.substring(0, atPosition)
    const afterAt = textContent.value.substring(cursorPosition)

    textContent.value = `${beforeAt}@${newUsername} ${afterAt}`
    showAutocomplete.value = false
    textareaElem.selectionStart = textareaElem.selectionEnd =
      atPosition + newUsername.length + 2

    nextTick(() => {
      textareaElem.focus()
      showAutocomplete.value = false
    })
  } catch (error) {
    console.log('error', error)
  }
}

const handleGifSelect = (gif) => {
  gifSelected.value = gif
}

const previewImage = (event) => {
  const file = event.target.files[0]
  if (file) {
    imageFile.value = file
    imagePreview.value = URL.createObjectURL(file)
  }
}

const removeImage = () => {
  imageFile.value = null
  imagePreview.value = null
  fileInput.value.value = null
}

const openFileInput = () => {
  fileInput.value.click()
}

const handlePaste = async (event) => {
  const clipboardData = event.clipboardData || window.clipboardData
  if (clipboardData && clipboardData.items) {
    const item = Array.from(clipboardData.items).find(
      (item) => item.type.indexOf('image') !== -1
    )
    if (item) {
      const blob = item.getAsFile()
      loadImage(blob)
      event.preventDefault() // Prevent the image from being pasted into the textarea
    }
  }
}

const loadImage = (blob) => {
  imageFile.value = blob
  imagePreview.value = URL.createObjectURL(blob)
}

const postContent = async () => {
  isUploading.value = true
  const formData = new FormData()
  let contentType = 'application/json'

  let isImage = false

  if (imageFile.value) {
    formData.append('image', imageFile.value)
    isImage = true
    contentType = 'multipart/form-data'
  }
  let combinedContent = textContent.value

  if (gifSelected.value) {
    combinedContent += `\n${gifSelected.value.media_formats.gif.url}`
  }

  formData.append('content', combinedContent)

  let url = isImage
    ? `${process.env.VUE_APP_API_URL}/api/image/upload`
    : `${process.env.VUE_APP_API_URL}/api/text/upload`

  // Append the friendlyId to the URL if it's provided
  if (props.post?.friendlyId) {
    url += `/${props.post.friendlyId}`
  }
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': contentType,
        //add a bearer token if you have one from localstorage token
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    imageFile.value = null
    fileInput.value.value = null
    textContent.value = ''
    console.log(response)
    store.dispatch('showSnackbar', {
      show: true,
      message: 'Posted Successfully!',
      color: 'success',
      // timeout: 2000, // Optional. Default is 3000
    })
    // router.push({ name: 'Feed' })
    emit('replyPosted', {
      post: props.post,
      newCommentCount: props.post.commentCount + 1,
    })
    emit('closeDialog')
  } catch (error) {
    console.log('Error uploading video:', error)
    store.dispatch('showSnackbar', {
      show: true,
      message: error.response?.data?.errors[0]?.msg || error.message,
      color: 'error',
      // timeout: 2000, // Optional. Default is 3000
    })
  }
  isUploading.value = false
}

const user = computed(() => {
  if (store.state.user) {
    return store.state.user
  }
  return null
})
</script>
<style>
.remove-image .v-btn__content {
  max-width: 150px;
}
.quick-reply-timeline {
  height: 100% !important;
}
.quick-reply-timeline .v-timeline-item__body {
  padding-left: 16px !important;
}
</style>
<style lang="scss" scoped>
.quick-reply-dialog.v-dialog {
  @media (min-width: 768px) {
    max-width: 800px;
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 40px); /* Adjusted width to accommodate the icon */
}

.custom-autocomplete {
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  left: 0;
  z-index: 10;
  top: 102px;
}

.custom-autocomplete-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #131520;
  }
}

.user-image img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.user-details {
  margin-left: 10px;
  .username {
    font-weight: bold;
    color: #a5a5a5;
    font-style: italic;
    font-size: 11px;
  }
  .display-name {
    font-weight: bold;
    color: #fff;
    font-size: 14px;
  }
}
</style>
