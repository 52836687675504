// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Feed from '../views/Feed.vue'
import PostText from '../views/PostText.vue'
import PostVideo from '../views/PostVideo.vue'
import Privacy from '../views/Privacy.vue'
import Profile from '../views/Profile.vue'
import Rooms from '../views/Rooms.vue'
import Search from '../views/Search.vue'
import Tos from '../views/Tos.vue'
import UserTransactions from '../views/UserTransactions.vue'
import Videos from '../views/Videos.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('../views/Welcome.vue'),
    meta: { requiresAuth: true, title: 'Welcome' },
  },
  {
    path: '/tos',
    name: 'Terms of Service',
    component: Tos,
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: Privacy,
  },
  {
    path: '/temp',
    name: 'Temp',
    component: () => import('../views/Temp2.vue'),
  },
  {
    path: '/',
    component: () => import('../layouts/BaseLayout.vue'),
    children: [
      {
        path: '/videos',
        name: 'Videos',
        component: Videos,
        meta: { requiresAuth: true, title: 'Videos' },
      },
      {
        path: '/upload-video',
        name: 'Upload Video',
        component: () => import('../views/UploadVideo.vue'),
        meta: { requiresAuth: true, title: 'Upload Video' },
      },
      {
        path: '/video/:postFriendlyId',
        name: 'SingleVideo',
        component: () => import('../views/SingleVideo.vue'),
        meta: { requiresAuth: true, title: 'Video' },
      },
      {
        path: '/profile/:username?',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true, title: 'Profile' },
      },
      {
        path: '/profile/:username/following', // change from /following to following
        name: 'Following',
        component: () => import('../views/FollowerFollowingList.vue'),
        meta: { requiresAuth: true, title: 'Following' },
      },
      {
        path: '/profile/:username/followers', // change from /following to following
        name: 'Followers',
        component: () => import('../views/FollowerFollowingList.vue'),
        meta: { requiresAuth: true, title: 'Followers' },
      },

      {
        path: '/search',
        name: 'Discover',
        component: Search,
        meta: { requiresAuth: true, title: 'Discover' },
      },
      {
        path: '/tickets',
        name: 'Tickets',
        component: () => import('../views/Tickets.vue'),
        meta: { requiresAuth: true, title: 'Tickets' },
      },
      {
        path: '/transactions',
        name: 'Transactions',
        component: UserTransactions,
        meta: { requiresAuth: true, title: 'Transactions' },
      },
      {
        path: '/activity',
        name: 'Activity',
        component: () => import('../views/Activity.vue'),
        meta: { requiresAuth: true, title: 'Activity' },
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('../views/Settings.vue'),
        meta: { requiresAuth: true, title: 'Settings' },
      },
      {
        path: '/feed',
        name: 'Feed',
        component: Feed,
        meta: { requiresAuth: true, title: 'Feed' },
      },
      {
        path: '/feed/post/:id',
        name: 'SinglePost',
        component: () => import('../views/SinglePost.vue'),
        meta: { requiresAuth: true, title: 'Post' },
      },
      {
        path: '/rooms',
        name: 'Rooms',
        component: Rooms,
        meta: { requiresAuth: true, title: 'Rooms' },
      },
      {
        path: '/rooms/create',
        name: 'CreateRoom',
        component: () => import('../views/CreateRoom.vue'),
        meta: { requiresAuth: true, title: 'Create Room - Legendary' },
      },
      {
        path: '/saved',
        name: 'Saved',
        component: () => import('../views/Saved.vue'),
        meta: { requiresAuth: true, title: 'Saved' },
      },
      {
        path: '/airdrop',
        name: 'Airdrop',
        component: () => import('../views/Airdrop.vue'),
        meta: { requiresAuth: true, title: 'Airdrop' },
      },
      {
        path: '/refer',
        name: 'Refer',
        component: () => import('../views/Refer.vue'),
        meta: { requiresAuth: true, title: 'Refer and Earn' },
      },
      {
        path: '/wallet',
        name: 'Wallet',
        component: () => import('../views/Wallet.vue'),
        meta: { requiresAuth: true, title: 'Wallet' },
      },
      {
        path: '/room/:roomId',
        name: 'SingleRoom',
        component: () => import('../views/SingleRoom.vue'),
        meta: { requiresAuth: true, title: 'Room' },
      },
      {
        path: '/post-video',
        name: 'Post Video',
        component: PostVideo,
        meta: { requiresAuth: true, title: 'Post a Video' },
      },
      {
        path: '/post-text',
        name: 'Post Text',
        component: PostText,
        meta: { requiresAuth: true, title: 'Post an Update' },
      },
    ],
  },
  // other routes can go here
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If savedPosition exists, that means this is a popstate event (like using the browser's back button)
    // In such cases, we use the saved position and scroll to that position.
    if (savedPosition) {
      return savedPosition
    } else if (store.state.feedScrollPosition[to.name] !== undefined) {
      return { left: 0, top: store.state.feedScrollPosition[to.name] }
    } else {
      // For all other route navigations, return to the top
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Legendary'

  const token = localStorage.getItem('token')
  const hasSeenWelcome = localStorage.getItem('hasSeenWelcome')
  const hasTokenInQuery = Boolean(to.query.token) // Check for token in URL parameters

  // If logged in but hasn't seen the welcome page and not already going to /welcome
  if (token && !hasSeenWelcome && to.path !== '/welcome') {
    return next({ path: '/welcome' })
  }

  // If on the login page with a token in the URL, allow navigation
  if (to.path === '/login' && hasTokenInQuery) {
    return next()
  }

  // If at the root path and token exists in localStorage, redirect to feed
  if (to.path === '/' && token) {
    return next({ path: '/feed' })
  }

  // If at the root path and no token in localStorage, redirect to login
  if (to.path === '/' && !token) {
    return next({ path: '/login' })
  }

  // Check if the route requires authentication and there's no token
  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    store.commit('setUser', null)
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

export default router
