<template>
  <v-progress-linear
    v-if="isUploading"
    indeterminate
    color="primary"
  ></v-progress-linear>
  <v-card
    class="drop tweet-card overflow-visible d-flex pt-0 pb-0"
    variant="text"
  >
    <template v-if="user">
      <v-avatar class="mt-4 ml-3" :image="user.profileImageUrl"></v-avatar>
    </template>
    <v-card-text>
      <div class="text-left position-relative">
        <div class="mb-2">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn size="x-small" v-bind="props"> {{ permissions }}</v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title @click="permissions = 'Everyone'"
                  >Everyone</v-list-item-title
                >
                <v-list-item-title @click="permissions = 'Holders'"
                  >Holders</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-textarea
          v-model="textContent"
          label="What's on your mind?"
          variant="solo"
          :disabled="isUploading"
          @paste="handlePaste"
          @keydown.enter="onEnterPress"
          :autofocus="true"
          ref="postBox"
        ></v-textarea>
        <v-sheet
          v-if="showAutocomplete"
          class="custom-autocomplete pt-2 pb-2 pl-1 pr-1"
          color="background"
          elevation="10"
        >
          <v-list-item
            v-for="user in usernames"
            :key="user.id"
            class="custom-autocomplete-item"
            @click="replaceUsername(user.id)"
            :prepend-avatar="user.profileImage"
            :title="`@${user.username}`"
            :subtitle="user.displayName"
          >
          </v-list-item>
        </v-sheet>

        <div class="d-flex justify-space-between">
          <input
            ref="fileInput"
            type="file"
            hidden
            @change="previewImage"
            accept="image/*"
            :disabled="isUploading"
          />
          <v-btn
            size="large"
            density="compact"
            icon="mdi-image"
            @click="openFileInput"
            class="mr-1"
            flat
          ></v-btn>
          <GifSelector class="mr-1" @select-gif="handleGifSelect"></GifSelector>
          <div>
            <v-btn
              id="open-emoji"
              size="large"
              density="compact"
              @click="openEmojiSelector = !openEmojiSelector"
              icon
              flat
              class="mr-1"
            >
              <v-icon icon="mdi-emoticon-happy-outline" />
            </v-btn>
            <v-menu
              :close-on-content-click="false"
              attach="body"
              :v-model="openEmojiSelector"
              activator="#open-emoji"
            >
              <EmojiPicker
                v-if="onSelectEmoji"
                :theme="isDarkMode === 'customDarkTheme' ? 'dark' : 'light'"
                class="emoji-selector"
                disable-skin-tones
                :native="true"
                @select="onSelectEmoji"
              />
            </v-menu>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="isUploading"
            :loading="isUploading"
            @click="postContent"
            >Post</v-btn
          >
        </div>
        <div class="position-relative mt-4" v-if="gifSelected">
          <v-btn
            @click="gifSelected = null"
            icon="mdi-close"
            variant="text"
            flat
            class="close-btn"
          ></v-btn>
          <TenorGif
            :tenorGifUrl="gifSelected.media_formats.gif.url"
            class="mb-3"
          />
        </div>
        <div class="wrapper-file-section" v-if="imageFile">
          <h6 class="text-overline">Selected Image</h6>
          <v-btn class="mb-2" @click="removeImage" prepend-icon="mdi-close">
            <div class="text-truncate-container">
              <span>
                {{ imageFile.name }}
              </span>
            </div>
          </v-btn>

          <div class="image-preview">
            <v-img v-if="imagePreview" cover :src="imagePreview"></v-img>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script setup>
import axios from 'axios'
import { computed, nextTick, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import { useTheme } from 'vuetify'
import { useStore } from 'vuex'
import GifSelector from './GifSelector.vue'
const postBox = ref(null)

import TenorGif from './TenorGif.vue'

const props = defineProps({
  repost: Object,
})
const store = useStore()
const theme = useTheme()
const router = useRouter()
const imageFile = ref(null)
const imagePreview = ref(null)
const fileInput = ref(null)
const permissions = ref('Everyone')
const textContent = ref('')
const isUploading = ref(false)
const showAutocomplete = ref(false)
const gifSelected = ref(null)
const openEmojiSelector = ref(false)

const usernames = ref([])

// const router = useRouter()
const isDarkMode = computed(() => {
  return theme.global.name.value
})
const onSelectEmoji = (emoji) => {
  // Ensure the textarea is focused
  const textarea = postBox.value
  textarea.focus()

  const startPos = textarea.selectionStart
  const endPos = textarea.selectionEnd

  // Update the textContent value
  const textBefore = textContent.value.substring(0, startPos)
  const textAfter = textContent.value.substring(endPos)

  textContent.value = textBefore + emoji.i + textAfter

  nextTick(() => {
    // Set the cursor position right after the inserted emoji
    const newCursorPos = startPos + emoji.i.length
    postBox.value.focus()
    postBox.value.setSelectionRange(newCursorPos, newCursorPos)
  })

  // Close the emoji selector if necessary
  openEmojiSelector.value = false
  /*
    // result
    { 
        i: "😚", 
        n: ["kissing face"], 
        r: "1f61a", // with skin tone
        t: "neutral", // skin tone
        u: "1f61a" // without tone
    }
    */
}

let inTaggingMode = false // Flag to indicate if you're in "user tagging mode"
let atPosition = -1 // Position of the '@' symbol

watch(textContent, async (newVal) => {
  try {
    const cursorPosition = document.querySelector('textarea').selectionStart
    const charBeforeCursor = newVal.charAt(cursorPosition - 1)

    if (charBeforeCursor === '@') {
      inTaggingMode = true // We're now in tagging mode
      atPosition = cursorPosition - 1 // Store the position of '@'
    }

    // Check if there are any spaces between '@' and the current cursor position
    const stringBetweenAtAndCursor = newVal.substring(
      atPosition + 1,
      cursorPosition
    )
    const hasSpace = stringBetweenAtAndCursor.includes(' ')

    if (inTaggingMode && !hasSpace) {
      const nextChar = newVal.charAt(cursorPosition)
      if (nextChar !== ' ') {
        const query = newVal.substring(atPosition + 1, cursorPosition) // Extract the substring after '@'
        console.log('query', query)
        if (query.length > 0) {
          // Make sure query is not empty
          showAutocomplete.value = true

          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/api/users/searchusers?q=${query}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          )
          if (response?.data?.success) {
            usernames.value = response.data.users.map((user) => ({
              id: user.username,
              username: user.username,
              displayName: user.displayName,
              profileImage: user.profileImageUrl,
              title: `@${user.username} - ${user.displayName}`,
            }))
            console.log('usernames', usernames.value)
          } else {
            console.log('error', response.data)
            usernames.value = []
          }
          return
        }
      }
    }

    // Reset tagging mode if a space is encountered or if cursor goes back before '@'
    if (hasSpace || cursorPosition <= atPosition) {
      inTaggingMode = false
      atPosition = -1
    }

    showAutocomplete.value = false
  } catch (error) {
    console.log('error', error)
  }
})

const onEnterPress = (event) => {
  if (inTaggingMode && usernames.value.length === 1) {
    event.preventDefault()
    replaceUsername(usernames.value[0].id)
    inTaggingMode = false
  }
}

const replaceUsername = (newVal) => {
  try {
    if (!newVal) return // Add this line to handle undefined or null values

    const userObj = usernames.value.find((user) => user.id === newVal)
    console.log('userObj', userObj)
    if (!userObj) return

    const newUsername = userObj.username

    const textareaElem = document.querySelector('textarea')
    const cursorPosition = textareaElem.selectionStart
    const atPosition = textContent.value.lastIndexOf('@', cursorPosition - 1)
    const beforeAt = textContent.value.substring(0, atPosition)
    const afterAt = textContent.value.substring(cursorPosition)

    textContent.value = `${beforeAt}@${newUsername} ${afterAt}`
    showAutocomplete.value = false
    textareaElem.selectionStart = textareaElem.selectionEnd =
      atPosition + newUsername.length + 2

    nextTick(() => {
      textareaElem.focus()
      showAutocomplete.value = false
    })
  } catch (error) {
    console.log('error', error)
  }
}

const handleGifSelect = (gif) => {
  gifSelected.value = gif
}

const previewImage = (event) => {
  const file = event.target.files[0]
  if (file) {
    imageFile.value = file
    imagePreview.value = URL.createObjectURL(file)
  }
}

const removeImage = () => {
  imageFile.value = null
  imagePreview.value = null
  fileInput.value.value = null
}

const openFileInput = () => {
  fileInput.value.click()
}

const handlePaste = async (event) => {
  const clipboardData = event.clipboardData || window.clipboardData
  if (clipboardData && clipboardData.items) {
    const item = Array.from(clipboardData.items).find(
      (item) => item.type.indexOf('image') !== -1
    )
    if (item) {
      const blob = item.getAsFile()
      loadImage(blob)
      event.preventDefault() // Prevent the image from being pasted into the textarea
    }
  }
}

const loadImage = (blob) => {
  imageFile.value = blob
  imagePreview.value = URL.createObjectURL(blob)
}

const postContent = async () => {
  isUploading.value = true
  const formData = new FormData()

  let isImage = false

  if (imageFile.value) {
    formData.append('image', imageFile.value)
    isImage = true
  }
  let combinedContent = textContent.value

  if (gifSelected.value) {
    combinedContent += `\n${gifSelected.value.media_formats.gif.url}`
  }

  formData.append('content', combinedContent)

  let url = isImage
    ? `${process.env.VUE_APP_API_URL}/api/image/upload`
    : `${process.env.VUE_APP_API_URL}/api/text/upload`
  let addendum = '?postPermissions=' + permissions.value
  url += addendum

  // Append the friendlyId to the URL if it's provided
  if (props.repost?.friendlyId) {
    formData.append('repostFriendlyId', props.repost.friendlyId)
  }
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        //add a bearer token if you have one from localstorage token
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    imageFile.value = null
    fileInput.value.value = null
    textContent.value = ''
    console.log(response)
    store.dispatch('showSnackbar', {
      show: true,
      message: 'Posted Successfully!',
      color: 'success',
      // timeout: 2000, // Optional. Default is 3000
    })
    store.dispatch('clearFeedCache')
    router.push({ name: 'Feed', query: { mode: 'for-you' } })
  } catch (error) {
    console.log('Error uploading video:', error)
    store.dispatch('showSnackbar', {
      show: true,
      message: error.response.data.errors[0].msg || error.message,
      color: 'error',
      // timeout: 2000, // Optional. Default is 3000
    })
  }
  isUploading.value = false
}

const user = computed(() => {
  console.log(store.state.user)
  if (store.state.user) {
    return store.state.user
  }
  return null
})
</script>

<style lang="scss" scoped>
/* @import '../../assets/global.scss'; */
.text-truncate-container {
  max-width: 100%; // Adjust as needed
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}
.close-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}
.post-tabs .v-tabs-slider {
  top: 0 !important;
  bottom: auto !important;
}
.video-preview {
  height: 100%;
  width: 100%;
  margin: 0 0 1rem 0;
  video {
    width: 65%;
    object-fit: cover;
    max-width: 500px;
  }
}
.video-upload-container {
  flex-direction: column;
  align-items: center;
  display: grid;
  height: 90%;
}
.file-input-box {
  padding: 4rem 2rem;
  border-style: dashed;
  width: 80%;
  margin: 0 auto 1rem;
  border-width: 3px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
}
.input-box {
  width: 90%;
  border-radius: 4px;
  border: 1px #000000a6 solid;
  font-size: 1.1rem;
  padding: 1rem;
}

.custom-autocomplete {
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  left: 0;
  z-index: 10;
  top: 175px;
}

.custom-autocomplete-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #131520;
  }
}

.user-image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.user-details {
  margin-left: 10px;
  .username {
    font-weight: bold;
    color: #a5a5a5;
    font-style: italic;
    font-size: 12px;
  }
  .display-name {
    font-weight: bold;
    color: #fff;
    font-size: 16px;
  }
}
</style>
