<template>
  <swiper
    v-if="!isLoading"
    :direction="'vertical'"
    class="video-swiper"
    :mousewheel="{ thresholdDelta: 20, sensitivity: 2 }"
    keyboard
    :modules="modules"
    @slideChange="handleSlideChange"
  >
    <swiper-slide v-for="(video, index) in videos" :key="index">
      <VideoItem
        :video="video"
        :isCurrentSlide="currentSlide === index"
        :isMuted="isMuted"
        @showTipDialog="showTipDialogForPost(post)"
        @showShareDialog="showShareForPost(post)"
        @toggleSound="toggleSound"
      />
    </swiper-slide>
  </swiper>
  <VideoSkeleton v-else />
  <Tipping
    :show="showTipDialog"
    :subject="subject"
    :postId="postId"
    @close="showTipDialog = false"
    @tip="handleTipAmount"
  />
  <Share
    :show="showShareDialog"
    :urlAddendum="urlAddendum"
    :postType="postType"
    @close="showShareDialog = false"
  />
</template>

<script setup>
import Share from '@/components/UserActions/Share.vue'
import axios from 'axios'
import { Mousewheel } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { inject, onMounted, ref } from 'vue'
import Tipping from '../components/UserActions/Tipping.vue'
import VideoItem from '../components/Videos/VideoItem.vue'

// Import Swiper styles
import VideoSkeleton from '@/components/Videos/VideoSkeleton.vue'
import 'swiper/css'
import { useStore } from 'vuex'

const emitter = inject('emitter')
const videos = ref([])
const lastSeenTimestamp = ref(null)
const isLoading = ref(true)
const currentSlide = ref(0) // Initialize to the first slide
const showTipDialog = ref(false)
const subject = ref(null)
const postId = ref(null)
const currentPost = ref(null)
const store = useStore()
const isMuted = ref(store.state.videoMuted || false)
const isInitialVisit = ref(true)
const urlAddendum = ref(null)
const postType = ref(null)
const showShareDialog = ref(false)
const modules = [Mousewheel]

emitter.on('showTipDialogHome', (value) => {
  showTipDialogForPost(value)
})

emitter.on('showShareDialogHome', (value) => {
  showShareForPost(value)
})

const showTipDialogForPost = (post) => {
  currentPost.value = post
  subject.value = post.user.address
  postId.value = post.postId
  showTipDialog.value = true
}
const showShareForPost = (post) => {
  urlAddendum.value = '/video/' + post.friendlyId
  postType.value = 'video'
  showShareDialog.value = true
}

const handleTipAmount = (tipAmount) => {
  if (currentPost.value) {
    currentPost.value.tipDollars += tipAmount
  }
}

const api = async () => {
  try {
    let url = `${process.env.VUE_APP_API_URL}/api/posts/feed?filterType=video&discover=true`

    if (lastSeenTimestamp.value) {
      url += `&lastSeen=${lastSeenTimestamp.value}`
    }

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
        //add a bearer token if you have one from localstorage token
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })

    lastSeenTimestamp.value = response.data.lastSeen
    return response.data.posts
  } catch (error) {
    console.log('Error uploading video:', error)
  }
}
const handleSlideChange = (event) => {
  currentSlide.value = event.activeIndex
  console.log(currentSlide.value)
  // Determine when to call loadMore
  const thresholdIndex = videos.value.length - 8 // Adjust threshold as needed
  if (currentSlide.value >= thresholdIndex) {
    loadMore()
  }
}

const loadMore = async () => {
  console.log('asynchronously loading more videos')
  const newVideos = await api() // Fetch new videos
  if (newVideos && newVideos.length > 0) {
    videos.value.push(...newVideos) // Add new videos to the existing list
  }
}

const toggleSound = () => {
  isMuted.value = !isMuted.value
  store.commit('setVideoMuted', isMuted.value)
  store.dispatch('showSnackbar', {
    show: true,
    message: isMuted.value ? 'Muted' : 'Unmuted',
    color: 'surface',
    location: 'center',
    timeout: 1000, // Optional. Default is 3000
  })
}

onMounted(async () => {
  //This is to handle if the Feed is refreshed to avoid crashing
  if (isInitialVisit.value) {
    isMuted.value = true
    isInitialVisit.value = false
  }
  isLoading.value = true
  videos.value = await api() // Load initial posts
  isLoading.value = false
})
</script>

<style lang="scss" scoped>
.swiper {
  height: calc(100vh - 64px - 72px); // removing header and bottom nav
  @media (min-width: 768px) {
    // Tablet and above
    width: 551px;
    position: relative;
    margin: 0 auto;
    height: calc(100vh - 64px); // removing header and bottom nav
  }
}
/* Your CSS goes here */
</style>
