<template>
  <v-dialog v-model="showShareDialog" max-width="500px" :persistent="isTipping">
    <v-card>
      <v-progress-linear
        v-if="isTipping"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-title>Share with following options: </v-card-title>
      <v-card-text
        class="d-flex flex-row justify-center align-center justify-space-between"
      >
        <v-btn icon variant="elevated" color="#000" @click="shareOnTwitter">
          <v-icon class="x-icon"></v-icon>
        </v-btn>
        <v-btn icon variant="elevated" color="#2AABEE" @click="shareOnTelegram">
          <span class="telegram-icon"></span>
        </v-btn>
        <v-btn
          icon
          variant="elevated"
          bg-color="background"
          color="#4267B2"
          @click="shareOnFacebook"
        >
          <v-icon size="x-large">mdi-facebook</v-icon>
        </v-btn>

        <v-btn icon variant="elevated" color="#0077B5" @click="shareOnLinkedIn">
          <v-icon size="x-large">mdi-linkedin</v-icon>
        </v-btn>

        <v-btn
          icon
          variant="elevated"
          color="background"
          @click="copyLinkToClipboard"
        >
          <v-icon size="x-large">mdi-content-copy</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn @click="closeDialog">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  show: Boolean,
  urlAddendum: String,
  postType: String,
})

const store = useStore()

const emit = defineEmits(['close'])
const isTipping = ref(false)
const showShareDialog = ref(props.show)
const urlAddendum = ref(props.urlAddendum)
const postType = ref(props.postType)

const shareUrl = computed(
  () =>
    `${window.location.protocol}//${window.location.host}${urlAddendum.value}`
)
const shareText = computed(() => {
  if (postType.value === 'refer') {
    return 'Come join me on the Legendary app with my referral link!'
  } else {
    return `Check out this ${postType.value} on @thelegendaryapp`
  }
})

function shareOnFacebook() {
  const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    shareUrl.value
  )}`
  window.open(url, '_blank')
}

function copyLinkToClipboard() {
  navigator.clipboard.writeText(shareUrl.value).then(() => {
    store.dispatch('showSnackbar', {
      show: true,
      message: 'Link copied to clipboard!',
      color: 'success',
    })
  })
}

function shareOnTwitter() {
  const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    shareUrl.value
  )}&text=${encodeURIComponent(shareText.value)}`
  window.open(url, '_blank')
}

function shareOnLinkedIn() {
  const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    shareUrl.value
  )}`
  window.open(url, '_blank')
}
function shareOnTelegram() {
  const url = `https://t.me/share/url?url=${encodeURIComponent(
    shareUrl.value
  )}&text=${encodeURIComponent(shareText.value)}`
  window.open(url, '_blank')
}
watch(
  [() => props.show, () => props.urlAddendum, () => props.postType],
  ([newShow, newUrlAddendum, newPostType]) => {
    showShareDialog.value = newShow
    urlAddendum.value = newUrlAddendum
    postType.value = newPostType
  }
)

const closeDialog = () => {
  showShareDialog.value = false
  emit('close') // Emit an event to close the dialog
}
</script>
<style lang="scss"></style>
