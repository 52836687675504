<template>
  <div class="video-comments">
    <p class="text-subtitle-2 text-center pa-2 font-weight-bold">Comments</p>
    <v-divider></v-divider>
    <v-list class="comment-list" dense v-if="!isFetchingComments">
      <template v-if="comments.length > 0">
        <CommentItem
          class="video-comment-item"
          :comment="comment"
          v-for="comment in comments"
          :key="comment.id"
          @openLink="(link) => (hrefLink = link)"
        />
      </template>
      <p v-else class="text-body-2 text-disabled text-center my-auto mx-auto">
        No comments yet
      </p>
    </v-list>
    <VideoCommentSkeleton v-else :quantity="5" />
    <VideoCommentForm
      :post="post"
      @refetchReplies="refetchReplies(post.friendlyId)"
    />
    <OpenLinkDialog
      v-if="hrefLink"
      :hrefLink="hrefLink"
      @close="hrefLink = null"
    />
  </div>
</template>
<script setup>
import axios from 'axios'
import { onMounted, ref } from 'vue'
import OpenLinkDialog from '../OpenLinkDialog.vue'
import CommentItem from './CommentItem.vue'
import VideoCommentForm from './VideoCommentForm.vue'
import VideoCommentSkeleton from './VideoCommentSkeleton.vue'

defineEmits(['navigateToPost', 'showTipDialog'])
// const store = useStore()
const props = defineProps({ post: Object })
const comments = ref([])
const isFetchingComments = ref(false)
const hrefLink = ref(null)

onMounted(async () => {
  console.log(props.post)
  if (props.post) comments.value = await fetchReplies(props.post.friendlyId)
})

const refetchReplies = async (friendlyId) => {
  comments.value = await fetchReplies(friendlyId)
}

// Placeholder data for comments and current user
const fetchReplies = async (friendlyId) => {
  isFetchingComments.value = true
  try {
    let url = `${process.env.VUE_APP_API_URL}/api/posts/comments/${friendlyId}`

    // if (lastSeenTimestamp.value) {
    //   url += `&lastSeen=${lastSeenTimestamp.value}`
    // }

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        //add a bearer token if you have one from localstorage token
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })

    // lastSeenTimestamp.value = response.data.lastSeen
    isFetchingComments.value = false
    console.log(response.data.comments)
    return response.data.comments
  } catch (error) {
    console.log('Error uploading video:', error)
    isFetchingComments.value = false
  }
}
</script>
<style lang="scss">
.video-comments {
  min-height: 416px;
}
.video-comment-item p.mb-3 {
  margin-bottom: 0 !important;
}
</style>
