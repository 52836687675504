<template>
  <v-btn
    v-if="!user.isMe"
    class="capitalize flake-follow-btn mr-0"
    :size="size"
    :variant="isFollowing ? unfollow : variant"
    :color="color"
    :loading="isLoading"
    :disabled="isLoading"
    @click.stop="followAction"
    :density="density"
  >
    {{ isFollowing ? 'Unfollow' : 'Follow' }}
  </v-btn>
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
  props: {
    user: Object,
    size: {
      type: String,
      default: 'small',
    },
    variant: {
      type: String,
      default: 'outlined',
    },
    unfollow: {
      type: String,
      default: 'outlined',
    },
    density: {
      type: String,
      default: 'default',
    },
    color: {
      type: String,
      default: '',
    },
  },
  components: {},
  setup(props) {
    const isFollowing = ref(props.user.isFollowing)
    const isLoading = ref(false)
    const store = useStore()
    const showTradePopup = ref(false)

    const followAction = async () => {
      isLoading.value = true
      let url = `${process.env.VUE_APP_API_URL}/api/users/follow/${props.user.username}`

      if (isFollowing.value) {
        url = `${process.env.VUE_APP_API_URL}/api/users/unfollow/${props.user.username}`
      }

      try {
        await axios.post(
          url,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )

        isFollowing.value = !isFollowing.value
      } catch (error) {
        console.log(error)
        store.dispatch('showSnackbar', {
          show: true,
          message: error.message,
          color: 'error',
          // timeout: 2000, // Optional. Default is 3000
        })
      }
      isLoading.value = false
    }

    return {
      followAction,
      isFollowing,
      isLoading,
      showTradePopup,
    }
  },
}
</script>

<!-- Global styles -->
<style>
.flake-follow-btn {
  .v-btn__loader {
    transform: scale(0.7);
  }
}
</style>

<!-- Scoped styles -->
<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
  margin-right: 5px;
}

.always-white {
  color: white !important;
}
</style>
